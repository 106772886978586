import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnSubpage} from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import BxRight from '../../assets/Icons/bxRight.svg'
import BxLeft from '../../assets/Icons/bxLeft.svg'
import BxSpinner from '../../assets/Spinners/LoopLoader.svg'
import BxLogo from '../../assets/Marks/bxLogo.svg'
import BxMenu from '../../assets/Icons/bxMenu.svg'
import BxCross from '../../assets/Icons/bxCross.svg'
import { BnBoolInput, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { BnLabel2 } from '../Label'
import BxSearch from '../../assets/Icons/bxSearch.svg'
import { mergeFields, capitalize, joinWith } from '../../classes/Util.js'
import './index.css'

export const BnTabviewTabSeparator = props => {
  return <div className='bnTabviewTabSeparator'/>
}

export const BnTabview = props => {
  const { tabs, selection, select } = props
  const selectedTab = tabs.find(t => t.selector === selection)
  if (!selectedTab) {
    debugger
    return null
  }
  return <div className='bnTabview'>
    <div className='bnTabviewTabs'>
    {joinWith(tabs.map(tab => {
      let className = 'bnTabviewTab'
      if (tab === selectedTab) {
        className += ' bnTabviewSelected'
      }
      return <div key={tab.selector} className={className} onClick={() => select(tab)}>
        {tab.label}
        </div>
    }), () => <BnTabviewTabSeparator/>)
    }
    </div>
    <div className='bnTabviewContent'>
    {selectedTab.render ? selectedTab.render() : null}
  </div>
    </div>
}

export const BnLogo = props => {
  return <div className='bnSignInLogo'><ReactSVG src={BxLogo}/></div>
}

export const BnLogoSmall = props => {
  return <div className='bnLogoSmall' onClick={props.action}><ReactSVG src={BxLogo}/></div>
}

export const BnMenu = props => {
  let className = 'bnMenu'
  if (props.open) className += ' bnMenuOpen'
  return <div className={className} onClick={props.action}><ReactSVG src={BxMenu}/></div>
}

export const BnCancelButton = props => {
  return <div className='bnCancelButton' onClick={props.action}>
    {props.label || 'Cancel'}
    </div>
}

export const BnModifyButton = props => {
  return <div className='bnModifyButton' onClick={props.action}>
    {props.label || 'Modify'}
    </div>
}

export class BnBackButton extends Component {
  render() {
    return <div className='bnBackButton' onClick={this.props.action}>
      <div className='bnBackButtonIcon'><ReactSVG src={BxLeft}/></div>
      <div className='bnBackButtonLabel'>Back</div>
      </div>
  }
}

export class BnOKButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }
  action = async () => {
    this.setState({
      busy: true
    })
    if (this.state.oof) {
      this.setState({
        oof: false
      })
      return
    }
    try {
      await this.props.action()
    } catch (err) {
      return console.error(err)
      this.setState({
        busy: false,
        oof: err
      })
    }
    this.setState({
      busy: false
    })
  }
  render() {
    const label = this.state.oof ? 'Oof, that didn\'t work': (this.props.label || 'Done')
    let icon = BxRight
    let className = 'bnOKButton'
    if (this.state.busy) {
      icon = BxSpinner
      className += ' bnButtonBusy'
    }
    return <div className={className} onClick={this.action}>
      <div className='bnOKButtonLabel'>{label}</div>
      <div className='bnOKButtonIcon'><ReactSVG src={icon}/></div>
      </div>
  }
}

export class BnSearchResult extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }

  action = async () => {
    if (!this.props.action) return
    this.setState({
      busy: true
    })
    console.log("props", this.props)
    await this.props.action()
    this.setState({
      busy: false
    })
  }
  
  render() {
    let className = 'bnSearchResult'
    const label = this.props.label
    const icon = BxSearch
    if (this.state.busy) {
      className += ' bnSearchResultBusy'
    }
    return <div className={className} onClick={this.action}>
      <div className='bnSearchResultLabel'>{label}</div>
      <div className='bnSearchResultIcon'><ReactSVG src={icon}/></div>
      </div>
  }
}

export const BnSearchResultBack = props => {
  const label = props.label
  return <div className='bnSearchResult bnSearchResultBack' onClick={props.action}>
    <div className='bnSearchResultIcon'><ReactSVG src={BxLeft}/></div>
    <div className='bnSearchResultLabel'>{'Back'}</div>
    </div>
}

export const BnCheckboxSeparator = props => {
  return <div className='bnCheckboxSeparator'/>
}

export const BnCheckboxes = props => {
  const { children } = props
  console.log('BnCheckboxes', children)
  return <div className='bnCheckboxes'>
    {joinWith(children, () => <BnCheckboxSeparator/>)}
    </div>
}

export const BnCheckbox = props => {
  const { form, name, icon, label, onChange } = props
  const selected = form[name]
  let className = 'bnCheckbox'
  if (selected) {
    className += ' bnCheckboxSelected'
  }
  const onClick = () => {
    onChange(name, !selected)
  }
  return <div className={className} onClick={onClick}>
    {icon && <div className='bnCheckboxIcon'>{icon}</div>}{label && <div className='bnCheckboxLabel'>{label}</div>}
  </div>
}

export class BnButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }

  onClick = async () => {
    if (this.state.busy) return
    if (this.state.oof) {
      this.setState({
        oof: false
      })
      return
    }
    this.setState({
      busy: true,
    })
    try {
      await this.props.action()
    } catch (err) {
      console.error(err)
      this.setState({
        busy: false,
        oof: err
      })
    }
    this.setState({
      busy: false
    })
  }

  getIcon() {
    return this.props.icon || BxRight
  }

  render() {
    let className = 'bnButton'
    let label = this.props.label || 'Continue'
    let icon = this.getIcon()
    const iconContent = this.props.iconContent;
    if (this.state.busy) {
      className += ' bnButtonBusy'
      icon = BxSpinner
    }
    if (this.state.oof) {
      className += ' bnButtonOof'
      label = 'Oof that didn\'t work'
    } else {
      if (this.props.buttonClass) {
        className += ' ' + this.props.buttonClass
      }
    }
    return <div className={className} onClick={this.onClick}>
      <div className='bnButtonLabel'>{label}</div>
      {iconContent ? iconContent : (icon && <div className='bnButtonIcon'><ReactSVG src={icon}/></div>)}
      </div>
  }
}

export const BnRadioButton = props => {
  const { form, name, selector, onChange, label } = props
  const selected = form[name] === selector
  const onClick = () => {
    onChange(name, selector)
  }
  return <div className='bnRadioButton'>
    <div className='bnRadioButtonButton' onClick={onClick}>
    <div className='bnRadioButtonOuter'/>
    <div className='bnRadioButtonInner' style={selected ? null: { display: 'none'} }/>
    </div>
    <div className='bnRadioButtonLabel'>{label}</div>
    </div>
}

export const BnBlueButton = props => {
  return <div className='bnBlueButton'>
    <BnButton {...props}/>
    </div>
}

export const BnRedButton = props => {
  return <div className='bnRedButton'>
    <BnButton {...props}/>
    </div>
}

export const BnInputWithButton = props => {
  const { form, onChange, formErr, name, label, button } = props
  let buttonClass = 'bnSimpleButton'
  if (button.className) {
    buttonClass += ' ' + button.className
  }
  return <div className='bnInputWithButton'>
    <BnInputField label={label} onChange={onChange} name={name} form={form} formErr={formErr}/>
    <div className={buttonClass} onClick={button.action}>{button.label}</div>
    </div>
}


export const BnPageButton = props => {
  const { label, count, action, icon, className } = props
  let clazz = 'bnPageButton'
  if (icon) {
    clazz += ' bnPageButtonWithIcon'
  }
  if (className) {
    clazz += ' ' + className    
  }
  return <div className={clazz} onClick={action}>
    <div className='bnPageButtonLeft'>
    {icon && <div className='bnPageButtonIcon'>{<ReactSVG src={icon}/>}</div>}
    <div className='bnPageButtonLabel'>{label}</div>
    </div>
    <div className='bnPageButtonRight'>
    <div className='bnPageButtonCount'>{count}</div>
    <div className='bnPageButtonIcon'><ReactSVG src={BxRight}/></div>
    </div>
    </div>
}

export const BnButtonSeparator = props => {
  if (props.direction === 'column') {
    return <div className='bnColumnButtonSeparator'/>
  }
  return <div className='bnButtonSeparator'/>
}

export const BnPageButton2 = props => {
  const { title, subtitle, action, className, tag } = props
  let clazz = 'bnPageButton2'
  if (className) {
    clazz += ' ' + className    
  }
  return <div className={clazz} onClick={action}>
    <div className='bnFieldTypeLabel'>{tag}</div>
    <div className='bnPageButton2Row1'>
    <div className='bnPageButton2Title'>{title}</div>
    <div className='bnPageButtonIcon'><ReactSVG src={BxRight}/></div>
    </div>
    <div className='bnPageButton2Row2'>
    <div className='bnPageButton2Subtitle'>{subtitle}</div>
    </div>
    </div>
}

export const BnSelectionList = props => {
  const { label, options, selection, action } = props
  let count = props.count
  const opt = options.find(x => x.value === selection)
  if (opt) {
    count = opt.name
  } 
  console.log(label, options, selection, opt)
  let clazz = 'bnPageButton'
  return <BnPageButton label={label} count={count} action={action}/>
}

export class BnSelectionListChooser extends BnSubpage {
  constructor (props) {
    super(props)
  }

  renderCheckbox = (option, form, onChange) => {
    if (!this.props.boolInput) {
      let className = 'bnSelectionListItem'
      if (form[option.value]) {
        className += ' bnSelectionListItemSelected'
      }
      const onClick = () => onChange(option.value, !form[option.value])
      return <div key={option.value} className={className} onClick={onClick}>{option.name}</div>
    }
    return <BnBoolInput label={option.name} form={form} name={option.value} onChange={onChange}/>
  }

  renderContent() {
    const { options, form, onChange, action } = this.props
    let sortedOptions = options
    if (options.length > 12) {
      sortedOptions = [].concat(options)
      sortedOptions.forEach((x, i) => {
        x.sortOrder = i
      })
      sortedOptions.sort((x, y) => {
        const s1 = form[x.value]
        const s2 = form[y.value]
        if (s1) {
          if (!s2) {
            return -1
          }
        } else if (s2) {
          return 1
        }
        return x.sortOrder - y.sortOrder;
      })
    }
    const content = joinWith(sortedOptions.map(option => {
      return this.renderCheckbox(option, form, onChange) 
    }), () => <BnInputFieldSeparator/>)
    return <div className='bnPageContent'>
      {content}
      </div>
  }
}

export class BnRemoveButton extends Component {
  constructor (props) {
    super(props)
    this.state = { busy: false }
  }
  action = async () => {
    if (this.state.busy) return
    this.state.busy = true
    this.forceUpdate()
    await this.props.action()
    this.setState({
      busy: false
    })
  }
  render() {
    let icon = BxCross
    let className = 'bnMemberRemoveButton'
    if (this.state.busy) {
      icon = BxSpinner
      className += ' bnMemberRemoveButtonBusy'
    }
    return <div className={className} onClick={this.action}>
      <div className='bnMemberRemoveButtonIcon'><ReactSVG src={icon}/></div>
    </div>
  }
}


