import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnForm, BnFormFieldSeparator as Sep } from '../../Form'
import { BnLabel1, BnLabel2 } from '../../Label'
import { BnButton, BnBackButton, BnLogo } from '../../Button'
import { BnInputField, BnInputFieldSeparator } from '../../TextInput'
import Spinner from '../../../assets/Spinners/SpinLoader.svg'
import { parsePhoneNumber, convertPhone, validatePassword, validateEmail } from '../../../classes/Util.js'
import './index.css'

const renderLogo = () => <BnLogo/>


class BnCustomerAccountSetupPage1 extends Component {

  render() {
    const form = this.props.form
    const formErr = this.props.formErr
    const onChange = this.props.onChange
    return <div className='bnCustomerAccountSetupPage'>
      <BnForm>
      {renderLogo()}
      <BnBackButton action={this.props.back}/>
      <Sep/>
      <BnLabel1 text={'Welcome'}/>
      <BnLabel2 text={'You\'ve been invited to BeNxT'}/>
      <Sep/>
      <BnLabel1 text={'Account Verification'}/>
      <BnLabel2 text={'Please enter the verification code from your invite'}/>
      <div className='bnSignInFields'>
      {false && [<BnInputField autoFocus={true} name='email' err={formErr} label={'Email Address'} form={form} type='email' autoComplete={'email'} onChange={onChange}/>,
      <BnInputFieldSeparator/>]}
      <BnInputField  autoFocus={true} key='verificationCode' name='verificationCode' err={formErr} label={'Verification Code'} form={form} type='text' onChange={onChange}/>
      </div>
      <Sep/>
      <div className='bnSignInErr'>{formErr && formErr.message}</div>
      <div className='bnSignInContinue'>
      <BnButton label={'Continue'} action={this.props.action}/>
      </div>
      </BnForm>
      </div>
  }
}

class BnCustomerAccountSetupPage2 extends Component {

  render() {
    const form = this.props.form
    const formErr = this.props.formErr
    const onChange = this.props.onChange
    return <div className='bnCustomerAccountSetupPage'>
      <BnForm>
      {renderLogo()}
      <BnBackButton action={this.props.back}/>
      <Sep/>
      <BnLabel1 text={'Password'}/>
      <BnLabel2 text={'Your password must contain at least 10 characters, a mixture of upper and lower case letters with the inclusion of at least one special character'}/>
      <div className='bnSignInFields'>
      <BnInputField key='password' autoFocus={true} name='password' err={formErr} label={'Password'} form={form} type='password' onChange={onChange}/>
      </div>
      <Sep/>
      <div className='bnSignInErr'>{formErr && formErr.message}</div>
      <div className='bnSignInContinue'>
      <BnButton label={'Continue'} action={this.props.action}/>
      </div>
      </BnForm>
      </div>
  }
}

export class BnCustomerAccountSetupSpinner extends Component {

  render() {
    const form = this.props.form
    const formErr = this.props.formErr
    const onChange = this.props.onChange
    return <div key='spinner' className='bnCustomerAccountSetupSpinner'>
      <BnForm>
      <BnLabel1 text={this.props.label1 || 'Creating Account'}/>
      <BnLabel2 text={this.props.label2 || 'Please wait as we processs your account'}/>
      <Sep/>
      <div key='progress' className='bnCreatingAccountProgress'>
      <ReactSVG src={Spinner}/>
      </div>
      </BnForm>
      </div>
  }
}

const page3Fields = [
    {
      name: 'name',
      label: 'First, Last Name',
      autoComplete: 'name',
    },
    {
      name: 'email',
      label: 'Email Address',
      autoComplete: 'email',
      type: 'email'
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      autoComplete: 'tel',
      type: 'tel'
    }/*,
    {
      name: 'password',
      label: 'Password',
      autoComplete: 'new-password',
      type: 'password'
    }*/,
    {
      name: 'address',
      label: 'Street Address',
      autoComplete: 'street-address'
    },
    {
      name: 'city',
      label: 'City',
      autoComplete: 'address-level2'
    },
    {
      name: 'state',
      label: 'State',
      autoComplete: 'address-level1'
    },
    {
      name: 'country',
      label: 'Country',
      autoComplete: 'country'
    },
    {
      name: 'zip',
      label: 'Zip',
      autoComplete: 'postal-code'
    },
    ]


class BnCustomerAccountSetupPage3 extends Component {

  constructor (props) {
    super(props)
    debugger
  }

  render() {
    const form = this.props.form
    const formErr = this.props.formErr
    const onChange = this.props.onChange
    let fields = []
    page3Fields.forEach((field, i) => {
      const {name, type, label, autoComplete} = field;
      const f = <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      fields.push(f)
      if (i + 1 < page3Fields.length) {
        fields.push(<BnInputFieldSeparator/>)
      }
    })    
    return <div className='bnCustomerAccountSetupPage bnCustomerAccountSetupPage3'>
      <div className='bnCustomerAccountSetupPage' style={this.props.creatingAccount ? { display: 'none' } : null}>
      <BnForm>
      {renderLogo()}
      <Sep/>
      <BnBackButton action={this.props.back}/>
      <Sep/>
      <BnLabel1 text={'Account Setup'}/>
      <div className='bnSignInFields'>
      {fields}
      </div>
      <Sep/>
      <div className='bnSignInErr'>{formErr && formErr.message}</div>
      <div className='bnSignInContinue'>
      <BnButton label={'Continue'} action={this.props.action}/>
      </div>
      </BnForm>
      </div>
      {this.props.creatingAccount && <BnCustomerAccountSetupSpinner/>}
      </div>
  }
}


export class BnCustomerAccountSetup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      form: {},
      formErr: null
    }
  }

  componentDidMount() {
    this.state.form.email = this.props.me.self.email || '' 
    this.state.form.name = this.props.me.self.displayName || ''
    const  { phoneNumber } = parsePhoneNumber(this.props.me.self.phoneNumber)
    this.state.form.phoneNumber = ''+phoneNumber
    console.log('FORM', this.state.form)
    this.forceUpdate()
  }

  componentWillUnmount() {
  }

  onChangeForm = (field, value) => {
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  page1Action = async () => {
    const form = this.state.form
    let err
    if (!form.email) {
      err = {field: 'email', message: 'Email address is required'}
      this.setState({formErr: err})
      return
    }
    if (!form.verificationCode) {
      err = {field: 'verificationCode', message: 'Verification code is required'}
      this.setState({formErr: err})
      return
    }
    const { ok } = await this.props.me.verifyInvite(this.props.me.self.email, form.verificationCode)
    if (!ok) {
      err = {field: 'verificationCode', message: 'Verification failed'}
      this.setState({formErr: err})
      return
    }
    if (false) {
      const { exists } = await this.props.me.emailExists(form.email)
      if (exists) {
        err = {field: 'email', message: 'A user with that email already exists'}
        this.setState({formErr: err})
        return
      }
    }
    this.setState({
      verification: { email: form.email, verificationCode: form.verificationCode },
      page: 3
    })
  }

  page2Action = async () => {
    const form = this.state.form
    let err
    if (false) {
      let msg
      if (!form.password) {
        msg = 'Password is required'
      } else {
        const  { errors } = validatePassword(form.password)
        if (errors.length > 0) {
          msg = errors[0]
        }
      }
      if (msg) {
        err = {field: 'password', message: msg}
        this.setState({formErr: err})
        return
      }
    }
    if (form.password.length < 6) {
      err = {field: 'password', message: 'Password is too short'}
      this.setState({formErr: err})
      return
    }
    this.setState({
      page: 3
    })
  }

  back = () => {
    if (this.state.page === 1) {
      return this.props.back()
    }
    this.setState({
      formErr: {},
      page: this.state.page - 1
    })
  }

  page3Action = async () => {
    const form = this.state.form
    if (!validateEmail(form.email)) {
      err = { field: 'email', message: 'Email address is invalid' }
      this.setState({
        formErr: err
      })
      return
    }
    let phoneNumberToCheck
    if (form.phoneNumber) {
      const converted = convertPhone(form.phoneNumber)
      if (!converted) {
        const err = { field: 'phoneNumber',
                      message: 'Phone number is invalid' }
        this.setState({
          formErr: err
        })
        return
      } else {
        phoneNumberToCheck = converted
      }
    }
    for (const field of page3Fields) {
      if (!form[field.name]) {
        const err = {field: field.name, message: field.label + ' is required'}
        this.setState({formErr: err})
        return
      }
    }
    this.setState({
      creatingAccount: true
    })
    if (!this.props.me.self.email || this.props.me.self.email.toLowerCase() !== form.email.toLowerCase())
    {
      const { exists } = await this.props.me.emailExists(form.email)
      if (exists) {
        const err = {field: 'email', message: 'A user with that email already exists'}
        this.setState({formErr: err, creatingAccount: false})
        return
      }
    }
    if (!this.props.me.self.phoneNumber || this.props.me.self.phoneNumber !== phoneNumberToCheck) 
    {
      const { exists } = await this.props.me.phoneNumberExists(phoneNumberToCheck)
      if (exists) {
        const err = {field: 'phoneNumber', message: 'A user with that phone number already exists'}
        this.setState({formErr: err, creatingAccount: false})
        return
      }
    }
    try {
      const result = await this.props.me.createCustomerAccount(this.state.verification, this.state.form)
    } finally {
      this.setState({
        creatingAccount: false
      })
    }
  }

  render() {
    let company = this.props.company || 'Somebody'
    let content
    switch (this.state.page) {
      case 1:
        content = <BnCustomerAccountSetupPage1 back={this.back} company={company} form={this.state.form} onChange={this.onChangeForm} formErr={this.state.formErr} action={this.page1Action}/>
          break
      case 2:
        content = <BnCustomerAccountSetupPage2 back={this.back} form={this.state.form} onChange={this.onChangeForm} formErr={this.state.formErr} action={this.page2Action}/>
          break
      case 3:
        content = <BnCustomerAccountSetupPage3 creatingAccount={this.state.creatingAccount} back={this.back} form={this.state.form} onChange={this.onChangeForm} formErr={this.state.formErr} action={this.page3Action}/>
          break
    }
    return <div className='bnCustomerAccountSetup'>
      {content}
      </div>
  }
}
