import React, { Component } from 'react'
import { map } from 'rxjs/operators'
import { ReactSVG } from 'react-svg'
import { BnApp } from '../App'
import { BnPage, BnSubpage } from '../Page'
import { BnBusinessMembership } from '../Business'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnLabel, BnLabel1, BnLabel2, BnErrorLabel, BnLabel3, BnFieldLabel3, BnLabel4, BnOrangeLabel, BnFieldLabel } from '../Label'
import { BnReasonsPage, BnReasonCard } from '../Admin'
import { BnButtonSeparator, BnButton, BnBlueButton, BnRedButton, BnBackButton, BnLogoSmall, BnPageButton, BnPageButton2,
         BnInputWithButton, BnSelectionList, BnSelectionListChooser, BnCheckbox, BnCheckboxes, BnTabview } from '../Button'
import { BnInputField, BnInputFieldSeparator, BnSearchField, BnNegBoolInput, BnBoolInput } from '../TextInput'
import { formatDistance, formatPhone, mergeFields, search, capitalize, joinWith } from '../../classes/Util.js'
import BxHealth from '../../assets/Services/bxHealth.svg'
import BxRight from '../../assets/Icons/bxRight.svg'
import BxTelehealth from '../../assets/Services/bxConferance.svg'
import BxInPerson from '../../assets/Icons/bxInPerson.svg'
import BxCal from '../../assets/Icons/bxCal.svg'
import BxClock from '../../assets/Icons/bxClock.svg'
import { getStars, getSpecialtyIcon, getProviderSorting, getOfficeVisitLabel } from '../Customer'
import { implies } from '../../classes/Taxonomy.js'
import mobiscroll from '@mobiscroll/react'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'

import './index.css'

export const daysOfWeek = [
  {
    name: 'monday',
    label: 'Mo',
    long: 'Mon'
  },
  {
    name: 'tuesday',
    label: 'Tu',
    long: 'Tue'
  },
  {
    name: 'wednesday',
    label: 'We',
    long: 'Wed'
  },
  {
    name: 'thursday',
    label: 'Th',
    long: 'Thu'
  },
  {
    name: 'friday',
    label: 'Fr',
    long: 'Fri'
  },
  {
    name: 'saturday',
    label: 'Sa',
    long: 'Set'
  },
/*  {
    name: 'sunday',
    label: 'Su'
  } */
]

export const timesOfDay = [
  {
    name: 'earlyMorning',
    label: '7-9am'
  },
  {
    name: 'morning',
    label: '9am-12pm'
  },
  {
    name: 'afternoon',
    label: '12-3pm'
  },
  {
    name: 'lastAfternoon',
    label: '3-6pm'
  }]

const DAYS_OF_WEEK = daysOfWeek
const TIMES_OF_DAY = timesOfDay



export const BnAddressLabel = props => {
  const getShortName = x => x ? x.short_name : ''
  const { address_components, distance } = props
  const streetNumber = getShortName(address_components.find(x => x.types.find(y => y == 'street_number')))
  let suite = ''
  const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
  if (subPremise) {
    suite = `, ${subPremise.short_name}`
  }
  const streetAddress = getShortName(address_components.find(x => x.types.find(y => y == 'route')))
  const city = getShortName(address_components.find(x => x.types.find(y => y == 'locality')))
  const state = getShortName(address_components.find(x => x.types.find(y => y == 'administrative_area_level_1')))
  const zip = getShortName(address_components.find(x => x.types.find(y => y == 'postal_code')))
  return <div className={'bnPracticeCardAddress'}>{streetNumber} {streetAddress}{suite}<br/>{city}, {state}, {zip} {distance ? `(${formatDistance(distance)})`: ''}</div>
}

const BnFormHack = props => {
  return <div className='bnFormHack'>
    {props.children}
    </div>
}

export const BnPracticeCard = props => {
  const { practice, action, profession } = props
  const { name, place, isoPhone, providers } = practice
  let specialties = practice.specialties || []
  let providerValue = ''
  if (providers) {
    let seen = {}
    let sep = providers.length > 1 ? 'Providers (' : 'Provider ('
    providers.forEach(x => {
      x.specialties.forEach(y => {
        if (!seen[y.code]) {
        seen[y.code] = true
          specialties.push(y)
        }
      })
      if (x.name !== name) {
      providerValue += sep
        providerValue += x.name
        sep = ', '
      }
    })
    if (providerValue) {
      providerValue += ')'
    }
  }
  return <div className='bnPracticeCard' onClick={action}>
    <div className='bnFieldTypeLabel'>{profession || 'PRACTICE'}</div>
    <BnOrangeLabel text={name}/>
    {providerValue && <BnFieldLabel3 label={providers.length} value={providerValue}/>}
    <div className='bnPracticeCardSpecialties'>
    {specialties.map(specialty => {
        return <BnLabel3 icon={getSpecialtyIcon(specialty.label)} label={specialty.label}/>
    })}
    {isoPhone && <div className='bnPhoneNumber'>{formatPhone(isoPhone)}</div>}
    {place && <BnAddressLabel address_components={place.address_components}/>}
    </div>
    </div>
}

export const BnProviderCard = props => {
  const { provider, action } = props
  const practice = mergeFields(provider, { providers: [] })
  console.log('practice', practice)
  return <BnPracticeCard practice={practice} profession={'Provider'} action={action}/>
}

export const BnServiceCard = props => {
  const { label, form, onChange, defaultPrice } = props
  return <div className='bnReasonCard'>
    <BnFormFields>
    <div className='bnReasonCardLabel'>{label}</div>
    <BnInputFieldSeparator/>
    <BnInputField type={'currency'} name='price' label='Price' form={form} onChange={onChange}/>
    </BnFormFields>
    </div>
}

export const getDaysOfWeek = value => {
  const arr = []
  for (const day of daysOfWeek) {
    if (value[day.name]) {
      arr.push(day)
    }
  }
  return arr.map(x => x.long).join(', ')
}

export const getTimesOfDay = value => {
  const arr = []
  for (const time of timesOfDay) {
    if (value[time.name]) {
      arr.push(time)
    }
  }
  return arr.map(x => x.label).join(', ')
}

const BnDrAppointmentDoctor = props => {
  const { doctor, action, reason } = props
  const { rating, distance }  = doctor
  const specialties = doctor.specialties.map(x => x.label).join(', ')
  const buttons = []
  const label = getOfficeVisitLabel({ doctor, reason })
  
  return <div className='bnDoctorAppointmentDoctorContainer' key={doctor.id}>
    <div className='bnDoctorAppointmentDoctorStandalone' onClick={action}>
    <div className='bnFieldTypeLabel'>PROVIDER</div>
    <div className='bnCustomerAppointmentCardHeader' >
    <div className='bnCustomerAppointmentCardHeaderTitle'>{doctor.name}</div>
    <div className='bnCustomerAppointmentCardHeaderButton'><ReactSVG src={BxRight}/></div>
    </div>
   {rating && <div key='rating' className='bnDoctorCardInfoLine bnDoctorCardInfoRatingLine'>
   <div className='bnDoctorCardInfoText bnRatingText'>{rating.toFixed(1)}</div>
   <div className='bnDoctorCardInfoRatingContainer'>
   <div className='bnDoctorCardInfoRating'>
   {getStars(rating)}
   </div>
   </div>
    </div>}
    <BnLabel3 icon={getSpecialtyIcon(doctor.specialties)} label={specialties}/>
    {doctor.affiliation && <div className='bnDoctorAppointmentAffiliation'>{doctor.affiliation}</div>}
    <BnAddressLabel address_components={doctor.place.address_components} distance={distance}/>
    {doctor.officeVisit &&
     <BnFieldLabel text={label} value={'$'+doctor.officeVisit.price}/>}
    </div>
    </div>

}

const BnDrAppointmentDoctorFull = props => {
  const { doctor, action, buttonLabel, form, onChange, decline, update, price } = props
  const { rating, distance }  = doctor
  const specialties = doctor.specialties.map(x => x.label).join(', ')
  const buttons = []
  if (decline) buttons.push(<BnRedButton label='Decline' action={decline}/>)
  if (update) buttons.push(<BnBlueButton label='Modify' action={update}/>)
  
  return <div className='bnDoctorAppointmentDoctorContainer' key={doctor.id}>
    <div className='bnDoctorAppointmentDoctorStandalone'>
    <div className='bnFieldTypeLabel'>PROVIDER</div>
    <BnOrangeLabel text={doctor.name}/>
    {rating && <div key='rating' className='bnDoctorCardInfoLine bnDoctorCardInfoRatingLine'>
   <div className='bnDoctorCardInfoText bnRatingText'>{rating.toFixed(1)}</div>
   <div className='bnDoctorCardInfoRatingContainer'>
   <div className='bnDoctorCardInfoRating'>
   {getStars(rating)}
   </div>
   </div>
     </div>}
    <BnLabel3 icon={getSpecialtyIcon(doctor.specialties)} label={specialties}/>
    <BnAddressLabel address_components={doctor.place.address_components} distance={distance}/>
    </div>
    <BnPageButton label='Call' count={<a href={'tel:'+doctor.isoPhone} className='bnPhoneNumber'>{formatPhone(doctor.isoPhone)}</a>}/>
    <BnInputFieldSeparator/>
      <div className='bnDates'>
      <BnLabel4 text={'Days of Week'}/>
      <Sep/>
      <BnCheckboxes>
      {daysOfWeek.map(day => {
        const onChecked = (field, value) => {
          const daysOfWeek = form.daysOfWeek || {}
          daysOfWeek[field] = value
          onChange('daysOfWeek', daysOfWeek)
        }
        return <BnCheckbox form={form.daysOfWeek || {}} name={day.name} label={day.label} onChange={onChecked}/>
      })}
      </BnCheckboxes>
      <BnLabel4 text={'Times of Day'}/>
      <BnCheckboxes>
      {timesOfDay.map(x => {
        const onChecked = (field, value) => {
          const timesOfDay = form.timesOfDay || {}
          timesOfDay[field] = value
          onChange('timesOfDay', timesOfDay)
        }
        return <BnCheckbox form={form.timesOfDay || {}} name={x.name} label={x.label} onChange={onChecked}/>
      })}
      </BnCheckboxes>
    </div>
    <BnNegBoolInput label='Disabled' form={form} name='disabled' onChange={onChange}/>
  
    {action &&
  <div className='bnCustomerAppointmentButtons'>
  {confirm && <div className='bnCustomerAppointmentButtonsRow1'>
   <BnButton label={buttonLabel} action={action} iconContent={'$'+price}/>
  <BnButtonSeparator direction='column'/>
  </div>}
     <div className='bnCustomerAppointmentButtonsRow2'>
     {joinWith(buttons, () => <BnButtonSeparator/>)}
     </div>
    </div>}
  
    </div>

}

      
const BnDrAppointment = (props) => {
  const { appt, doctorForm, onChangeDoctor, modifyProvider, editProvider, startCall, doctor, book, confirm, decline, cancel, onChangeDate, onChangeTime, isAdmin, call, update, updateStatus } = props

  const form = doctorForm
  const onChange = onChangeDoctor
  
  const ddate = doctor.daysOfWeek || 'All Days'
  const dtime = doctor.timesOfDay || 'All Times'
  const { phoneNumber, time, date, status, id, uid, displayName, reason, isTelehealth, when, notes, timesOfDay, daysOfWeek, price } = appt
  let dates
  let times

  if (when === 'asap') {
    dates = 'As soon as possible'
  } else {
    dates = getDaysOfWeek(daysOfWeek)
    times = getTimesOfDay(timesOfDay)
  }
  const renderText = (icon, text) => {
      return <div className='bnDoctorAppointmentItem'>
      <div className='bnDoctorAppointmentItemIcon'><ReactSVG src={icon}/></div>
      <div className='bnDoctorAppointmentItemText'>{text}</div>
      </div>
  }
  const dateInput = <div className='bnDateInput'><mobiscroll.Date theme={'ios'} value={date} dateFormat=' MMM D dd' height={45} rows={5} onSet={onChangeDate}  dateWheels={'MD dd'}><input tabIndex={-1}/></mobiscroll.Date></div>
  const timeInput =  <div className='bnDateInput'><mobiscroll.Time theme={'ios'} rows={5} key='timeStart' height={45} onItemTap={null} value={time} onSet={onChangeTime} timeWheels={'hhiiA'} stepMinute={10}><input tabIndex={-1} />
        </mobiscroll.Time></div>

  let bookingStatusClass = 'bnBookingStatus' + capitalize(status)
  const buttons = []
  if (book) buttons.push(<BnButton label={'Book'} action={book}/>)
  if (confirm) buttons.push(<BnButton label={'Confirm'} action={confirm}/>)
  if (decline) buttons.push(<BnRedButton label={'Decline'} action={decline}/>)
  if (startCall) buttons.push(<BnButton label={'Start Call'} action={startCall}/>)
  if (cancel) buttons.push(<BnRedButton label={'Cancel'} action={cancel}/>)
  if (update) buttons.push(<BnBlueButton label={'Update'} action={update}/>)
  const specialties = doctor.specialties.map(x => x.label).join(', ')
  return <div className='bnDoctorAppointmentContainer' key={appt.id}>
    <BnInputFieldSeparator/>
    <div className='bnDoctorAppointment'>
    <BnOrangeLabel text={reason.reason + ' Visit'}/>
    <BnInputFieldSeparator/>
    <div className='bnFieldTypeLabel'>CUSTOMER</div>
    <BnOrangeLabel text={displayName}/>
    <BnLabel3 icon={isTelehealth ? BxTelehealth : BxInPerson} label={isTelehealth ? 'Telehealth' : 'In-person Visit'}/>
    {dates && <BnLabel3 icon={BxCal} label={dates}/>}
    {times && <BnLabel3 icon={BxClock} label={times}/>}
    <div className='bnDoctorAppointmentNotes'>{notes}</div>
    { isAdmin && [ <BnPageButton label='Call' count={<a href={'tel:'+phoneNumber} className='bnPhoneNumber'>{formatPhone(phoneNumber)}</a>} action={call}/>]}
    <BnInputFieldSeparator/>
  
    {isAdmin && <div className='bnDoctorAppointmentDoctor'>
    <div className='bnFieldTypeLabel'>PROVIDER</div>
    <BnOrangeLabel text={doctor.name}/>
    <BnLabel3 icon={getSpecialtyIcon(doctor.specialties)} label={specialties}/>
    {ddate && <BnLabel3 icon={BxCal} label={ddate}/>}
    {dtime && <BnLabel3 icon={BxClock} label={dtime}/>}
    {doctor.affiliation && <div className='bnDoctorAppointmentAffiliation'>{doctor.affiliation}</div>}
     <BnAddressLabel address_components={doctor.place.address_components}/>
     { call && [<BnPageButton label='Call' count={<a href={'tel:'+doctor.isoPhone} className='bnPhoneNumber'>{formatPhone(doctor.isoPhone)}</a>} action={call}/>]}
    <BnInputFieldSeparator/>

     {status === 'requested' && <div className='bnDoctorAppointmentReplaceButton'>
     <BnBlueButton label='Modify' action={modifyProvider}/>
     <BnButtonSeparator/>
     <BnRedButton label='Replace' action={editProvider}/>
      </div>}
    <BnInputFieldSeparator/>
     </div>
    }
    
    <div className='bnDoctorAppointmentWhen'>
    {!updateStatus && <BnFieldLabel text='Status' value={<span className={bookingStatusClass}>{capitalize(status)}</span>}/>}
    {updateStatus && [<BnPageButton action={updateStatus} label='Status' count={<span className={bookingStatusClass}>{capitalize(status)}</span>}/>]}
    <BnInputFieldSeparator/>
    <BnPageButton key='date' label='Date' count={dateInput}/>
    <BnInputFieldSeparator/>
     <BnPageButton key='time'label='Time' count={timeInput}/>
    <BnInputFieldSeparator/>
    <BnFieldLabel text={getOfficeVisitLabel({ doctor, reason })} value={'$'+price}/>
   </div>
    <BnInputFieldSeparator/>
    </div>
    {buttons.length > 0 && <div className='bnDoctorAppointmentButtons'>
    {joinWith(buttons, () => <BnButtonSeparator/>)}
    </div>}
    </div>
}

class BnEditAppointmentProviderPage extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.sort = 'relevance'
  }

  renderAppt() {
    return <div className='bnSubpageContent'>
    <BnLabel1 text='Appointment'/>
    <BnFormHack>
    <BnOrangeLabel text={reason.reason + ' Visit'}/>
    </BnFormHack>
    <BnInputFieldSeparator/>
    <BnFormHack>
    <div className='bnFieldTypeLabel'>CUSTOMER</div>
    <BnOrangeLabel text={displayName}/>
    <BnLabel3 icon={isTelehealth ? BxTelehealth : BxInPerson} label={isTelehealth ? 'Telehealth' : 'In-person Visit'}/>
    {dates && <BnLabel3 icon={BxCal} label={dates}/>}
    {times && <BnLabel3 icon={BxClock} label={times}/>}
    {notes && <div className='bnDoctorAppointmentNotes'>{notes}</div>}
    </BnFormHack>
      <BnPageButton label='Call' count={<a href={'tel:'+phoneNumber} className='bnPhoneNumber'>{formatPhone(phoneNumber)}</a>}/>
    <BnFieldLabel text='Price' value={'$'+price}/>
      <Sep/>
      </div>
  }

  getSorting = () => {
    if (this.state.sort === 'relevance') {
      const appt = this.props.appt
      const specialtiesMatch = x => {
        let same = true
        for (const a of x.specialties) {
          for (const b of appt.providerInfo.specialties) {
            if (a.code !== b.code) {
              same = false
              break
            }
          }
          if (!same) {
            break
          }
        }
        return same ? 0 : 1
      }
      const price = appt.price
      const fn = searchResults => {
        searchResults.sort((x, y) => {
          let a = specialtiesMatch(x)
          let b = specialtiesMatch(y)
          let cmp = a - b
          if (cmp) return cmp
          a = x.services ? x.officeVisit.price : price
          b = y.services ? y.officeVisit.price : price
          cmp = a - b
          return cmp
        })
        return searchResults
      }
      return fn
    }
    return getProviderSorting(this.state.sort, this.state.providerType, null,
                              this.props.me.providerTypeFilter(this.state.providerType))
  }
  
  renderContent () {
    const { price, phoneNumber, isTelehealth, reason, displayName, notes, when, timesOfDay, daysOfWeek } = this.props.appt


    let dates
    let times
    
    if (when === 'asap') {
      dates = 'As soon as possible'
    } else {
      dates = getDaysOfWeek(daysOfWeek)
      times = getTimesOfDay(timesOfDay)
    }
    const fn = this.getSorting()
    const similarProviders = fn(this.props.similarProviders.results)
    return <div className='bnSubpageContent'>
    <BnLabel1 text='Similar Providers'/>
    <Sep/>
    <BnInputFieldSeparator/>
     <BnTabview tabs={[
       {
         selector: 'relevance',
         label: 'Relevance',
         render: () => null
       },
       {
         selector: 'rating',
         label: 'Rating',
         render: () => null
       },
       {
         selector: 'price',
         label: 'Price',
         render: () => null
       },
       {
         selector: 'distance',
         label: 'Distance',
         render: () => null
       }
     ]} selection={this.state.sort} select={tab=>this.setState({sort: tab.selector})}/> 
      <div key={'searchResults'} className='bnCustomerSearchResults'>
      {
        joinWith(similarProviders.map(provider => {
          const action = async() => this.props.suggest(provider)
          return <BnDrAppointmentDoctor reason={reason} doctor={provider} action={action}/>
        }), () => <Sep/>)
      }
      </div>
      </div>
  }
}

class BnDoctorReasonsPage extends BnReasonsPage {

  constructor (props) {
    super(props)
    console.log('BnDoctorReasonsPage', props.reasons)
  }

  observePrices() {
    this.pricesSub = this.props.me.observeMyPrices(this.props.doctor.id).subscribe(change => {
     const { reason, enabled, doctorPrice, type } = change
      if (type === 'removed') {
        delete this.forms[reason.id]
      } else {
        const { telehealthEnabled, enabled, price, telehealthPrice } = doctorPrice
        const form = this.getReasonForm(reason)
        form.useDefaultPrice = false
        form.acceptInPerson = enabled
        form.price = price
        form.acceptTelehealth = telehealthEnabled
        form.telehealthPrice = telehealthPrice
      }
      this.forceUpdateLater()
    })
    const setDefaultPrices = doctor => {
      if (doctor.defaultPrice) {
        this.state.form.defaultPrice = doctor.defaultPrice
      }
      if (doctor.defaultTelehealthPrice) {
        this.state.form.defaultTelehealthPrice = doctor.defaultTelehealthPrice
      }
      this.forceUpdate()
    }
    setDefaultPrices(this.props.doctor)
    this.defaultPriceSub = this.props.me.observeDoctor(this.props.doctor.id).subscribe(change => {
      const doctor = change.doctor
      setDefaultPrices(doctor)
    })
  }

  async saveDefaultPrice (form) {
    await this.props.me.saveDoctorDefaultPrice(mergeFields({
      doctorId: this.props.doctor.id
    }, form))
  }

  unobservePrices() {
    if (this.pricesSub) this.pricesSub.unsubscribe()
    if (this.defaultPriceSub) this.defaultPriceSub.unsubscribe()
  }
  
  forceUpdateLater = () => {
    clearTimeout(this.forceUpdateTimeout)
    this.forceUpdateTimeout = setTimeout(() => this.forceUpdate(), 200)
  }
}

export class BnAppointmentsPage extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.appointments = []
    this.state.forms = {}
  }

  getAppointmentForm = x => {
    let form = this.state.forms[x.id]
    if (!form) {
      form = {}
      for (const id in x) {
        form[id] = x[id]
      }
      if (!x.date) {
        form.date = x.scheduled ? new Date(x.scheduled) : new Date()
        form.date.setHours(0)
        form.date.setMinutes(0)
        form.date.setSeconds(0)
        form.date.setMilliseconds(0)
      }
      if (!x.time) {
        form.time = x.scheduled ? new Date(x.scheduled) : new Date()
        form.time.setMinutes(0)
        form.time.setSeconds(0)
        form.time.setMilliseconds(0)
      }
      this.state.forms[x.id] = form
    }
    return form
  }

  appts = {}

  componentDidMount() {
    this.sub = this.props.observeAppointments().subscribe(change => {
      const appt = change.appt
      if (change.type !== 'removed') {
        this.appts[appt.id] = appt
      } else {
        delete this.appts[appt.id]
      }
      this.updateAppointmentsLater()
    })
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  updateAppointmentsLater = () => setTimeout(this.updateAppointments, 200)

  updateAppointments = () => {
    const appts = Object.values(this.appts)
    appts.sort((x, y) => y.created - x.created)
    this.setState({
      appointments: appts
    })
  }


  openStatusChooser = x => {
    const form = {}
    form[x.status] = true
    const makeName = status => {
      const up = capitalize(status)
      //return <span className={'bnBookingStatus' + up}>{up}</span>
      return up
    }
    const status = ['requested', 'scheduled', 'booked', 'canceled', 'completed', 'paid']
    const options = status.map(status => {
      return {
        value: status,
        name: capitalize(status)
      }
    })
    const onChange = (field, value) => {
      if (value) {
        for (const opt of options) {
          form[opt.value] = false
        }
        form[field] = true
      } else if (false) {
        form[field] = false
        form[x.status] = true
      }
      this.forceUpdate()
    }
    const action = () => {
      for (const opt of options) {
        if (form[opt.value]) {
          x.status = opt.value
          break
        }
      }
      this.back()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser me={this.props.me} back={this.back} action={action} form={form} onChange={onChange} options={options}/>
    })
  }

  modifyProvider = async x => {
  }

  declineAppointment = async (appt) => {
    const result = await this.props.me.declineAppointment(appt)
  }

  editAppointmentProvider = async (appt) => {
    const suggest = async (provider) => {
      const form = this.getAppointmentForm(appt)
      if (!form.declined) {
        form.declined = []
      }
      form.declined.push(form.doctor)
      form.doctor = provider
      delete form.doctorForm
      this.back()
    }
    const { reasonId, price, providerInfo, customerInfo } = appt
    const result = await this.props.me.findSimilarProviders({
      reasonId, price, providerInfo, customerInfo
    })
    this.setState({
      subpage: () => <BnEditAppointmentProviderPage appt={appt} me={this.props.me} back={this.back} similarProviders={result} suggest={suggest}/>
    })
  }

  renderContent() {
    const arr = this.state.appointments
    return <div className='bnPageContent'>
      <BnLabel1 text={capitalize(this.props.status) + ' Appointments'}/>
      <Sep/>
      {joinWith(arr.map(x => {
        let book
        let decline
        let editProvider
        let modifyProvider
        let cancel
        let update
        let updateStatus
        let onChangeDate
        let onChangeTime
        let date
        let time
        let canUpdate
        const form = this.getAppointmentForm(x)
        if (this.props.isAdmin) {
          switch (x.status) {
            case 'booked':
            case 'completed':
              canUpdate = true
              updateStatus = async () => {
                await this.openStatusChooser(form)
              }
          }
        }
        if (canUpdate) {
          update = async () => {
            delete this.state.forms[form.id]
            try {
              debugger
              if (x.status === 'booked' && form.status === 'completed') {
                // temporary hack
                await this.props.me.completeAppointment(form)
              } else {
                await this.props.me.updateAppointment(form)
              }
            } catch (err) {
              console.error(err)
              debugger
            }
          }
        }
        const call = async () => {
          //await this.props.me.callPhoneNumber(x.doctor.isoPhone)
        }
        if (x.status === 'requested') {
          book = async () => {
            const time = new Date(form.date)
            time.setHours(form.time.getHours())
            time.setMinutes(form.time.getMinutes())
            const opts = {
              doctor: form.doctor, appt: x, date: form.date, time
            }
            const { error } = await this.props.me.acceptAppointment(opts)
            if (error) {
              console.log(error)
              debugger
            }
          }
          decline = async () => {
            await this.declineAppointment(x)
          }
          editProvider = async () => {
            await this.editAppointmentProvider(x)
          }
          modifyProvider = async () => {
            await this.modifyProvider(x)
          }
        } else {
          switch (x.status) {
            case 'completed':
            case 'canceled':
              break
            default:
              cancel = async () => {
                await this.props.me.cancelDoctorAppointment({ doctor: x.doctor, appt: x })
              }
          }
        }
        date = form.date
        time = form.time
        if (!form.doctorForm) {
          form.doctorForm = mergeFields(form.doctor)
        }
        const onChangeDoctor = (field, value) => {
          appt.doctorForm[field] = value
          this.forceUpdate()
        }
        onChangeDate = (e, instance) => {
          const val = instance.getVal()
          console.log(val)
          form.date = val
          this.forceUpdate()
        }
        onChangeTime = (e, instance) => {
          const val = instance.getVal()
          form.time = val
          console.log('onChangeTime', x, val)
          this.forceUpdate()
        }
        return <BnDrAppointment key={x.id} update={update} updateStatus={updateStatus} call={call} isAdmin={this.props.isAdmin} doctor={form.doctor} appt={form} doctorForm={form.doctorForm} onChangeDoctor={onChangeDoctor} book={book} editProvider={editProvider} modifyProvider={modifyProvider} decline={decline} cancel={cancel} onChangeDate={onChangeDate} onChangeTime={onChangeTime} date={date} time={time}/>
      }), () => <Sep/>)
      }
      </div>
  }
}

class BnAppointmentsMenuPage extends BnSubpage {


  componentDidMount() {
  }

  componentWillUnmount() {
  }

  openAppointments = status => {
    const ob = () => this.props.me.observeDoctorAppointments(this.props.doctor.id, status).pipe(map(x => {
      x.appt.doctor = this.props.doctor
      return x
    }))
    this.setState({
      subpage: () => <BnAppointmentsPage observeAppointments={ob} me={this.props.me} status={status} back={this.back}/>
    })
  }

  openRequested = async () => this.openAppointments('requested')
  openScheduled = async () => this.openAppointments('scheduled')
  openBooked = async () => this.openAppointments('booked')
  openCompleted = async () => this.openAppointments('completed')
  openCanceled = async () => this.openAppointments('canceled')
  openDeclined = async () => this.openAppointments('declined')
  
  renderContent() {
    let summary = this.props.summary
    if (!summary) summary = { requested: 0, scheduled: 0, booked: 0, completed: 0, canceled: 0 }
    const { requested, scheduled, booked, completed, canceled } = summary
    return <div className='bnPageContent'>
      <BnLabel1 text='Appointments'/>
      <BnFormFields>
      <BnPageButton label={'Requested'} count={requested} action={this.openRequested}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Scheduled'} count={scheduled} action={this.openScheduled}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Booked'} count={booked} action={this.openBooked}/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Completed'} count={completed} action={this.openCompleted}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Canceled'} count={canceled} action={this.openCanceled}/>
      </BnFormFields>
      </div>
  }
}


class BnServicesPage extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.form.searchTerm = ''
    this.state.form.defaultPrice = 180
    this.state.form.defaultTelehealthPrice = 45
    this.state.form.reasons = {}
    this.state.searchForm = {}
    this.state.searchForm.specialties = {}
    this.state.searchResults = []
    this.state.services = []
    this.state.operatingHours = {}
    this.options = []
  }


  onChange = (field, value) => {
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  openPage = x => {
  }

  search = async searchTerm => {
    this.setState({
      searchTerm
    })
    console.log('search', this.state.services)
    const searchResults = await search(this.state.services,
                                       x => x.code,
                                       x => x.description,
                                       searchTerm)
    console.log('searchResults', searchResults)
    this.setState({
      searchResults 
    })
  }

  forms = {}

  getPricingForm = x => {
    if (!this.forms[x.id]) {
      this.forms[x.id] = {useDefaultPrice: true, acceptTelehealth: true, acceptInPerson: true}
    }
    return this.forms[x.id]
  }

  chooseSpecialty = () => {
    const options = this.options
    const form = this.state.searchForm.specialties
    const action = async (option) => {
      this.back()
    }
    const onChange = (name, value) => {
      form[name] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser title={'Specialties'} subtitle={'Select specialties'} me={this.props.me} form={form} onChange={onChange}  back={this.back} options={options} action={action}/>
    })

  }
  
  openChooser = () => {
    const selection = {}
    this.state.services.forEach(x => {
      selection[x.code] = x
    })
    const select = x => {
      if (selection[x.code]) {
        delete selection[x.code]
      } else {
        selection[x.code] = x
      }
      this.forceUpdate()
    }
    const applyServiceChoices = async () => {
      const services = Object.values(selection)
      for (const x of services) {
        if (!x.prices) {
          x.prices = await this.props.me.resolvePrice(x.code)
        }
      }
      console.log('services', services)
      this.setState({
        services,
        subpage: null
      })
    }
    this.setState({
      subpage: () => <BnServicesChooser search={this.props.search} select={select} selection={selection} title={this.props.title} me={this.props.me} services={this.state.services} back={this.back} action={applyServiceChoices}/>
    })
  }

  renderSearchFilters = () => {
    const form = this.state.searchForm.specialties
    const filters = Object.keys(form).filter(x => form[x])
    filters.sort()
    const onChangeSearchFilter = (name, value) => {
      if (value) {
        form[name] = value
      } else {
        delete form[name]
      }
      this.forceUpdate();
    }
    const selection = filters
    return <div className='bnSearchFilters'>
      <BnSelectionList label={'Specialties'} selection={this.options.length} action={this.chooseSpecialty}/>
      <div className='bnSearchFiltersActive'>
      {
        joinWith(filters.map(x => {
          return <BnCheckbox name={x} label={x} form={this.state.searchForm.specialties} onChange={onChangeSearchFilter}/>
        }), () => <BnInputFieldSeparator/>)
      }
     </div>
    </div>
  }
  
  renderContent() {
    const title = this.props.title
    const data = this.state.data || []
    const form = this.state.form
    const formErr = this.state.formErr
    const onChange = this.onChange
    let start = 1
    let end = 30
    let searchResults = this.state.searchTerm ? this.state.searchResults : this.state.services
    console.log('render services', searchResults)
    const total = this.state.services.length
    start = Math.min(1, searchResults.length)
    end = Math.min(end, searchResults.length)
    //searchResults = searchResults.slice(start-1, end-1)
    return <div className='bnAdminAddSpecialty'>
      <BnSearchField label='Search' searchTerm={this.state.searchTerm} search={this.search}/>
      <Sep/>
      <BnButton label={'Add ' + this.props.title} action={this.openChooser}/>
      <Sep/>
      <BnFormFields>
      <BnInputField type={'currency'} name='defaultPrice' label='Default In-person Price' form={form} onChange={onChange}/>
      <BnInputFieldSeparator/>
      <BnInputField type={'currency'} name='defaultTelehealthPrice' label='Default Telehealth Price' form={form} onChange={onChange}/>
      </BnFormFields>
      <Sep/>
      <div className='bnSearchResultSummary'>{start}-{end} of {total}</div>
      <Sep/>
      {searchResults.map((x, i) => {
        const action = () => {
        }
        const cardForm = this.getPricingForm(x)
        const onChangeCard = (field, value) => {
          cardForm[field] = value
          this.forceUpdate()
        }
        let className='bnServiceCard bnServiceType' + capitalize(x.type)
        const formatLabel = () => <div className={className}><div className='bnServiceLabelCode'>{x.code}</div><div className='bnServiceLabelDesc'>{x.description}</div></div>
              return <BnServiceCard form={cardForm} onChange={onChangeCard} label={formatLabel(x)} defaultTelehealthPrice={form.defaultTelehealthPrice} defaultPrice={x.prices.results.length ? x.prices.results[0].nfac_price : 0} action={action}/>
      })}
      </div>
  }
}

const Services = [
  {
    categories: ['primaryCare', 'specialtyCare', 'acupuncture', 'chiropractic', 'vision'],
    services: [
      {
        description: '20 Minute Office Visit',
        code: '99202',
        duration: 20,
        low: 100,
        hi: 250
      },
      {
        description: '30 Minute Office Visit',
        code: '99203',
        duration: 30,
        low: 125,
        hi: 300
      },
      {
        description: '45 Minute Office Visit',
        code: '99204',
        duration: 45,
        low: 175,
        hi: 500
      },
      {
        description: '60 Minute Office Visit',
        code: '99205',
        duration: 60,
        low: 200,
        hi: 650
      }
    ]
  },
  {
    categories: ['dental'],
    services: [
      {
        code: 'D01110',
        description: 'Dental Cleaning',
        low: 120,
        high: 165
      },
      {
        code: 'D00150',
        description: 'Comprehensive Dental Exam',
        low: 120,
        high: 187
      },
      {
        code: 'D00210',
        description: 'Full Mouth X-ray',
        low: 197,
        high: 330
      }]
  },
  {
    categories: ['behavioralHealth'],
    services: [
      {
        code: '90791',
        description: 'Diagnostic Evaluation',
        low: 120,
        hi: 350
      }]
  }
]

class BnOfficeVisitChooser extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.state.options = []
    this.state.selection = {}
  }
  componentDidMount() {
    const { provider } = this.props
    const { specialties } = provider
    const psychologist = '103T00000X'
    const dentist = "122300000X"
    const generalPracticeDentist = '1223G0001X'
    const isDentist = specialties.find(x => x.code == dentist || x.code === generalPracticeDentist)
    const isPsych = specialties.find(x => implies(psychologist, x.code))
    let services
    if (isDentist) {
      services = Services.find(x => x.categories.find(y => y == 'dental')).services
    } else if (isPsych) {
      services = Services.find(x => x.categories.find(y => y == 'behavioralHealth')).services
    } else {
      services = Services[0].services
    }
    this.state.services = services.map(x => {
      const { code, description, low, hi } = x
      return {
        code,
        description,
        low,
        hi,
        price: low
      }
    })
    const options = services.map(x => {
      return {
        name: `(${x.code}) ${x.description}`,
        value: x.code
      }
    })
    const selected = {}
    if (provider.services) {
      provider.services.forEach(x => {
        selected[x.code] = true
      })
    }
    this.state.options = options
    this.state.selection = selected
    this.forceUpdate()
  }

  action = async () => {
    const services = []
    for (const code in this.state.selection) {
      const service = this.state.services.find(x => x.code === code)
      services.push(service)
    }
    return this.props.action(services)
  }
  render() {
    const onChange = (option, value) => {
      if (value) {
        this.state.selection[option] = value 
      } else {
        delete this.state.selection[option]
      }
      this.forceUpdate()
    }
    return <BnSelectionListChooser title='Services' me={this.props.me} options={this.state.options} form={this.state.selection} onChange={onChange} back={this.props.back} action={this.action}/>
  }
}

class BnServicesChooser extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.form.searchTerm = ''
    this.state.form.defaultPrice = 180
    this.state.form.defaultTelehealthPrice = 45
    this.state.form.reasons = {}
    this.state.searchForm = {}
    this.state.searchForm.specialties = {}
    this.state.searchResults = { resultCount: 0, results: [] }
    this.state.options = {}
  }

  onChange = (field, value) => {
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  openPage = x => {
  }

  search = async searchTerm => {
    this.setState({
      searchTerm
    })
    const searchResults = await this.props.me.procedureSearch(searchTerm)
    this.setState({
      searchResults
    })
  }

  forms = {}

  getPricingForm = x => {
    if (!this.forms[x.id]) {
      this.forms[x.id] = {useDefaultPrice: true, acceptTelehealth: true, acceptInPerson: true}
    }
    return this.forms[x.id]
  }

  chooseSpecialty = () => {
    const options = this.options
    const form = this.state.searchForm.specialties
    const action = async (option) => {
      this.back()
    }
    const onChange = (name, value) => {
      form[name] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser me={this.props.me} form={form} onChange={onChange}  back={this.back} options={options} action={action}/>
    })
  }

  renderSearchFilters = () => {
    const form = this.state.searchForm.specialties
    const filters = Object.keys(form).filter(x => form[x])
    filters.sort()
    const onChangeSearchFilter = (name, value) => {
      if (value) {
        form[name] = value
      } else {
        delete form[name]
      }
      this.forceUpdate();
    }
    const selection = filters
    return <div className='bnSearchFilters'>
      <BnSelectionList label={'Specialties'} selection={this.options.length} action={this.chooseSpecialty}/>
      <div className='bnSearchFiltersActive'>
      {
        joinWith(filters.map(x => {
          return <BnCheckbox name={x} label={x} form={this.state.searchForm.specialties} onChange={onChangeSearchFilter}/>
        }), () => <BnInputFieldSeparator/>)
      }
     </div>
    </div>
  }
  
  renderContent() {
    const title = this.props.title
    const data = this.state.data || []
    const form = this.state.form
    const formErr = this.state.formErr
    const onChange = this.onChange
    let start = 1
    let end = 30
    console.log("searchResults", this.state.searchResults)
    let { resultCount, results } = this.state.searchResults
    if (!this.state.searchTerm) {
      results = this.props.services.filter(x => this.isSelected(x))
      resultCount = results.length
    }
    const searchResults = results
    const total = resultCount
    for (const _ in this.state.searchForm.specialties) {
      searchResults = searchResults.filter(x => {
        console.log("FILTER", x, this.state.searchForm.specialties)
        return x.specialties && x.specialties.find(y => this.state.searchForm.specialties[y])
      })
      break
    }
    start = Math.min(1, searchResults.length)
    end = Math.min(end, searchResults.length)
    if (false) searchResults.sort((x, y) => {
      return x.description.localeCompare(y.description)
    })
    return <div className='bnAdminAddSpecialty'>
      <BnSearchField label='Search' searchTerm={this.state.searchTerm} search={this.search}/>
      <Sep/>
      <div className='bnSearchResultSummary'>{start}-{end} of {total}</div>
      <Sep/>
      {joinWith(searchResults.slice(start-1, end).map((x, i) => {
        let className='bnSelectionListItem'
        if (this.isSelected(x)) {
          className += ' bnSelectionListItemSelected'
        }
        const formatLabel = () => <div className={'bnServiceLabel'} ><div className='bnServiceLabelCode'>{x.code}</div><div className='bnServiceLabelDesc'>{x.description}</div></div>
        return <div className={className} onClick={()=>this.toggleSelection(x)}>{formatLabel()}</div>
              }), () => <BnInputFieldSeparator/>)}
      </div>
  }

  toggleSelection = x => {
    this.props.select(x)
  }

  isSelected = x => {
    return this.props.selection[x.code]
  }
}

class BnPracticeProvider extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.reasons = []
    this.reasons = {}
    this.specialties = {}
    this.state.operatingHours = {}
    this.state.members = []
  }

  onRemoveMember = async form => {
    this.state.members = this.state.members.filter(x => x.id !== form.id)
    await this.props.me.removePracticeMember(this.props.doctor.id, form)
  }

  onUpdateMember = async form => {
    await this.props.me.updatePracticeMember(this.props.doctor.id, form)
  }

  onInviteMember = async form => {
    this.state.members.push({
      email: form.email,
      name: form.name
    })
    this.forceUpdate()
    const result = await this.props.me.invitePracticeMember(this.props.doctor.id, form)
  }
  
  updateDataLater = () => {
    clearTimeout(this.updateTimeout)
    this.updateTimeout = setTimeout(this.updateData, 200)
  }

  componentDidMount() {
    this.sub = this.props.me.observeDoctorBookingSummary(this.props.doctor.id).subscribe(summary => {
      this.setState({
        summary
      })
    })
    this.membersub = this.props.me.observePracticeMembers(this.props.doctor.id).subscribe(change => {
      const member = change.member
      if (change.type === 'removed') {
        delete this.members[member.id]
      } else {
        this.members[member.id] = member
      }
      this.updateDataLater()
    })
  }

  members = {}

  updateDataLater = () => {
    clearTimeout(this.dataUpdateTimeout)
    this.dataUpdateTimeout = setTimeout(this.updateData, 200)
  }
  
  updateData = () => {
    this.setState({
      members: Object.values(this.members),
    })
  }

  componentWillunmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  procedureSearch = async q => {
    return this.props.me.procedureSearch(q)
  }

  diagnosisSearch = async q => {
    return this.props.me.diagnosisSearch(q)
  }

  savePrice = async (reason, form) => {
    const updates = {
      doctor: this.props.doctor,
      reason,
      price: form.price,
      telehealthPrice: form.telehealthPrice,
      enabled: form.acceptInPerson,
      telehealthEnabled: form.acceptTelehealth
    }
    await this.props.me.saveDoctorPrice(updates)
  }

  openReasons = async () => {
    this.setState({
      subpage: () => {
        const specialties = this.props.specialties
        const reasons = this.props.reasons
        return <BnDoctorReasonsPage specialties={specialties} doctor={this.props.doctor} savePrice={this.savePrice} search={this.props.mainSearch} searchTerm={this.state.searchTerm} back={this.back} selectedSpecialties={this.props.doctor.specialties} me={this.props.me} title={'Prices'}  reasons={reasons}/>
      }
    })
  }

  openServices = async () => {
    this.setState({
      subpage: () => <BnServicesPage search={this.procedureSearch} searchTerm={this.state.searchTerm} back={this.back} specialty={this.props.doctor.specialty} me={this.props.me} title={'Procedures'}  reasons={this.state.services}/>
    })
  }

  openDiagnoses = async () => {
    this.setState({
      subpage: () => <BnServicesPage search={this.diagnosisSearch} searchTerm={this.state.searchTerm} back={this.back} specialty={this.props.doctor.specialty} me={this.props.me} title={'Diagnoses'}  reasons={this.state.diagnoses}/>
    })
  }

  onChangeOperatingHours = (field, value) => {
    this.state.operatingHours[field] = value
    this.forceUpdate()
  }

  openOperatingHours = async () => {
    this.setState({
      subpage: () => <BnOperatingHoursPage back={this.back} doctor={this.props.doctor} me={this.props.me} form={this.getForm()} formErr={this.getFormErr()} onChange={this.onChangeOperatingHours}/>
    })
  }

  openAppointments = async () => {
    this.setState({
      subpage: () => <BnAppointmentsMenuPage summary={this.state.summary} doctor={this.props.doctor} back={this.back} me={this.props.me}/>
    })
  }

  openMembers = async () => {
    const isAdmin = () => true
    this.setState({
      subpage: () => <BnBusinessMembership onInviteMember={this.onInviteMember} onUpdateMember={this.onUpdateMember} onRemoveMember={this.onRemoveMember} isAdmin={isAdmin}  me={this.props.me} back={this.back} members={this.state.members}/>
    })
  }

  openAccount = async () => {
    const action = async () => {
      this.back()
    }
    const { specialty, name, practiceName, address, place } = this.props.doctor
    const { address_components} = place
    const streetNumber = address_components.find(x => x.types.find(y => y == 'street_number')).short_name
    let suite = ''
    const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
    if (subPremise) {
      suite = `, ${subPremise.short_name}`
    }
    const streetAddress = address_components.find(x => x.types.find(y => y == 'route')).short_name
    const city = address_components.find(x => x.types.find(y => y == 'locality')).short_name
    const state = address_components.find(x => x.types.find(y => y == 'administrative_area_level_1')).short_name
    const zip = address_components.find(x => x.types.find(y => y == 'postal_code')).short_name
    const specialties = this.props.doctor.specialties
    const form = mergeFields(this.props.doctor, { specialties, city, state, zip, address: `${streetNumber} ${streetAddress}${suite}` })
    console.log('doctor form', form)
    const formErr = {}
    const onChange = (field, value) => {
      form[field] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnDoctorAccountSetup me={this.props.me} form={form} formErr={formErr} onChange={onChange} back={this.back} action={action}/>
    })
  }

  
  
  renderContent() {
    let count = ''
    let appointmentsCount = '' 
    if (this.state.summary) {
      const { requested, scheduled, booked } = this.state.summary
      if (requested > 0) {
        appointmentsCount = requested + ' Request'
      } 
    }
    return <div className='bnPageContent'>
      <BnFormFields>
      <BnPageButton label={'Prices'} count={count} action={this.openReasons}/>
      <BnInputFieldSeparator/>
      {true && <div>
      <BnPageButton label={'Procedures'} count={count} action={this.openServices}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Diagnoses'} count={count} action={this.openDiagnoses}/>
      <BnInputFieldSeparator/>
       </div>}
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Availability'} count={count} action={this.openOperatingHours}/>
      <Sep/>
      <BnInputFieldSeparator/>      
      <BnPageButton label={'Appointments'} count={appointmentsCount} action={this.openAppointments}/>
      <Sep/>
      <BnInputFieldSeparator/>      
      <BnPageButton label={'Members'} count={this.state.members.length} action={this.openMembers}/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Payments'} count={count} action={this.openPayments}/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Profile'} count={count} action={this.openAccount}/>
      <BnInputFieldSeparator/>
      </BnFormFields>
      </div>
  }
}

class BnDoctorPractice extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.reasons = []
    this.reasons = {}
    this.specialties = {}
    this.state.operatingHours = {}
    this.state.members = []
    this.state.providers = []
  }

  onRemoveMember = async form => {
    this.state.members = this.state.members.filter(x => x.id !== form.id)
    await this.props.me.removePracticeMember(this.props.practice.id, form)
  }

  onUpdateMember = async form => {
    await this.props.me.updatePracticeMember(this.props.practice.id, form)
  }

  onInviteMember = async form => {
    this.state.members.push({
      email: form.email,
      name: form.name
    })
    this.forceUpdate()
    const result = await this.props.me.invitePracticeMember(this.props.practice.id, form)
  }
  
  updateDataLater = () => {
    clearTimeout(this.updateTimeout)
    this.updateTimeout = setTimeout(this.updateData, 200)
  }

  componentDidMount() {
    this.sub = this.props.me.observeDoctorBookingSummary(this.props.practice.id).subscribe(summary => {
      this.setState({
        summary
      })
    })
    this.membersub = this.props.me.observePracticeMembers(this.props.practice.id).subscribe(change => {
      const member = change.member
      if (change.type === 'removed') {
        delete this.members[member.id]
      } else {
        this.members[member.id] = member
      }
      this.updateDataLater()
    })
    if (this.props.practice) {
      const { providers } = this.props.practice
      this.setState({
        providers
      })
    }
  }

  members = {}

  updateDataLater = () => {
    clearTimeout(this.dataUpdateTimeout)
    this.dataUpdateTimeout = setTimeout(this.updateData, 200)
  }
  
  updateData = () => {
    this.setState({
      members: Object.values(this.members),
    })
  }

  componentWillunmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  procedureSearch = async q => {
    return this.props.me.procedureSearch(q)
  }

  diagnosisSearch = async q => {
    return this.props.me.diagnosisSearch(q)
  }

  savePrice = async (reason, form) => {
    const updates = {
      doctor: this.props.doctor,
      reason,
      price: form.price,
      telehealthPrice: form.telehealthPrice,
      enabled: form.acceptInPerson,
      telehealthEnabled: form.acceptTelehealth
    }
    await this.props.me.saveDoctorPrice(updates)
  }

  openReasons = async () => {
    this.setState({
      subpage: () => {
        const specialties = this.props.specialties
        const reasons = this.props.reasons
        return <BnDoctorReasonsPage specialties={specialties} doctor={this.props.doctor} savePrice={this.savePrice} search={this.props.mainSearch} searchTerm={this.state.searchTerm} back={this.back} selectedSpecialties={this.props.doctor.specialties} me={this.props.me} title={'Prices'}  reasons={reasons}/>
      }
    })
  }

  openServices = async () => {
    this.setState({
      subpage: () => <BnServicesPage search={this.procedureSearch} searchTerm={this.state.searchTerm} back={this.back} specialty={this.props.doctor.specialty} me={this.props.me} title={'Procedures'}  reasons={this.state.services}/>
    })
  }

  openDiagnoses = async () => {
    this.setState({
      subpage: () => <BnServicesPage search={this.diagnosisSearch} searchTerm={this.state.searchTerm} back={this.back} specialty={this.props.doctor.specialty} me={this.props.me} title={'Diagnoses'}  reasons={this.state.diagnoses}/>
    })
  }

  onChangeOperatingHours = (field, value) => {
    this.state.operatingHours[field] = value
    this.forceUpdate()
  }

  openOperatingHours = async () => {
    this.setState({
      subpage: () => <BnOperatingHoursPage back={this.back} doctor={this.props.doctor} me={this.props.me} form={this.getForm()} formErr={this.getFormErr()} onChange={this.onChangeOperatingHours}/>
    })
  }

  openAppointments = async () => {
    this.setState({
      subpage: () => <BnAppointmentsMenuPage summary={this.state.summary} doctor={this.props.doctor} back={this.back} me={this.props.me}/>
    })
  }

  openProviders = async () => {
    this.setState({
      subpage: () => <BnPracticeProviders practice={this.props.practice} providers={this.state.providers} back={this.back} me={this.props.me}/>
    })
  }

  openMembers = async () => {
    const isAdmin = () => true
    this.setState({
      subpage: () => <BnBusinessMembership onInviteMember={this.onInviteMember} onUpdateMember={this.onUpdateMember} onRemoveMember={this.onRemoveMember} isAdmin={isAdmin}  me={this.props.me} back={this.back} members={this.state.members}/>
    })
  }

  openAccount = async () => {
    const action = async () => {
      this.back()
    }
    const { specialty, name, practiceName, address, place } = this.props.practice
    const { address_components} = place
    const streetNumber = address_components.find(x => x.types.find(y => y == 'street_number')).short_name
    let suite = ''
    const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
    if (subPremise) {
      suite = `, ${subPremise.short_name}`
    }
    const streetAddress = address_components.find(x => x.types.find(y => y == 'route')).short_name
    const city = address_components.find(x => x.types.find(y => y == 'locality')).short_name
    const state = address_components.find(x => x.types.find(y => y == 'administrative_area_level_1')).short_name
    const zip = address_components.find(x => x.types.find(y => y == 'postal_code')).short_name
    const specialties = this.props.practice.specialties
    const form = mergeFields(this.props.practice, { specialties, city, state, zip, address: `${streetNumber} ${streetAddress}${suite}` })
    console.log('doctor form', form)
    const formErr = {}
    const onChange = (field, value) => {
      form[field] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnDoctorAccountSetup me={this.props.me} form={form} formErr={formErr} onChange={onChange} back={this.back} action={action}/>
    })
  }

  renderContent() {
    let count = ''
    let appointmentsCount = '' 
    if (this.state.summary) {
      const { requested, scheduled, booked } = this.state.summary
      if (requested > 0) {
        appointmentsCount = requested + ' Request'
      } 
    }
    return <div className='bnPageContent'>
      <BnFormFields>
      <BnPageButton label={'Appointments'} count={appointmentsCount} action={this.openAppointments}/>
      <Sep/>
      <BnInputFieldSeparator/>      
      <BnPageButton label={'Providers'} count={this.state.providers.length} action={this.openProviders}/>
      <Sep/>
      <BnInputFieldSeparator/>      
      <BnPageButton label={'Members'} count={this.state.members.length} action={this.openMembers}/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Payments'} count={count} action={this.openPayments}/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Profile'} count={count} action={this.openAccount}/>
      <BnInputFieldSeparator/>
      </BnFormFields>
      </div>
  }
}

class BnDoctorMyPractices extends BnSubpage {

  constructor (props) {
    super(props)
  }

  openProvider = getProvider => {
    const form = mergeFields(getProvider())
    const onChange = (field, value) => {
      form[field] = value
      this.forceUpdate()
    }
    const save = async () => {
    }
    this.setState({
      subpage: () => {
        const provider = getProvider()
        return <BnDoctorProfile doctor={provider} back={this.back} me={this.props.me} form={form} onChange={onChange} save={save}/>
      }
    })
  }

  openPractice = getPractice => {
    this.setState({
      subpage: () => {
        const practice = getPractice()
        return <BnDoctorPractice reasons={this.props.reasons} specialties={[]} mainSearch={this.props.mainSearch} practice={practice} me={this.props.me} title={practice.practiceName || practice.name} back={this.back}/>
      }
    })
  }

  addPractice = () => {
    this.setState({
      subpage: () => <BnDoctorPlanSetup me={this.props.me} back={this.back} />
    })
  }

  renderContent() {
    const searchResults = this.props.searchResults
    let label1
    let buttonLabel
    if (this.props.isAdmin) {
      if (this.props.type === 'providers') {
        label1 = 'All Providers'
        buttonLabel = 'Add Provider'
      } else {
        label1 = 'All Practices'
        buttonLabel = 'Add Practice'
      }
    } else {
      label1 = 'My Practices'
    }
    
    return <div className='bnPageContent'>
      <BnLabel1 text={label1}/>
      {this.props.isAdmin && [<BnSearchField label='Search' searchTerm={this.props.searchTerm} search={this.props.search}/>, <Sep/>]}
      <BnButton label={buttonLabel} action={this.addPractice}/>
      <Sep/>
      {joinWith(searchResults.map(x => {
        const action = () => {
          if (this.props.type === 'practices') {
            this.openPractice(() => x)
          } else {
            this.openProvider(() => x)
          }
        }
        switch (this.props.type) {
          case 'practices':
            return <BnPracticeCard practice={x} action={action}/>
          case 'providers':
            return <BnProviderCard provider={x} action={action}/>
        }
      }), () => <Sep/>)
      }
      </div>
  }
}



  

class BnOperatingHoursPage extends BnSubpage {

  saveAvailability = async () => {
    const form = this.getForm()
    const { timesOfDay, daysOfWeek } = form
    const doctor = this.props.doctor
    await this.props.me.saveDoctorAvailability({doctor, timesOfDay, daysOfWeek })
  }
  
  componentDidMount() {
    const form = this.getForm()
    form.timesOfDay = {
    }
    timesOfDay.forEach(x => form.timesOfDay[x.name] = true)
    form.daysOfWeek = {
    }
    daysOfWeek.slice(0, 5).forEach(day => {
      form.daysOfWeek[day.name] = true
    })
    this.props.onChange('daysOfWeek', form.daysOfWeek)
    this.props.onChange('timesOfDay', form.timesOfDay)
  }

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.props.onChange
    const email = form.email
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Availability'/>
      <BnLabel2 text={`When would you like to provide services to BeNxT customers?`}/>
      <Sep/>
      <div className='bnDates'>
      <BnLabel text={'Days of Week'}/>
      <Sep/>
      <BnCheckboxes>
      {daysOfWeek.map(day => {
        const onChecked = (field, value) => {
          const daysOfWeek = form.daysOfWeek || {}
          daysOfWeek[field] = value
          onChange('daysOfWeek', daysOfWeek)
        }
        return <BnCheckbox form={form.daysOfWeek || {}} name={day.name} label={day.label} onChange={onChecked}/>
      })}
      </BnCheckboxes>
      <Sep/>
      <Sep/>
      <BnLabel text={'Times of Day'}/>
      <Sep/>
      <BnCheckboxes>
      {timesOfDay.map(x => {
        const onChecked = (field, value) => {
          const timesOfDay = form.timesOfDay || {}
          timesOfDay[field] = value
          onChange('timesOfDay', timesOfDay)
        }
        return <BnCheckbox form={form.timesOfDay || {}} name={x.name} label={x.label} onChange={onChecked}/>
      })}
      </BnCheckboxes>
      </div>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label='Save' action={this.saveAvailability}/>
      </div>
  }
}

const addressFields = [
    {
      name: 'address',
      label: 'Street Address',
      autoComplete: 'street-address'
    },
    {
      name: 'city',
      label: 'City',
      autoComplete: 'address-level2'
    },
    {
      name: 'state',
      label: 'State',
      autoComplete: 'address-level1'
    },
    {
      name: 'country',
      label: 'Country',
      autoComplete: 'country'
    },
    {
      name: 'zip',
      label: 'Zip',
      autoComplete: 'postal-code'
    }]
  
  
class BnDoctorAccountVerification extends BnSubpage {

  action = () => {
    this.setState({
      subpage: () => <BnDoctorAccountDetails back={this.back} me={this.props.me} form={this.getForm()} formErr={this.getFormErr()} onChange={this.props.onChange}/>
    })
  }
  
  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.props.onChange
    const email = form.email
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Account verification'/>
      <BnLabel2 text={`Please check your email account ${email} for your verification code`}/>
      <Sep/>
      <BnFormFields>
      <BnInputField label={'Verification Code'} name='verificationCode' type='one-time-code' form={form} formErr={formErr} onChange={onChange}/>
      </BnFormFields>
      <Sep/>
      <div className='bnSignInRecoverPasswordLine'>
      <div className='bnSignInRecoverPassword' onClick={this.resendVerificationCode}>Resend verification code</div>
      </div>
      <Sep/>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}

const BnServicePriceCard = props => {
  const { doctor, service } = props
  const { avgSubmittedCharge, price, desc, description, code } = service
  return <div className='bnServiceCard'>
    <BnFormFields>
    <div className='bnFieldTypeLabel'>{'SERVICE'}</div>
    <div className='bnReasonCardLabel'>({code}) {desc || description}</div>

    <div className='bnCurrencyInput'>
    <div className='bnCurrencyInputLabel'>{'Price'}</div>
    <div className='bnCurrencyInputInput'>${Math.round(price || avgSubmittedCharge)}</div>
      </div>
    </BnFormFields>
  </div>
}

class BnDoctorProfile extends BnSubpage {

  constructor (props) {
    super(props)
  }

  componentDidMount() {
    const { doctor } = this.props
    if (doctor) {
      for (const field of ['gender', 'creds', 'edu', 'specialties']) {
        this.set(field, doctor[field])
      }
    }
  }

  addService = async () => {
    const form = this.getForm()
    const applyServiceChoices = async (services) => {
      form.services = services
      this.back()
    }
    this.setState({
      subpage: () => <BnOfficeVisitChooser provider={form} me={this.props.me} back={this.back} action={applyServiceChoices}/>
    })
  }
  
  addService1 = async () => {
    const selection = {}
    this.state.services.forEach(x => {
      selection[x.code] = x
    })
    const select = x => {
      if (selection[x.code]) {
        delete selection[x.code]
      } else {
        selection[x.code] = x
      }
      this.forceUpdate()
    }
    const applyServiceChoices = async () => {
      const services = Object.values(selection)
      console.log('services', services)
      this.setState({
        services,
        subpage: null
      })
    }
    this.setState({
      subpage: () => <BnServicesChooser select={select} selection={selection} title={this.props.title} me={this.props.me} services={this.state.services} back={this.back} action={applyServiceChoices}/>
    })
  }
  
  renderContent() {
    const genderOptions = [
      {
        name: "Female",
        value: "F"
      },
      {
        name: "Male",
        value: "M"
      }
    ]
    const form = this.getForm()
    const services = form.services || []
    const formErr = this.getFormErr()
    const onChange = this.props.onChange
    const creds = form.credentials || []
    let gender = form.gender
    let certifications = creds.join(', ')
    let specialty
    if (form.specialties.length > 1) {
      specialty = form.specialties.length
    } else {
      form.specialties.map(x => x.label).join(', ')
    }
    return <div className='bnSubpageContent'>
      <BnLabel1 text={form.name}/>
      <BnFormFields>
      <BnPageButton label={'Credentials'} count={certifications} action={this.chooseCertifications}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Specialties'} count={specialty} action={this.chooseSpecialties}/>
      <BnInputFieldSeparator/>
      <BnSelectionList label={'Gender'} selection={gender} options={genderOptions} action={this.chooseGender}/>
      <BnInputFieldSeparator/>
      <BnNegBoolInput label='Disabled' form={form} name={'disabled'} onChange={onChange}/>
      </BnFormFields>
      <Sep/>
      <BnLabel1 text='Services'/>
      <Sep/>
      <BnBlueButton label='Modify Services' action={this.addService}/>
      <Sep/>
      {services.map(service => {
        return <BnServicePriceCard doctor={this.props.doctor} service={service}/>
      })
      }
      </div>
  }
}

class BnPracticeProviders extends BnSubpage {

  openProvider = (provider) => {
    const form = mergeFields(provider)
    const onChange = (field, value) => {
      form[field] = value
      this.forceUpdate()
    }
    const save = async () => {
    }
    this.setState({
      subpage: () => <BnDoctorProfile doctor={provider} onChange={onChange} form={form} me={this.props.me}  back={this.back} save={save}/>
    })
  }

  addProvider = async () => {
  }
  
  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.props.onChange
    const providers = this.props.providers
    return <div className='bnSubpageContent'>
      <BnLabel1 text = 'Providers'/>
      <Sep/>
      <BnBlueButton label='Add Provider' action={this.addProvider}/>
      <Sep/>
      {this.props.providers.map(provider => {
        const openProvider = async () => this.openProvider(provider)
        return <BnProviderCard practice={this.props.practice} provider={provider} action={openProvider}/>
      })
      }
      <Sep/>
      </div>
  }
}


class BnDoctorAccountSetup extends BnSubpage {

  action = () => {
    const form = this.getForm()
    if (false) {
      if (!form.email) {
        return this.reportFormErr({field: 'email', message: 'Email Address is required'})
      }
      if (!form.password) {
        return this.reportFormErr({field: 'phoneNumber', message: 'Phone Number is required'})
      }
      if (!form.password) {
        return this.reportFormErr({field: 'password', message: 'Password is required'})
      }
    }
    this.setState({
      subpage: () => <BnDoctorAccountVerification back={this.back} me={this.props.me} form={this.state.form} formErr={this.state.formErr} onChange={this.onChange}/>
    })
  }

  chooseNPIType = () => {
  }

  chooseCertifications = () => {
  }

  chooseLicenses = () => {
  }

  chooseLanguages = () => {
  }

  openDoctors = () => {
    const doctors = [this.props.form]
    this.setState({
      subpage: () => <BnDoctorProfiles me={this.props.me} doctors={doctors} back={this.back}/>
    })
  }
  
  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.props.onChange
    const doctors = [this.props.doctor]
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Practice'/>
      <BnFormFields>
      <BnInputField label={'Practice Name'} name='practiceName' type='name' form={form} formErr={formErr} onChange={this.onChange}/>
      <BnInputFieldSeparator/>
      <BnInputField label={'Phone Number'}  name='phoneNumber' type='tel' form={form} formErr={formErr} onChange={this.onChange}/>
      <BnInputFieldSeparator/>
      <BnInputField label={'Email Address'} name='email' type='text' form={form} formErr={formErr} onChange={this.onChange}/>
      <BnInputFieldSeparator/>
      <BnInputField label={'Password'} name='password' type='password' autoComplete={'new-password'} form={form} formErr={formErr} onChange={this.onChange}/>
      </BnFormFields>
      <Sep/>
      <BnPageButton label='Providers' count={doctors.length} action={this.openDoctors}/>
      <Sep/>
      <BnLabel1 text= 'Business address'/>
      <BnFormFields>
      {joinWith(addressFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }     
      </BnFormFields>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }

}

class BnDoctorPlanSetup extends BnSubpage {

  action = async () => {
    const form = this.getForm()
    if (!form.npi)  {
      this.setState({
        formErr: {field: 'npi', message: 'NPI Number is required'}
      })
      return
    }
    const resolved = await this.props.me.resolveNPI(form.npi)
    const { basic, addresses, taxonomies, practiceLocations, other_names }  =  resolved
    const address = addresses.find(x => x.address_purpose === 'LOCATION')
    const licenses = taxonomies
    const set = (field, value) => {
      console.log(field, value)
      form[field] = value
    }
    let { name_prefix, name, credential, gender, } = basic
    const credentials = (credential ? [credential] : []).concat(other_names.map(x => x.credential))
    if (name_prefix) {
      name = name_prefix + name
    }
    const specialties = []
    const seen = {}
    for (const t of taxonomies) {
      console.log(t)
      const taxonomy = await this.props.me.getTaxonomy(t.code)
      console.log(taxonomy)
      let { specialty } = taxonomy
      specialty = specialty.replace(/ in Private Practice/g, '')
      if (seen[specialty]) {
        continue
      }
      seen[specialty] = true
      specialties.push(specialty)
    }
    set('name', name)
    set('gender', gender)
    set('credentials', credentials)
    set('address', address.address_1)
    set('address2', address.address_2)
    set('city', address.city)
    set('state', address.state)
    set('zip', address.postal_code)
    set('country', address.country_code)
    set('phoneNumber', address.telephone_number)
    set('specialties', specialties)
    this.setState({
      subpage: () => <BnDoctorAccountSetup back={this.back} me={this.props.me} form={this.getForm()} formErr={this.getFormErr()} onChange={this.onChange}/>
    })
  }

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.onChange;
    return <div className='bnPageContent'>
      <BnLabel1 text='Add a practice'/>
      <BnLabel2 text={`Please enter the NPI Number for this practice:`}/>
      <Sep/>
      <BnFormFields>
      <BnInputField label={'NPI Number'} name='npi' type='number' form={form} formErr={formErr} onChange={onChange}/>
      </BnFormFields>
      <Sep/>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}


export class BnDoctorMain extends BnSubpage {
  constructor (props) {
    super(props)
  }

  componentDidMount() {
  }


  renderContent() {
    <div className='bnPageContent'>
      </div>
    
  }
}

export class BnDoctorContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      plan: false,
      doctors: [],
      practices: [],
      practiceSearchTerm: '',
      out_of: 0,
      page: 1,
      results: []
    }
  }

  practices = {}
  updatePractices = () => {
    const practices = Object.values(this.practices)
    practices.sort((x, y) => x.name.localeCompare(y.name))
    this.setState({
      practices
    })
    if (this.props.isAdmin) {
      this.searchPractices(this.state.practiceSearchTerm)
    }
    console.log("updated practices")
  }

  searchPractices = async practiceSearchTerm => {
    this.setState({
      practiceSearchTerm
    })
    const searchTerm = practiceSearchTerm.trim()
    let searchResults = this.state.practices
    if (practiceSearchTerm) {
      const results = await this.mainSearch(searchTerm, 'practices')
      searchResults = results.map(result => result[result.type])
    }
    this.setState({
      practices: searchResults.filter(x => x.place)
    })
  }

  updatePracticesLater = () => {
    clearTimeout(this.dupdateTimeout)
    this.dupdateTimeout = setTimeout(this.updatePractices, 200)
  }

  componentWillUnmount() {
    if (this.reasonsSub) this.reasonsSub.unsubscribe()
    if (this.specialtiesSub) this.specialtiesSub.unsubscribe()
    if (this.worker) this.worker.terminate()
  }

  specialties = {}
  reasons = {}

  componentDidMount() {
    this.planSub = this.props.me.observeDoctorPlan().subscribe(change => {
      let plan = change.plan
      if (change.type === 'removed') {
        plan = null
      }
      this.setState({
        plan: plan
      })
    })
    if (false) {
      this.worker = new Worker('./Search.js')
      this.worker.addEventListener('message', this.onSearchResults)
      this.mainSearch('')
      this.sub = this.props.me.observeMyPractices(this.props.isAdmin).subscribe(change => {
      const practice = change.practice
        if (change.type === 'removed') {
          delete this.practices[practice.id]
        } else {
          this.practices[practice.id] = practice
        }
        console.log("practice changed", practice)
        this.worker.postMessage({
          type: 'upload',
          upload: {
            practices: [change]
          }
        })
        this.updatePracticesLater()
      })
      this.reasonsSub = this.props.me.observeVisitReasons().subscribe(change => {
        const { reason } = change
        if (change.type == 'removed') {
          delete this.reasons[reason.id]
        } else {
          this.reasons[reason.id] = reason
        }
        this.worker.postMessage({
          type: 'upload',
          upload: {
            reasons: [change]
          }
        })
        this.updateDataLater()
      })
      this.specialtiesSub = this.props.me.observeSpecialties().subscribe(change => {
        if (change.type === 'removed') {
          delete this.specialties[change.specialty]
        } else {
          this.specialties[change.specialty.id] = change.specialty
      }
        this.updateDataLater()
      })
    }
    this.mainSearch('')
  }

  updateDataLater = () => {
    clearTimeout(this.dataUpdateTimeout)
    this.dataUpdateTimeout = setTimeout(this.updateData, 200)
  }
  
  updateData = () => {
    this.setState({
      reasons: Object.values(this.reasons),
      specialties: Object.values(this.specialties)
    })
  }
  
  searchId = 0

  mainSearch = async (searchTerm, type) => {
    const searchId = ++this.searchId
    this.setState({
      searchTerm,
      searchComplete: false,
    })
    switch (this.props.type) {
      case 'practices':
        {
          const result = await this.props.me.searchPractices({ q: searchTerm })
          if (searchId === this.searchId) {
            result.searchComplete = true
            this.setState(result)
          }
        }
        break
      case 'providers':
        {
          const result = await this.props.me.searchProviders({ q: searchTerm })
          if (searchId === this.searchId) {
            result.searchComplete = true
            this.setState(result)
          }
        }
        break
    }
  }


  onSearchResults = e => {
    const {type, searchId, searchResults } = e.data
    if (type === 'autoComplete' || type === 'specialties' || type === 'practices') {
      if (searchId !== this.searchId) {
        this.state.searchCont()
        return
      }
      const k = this.searchCont
      this.searchCont = null
      k(searchResults)
    }
  }

  
  render() {
    let content
    if (false && (!this.state.plan || !this.props.me.self)) {
      content = <BnDoctorPlanSetup me={this.props.me} back={this.props.back} />
    } else {
      content = <BnDoctorMyPractices reasons={this.state.reasons} specialties={this.state.specialties} mainSearch={this.mainSearch} searchTerm={this.state.searchTerm} search={this.mainSearch} isAdmin={this.props.isAdmin} type={this.props.type} out_of={this.state.out_of} page={this.state.page} searchResults={this.state.results} me={this.props.me} back={this.props.back}/>
    }
    return <div className='bnPageContent bnDoctor'>
      {
        content
      }
      </div>
  }
}

export class BnDoctor extends BnApp {

  renderSignUp () {
    return this.renderContent()
  }

  renderContent () {
    return <BnDoctorContent me={this.props.me} back={this.back}/>
  }

}
