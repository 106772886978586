import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnForm, BnFormFieldSeparator as Sep } from '../Form'
import { BnButton, BnBackButton } from '../Button'
import { BnInputField, BnInputFieldSeparator } from '../TextInput'
import BxLogo from '../../assets/Marks/bxLogo.svg'
import './index.css'

export class BnSignIn extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  onChange = (field, value) => {
    this.props.onChange(field, value)
  }

  recoverPassword = async () => {
    this.props.onChange('email', this.props.form.email)
    this.setState({
      passwordResetEmailSent: false,
      passwordRecovery: true
    })
  }

  action = async () => {
    await this.props.action()
  }

  back = async () => {
    if (this.state.passwordResetEmailSent) {
      return this.setState({
        passwordRecovery: false
      })
    }
    return await this.props.back()
  }

  performPasswordRecovery = async () => {
    await this.props.recoverPassword()
    this.setState({
      passwordResetEmailSent: true
    })
  }

  renderRecoverPassword = () => {
    const form = this.props.form
    console.log("form", form)
    return <div className='bnSignIn'>
      <BnForm>
      <div className='bnSignInLogo'><ReactSVG src={BxLogo}/></div>
      <BnBackButton action={this.back}/>
      <Sep/>
      <BnLabel1 text={'Recover password'}/>
      <div className='bnSignInFields'>
      <BnInputField name='email' label={'Email Address'} formErr={this.props.formErr} form={form} type='email' onChange={this.onChange}/>
      </div>
      <div className='bnSignInErr'>{this.props.formErr && this.props.formErr.message}</div>
      {
        this.state.passwordResetEmailSent ?
          <div className='bnPasswordResetMessage'>A password reset message has been sent to your email address</div>
          :
          <div className='bnSignInContinue'>
          <BnButton label={'Reset Password'} action={this.performPasswordRecovery}/>
          </div>
      }
      </BnForm>
      </div>
  }

  render() {
    if (this.state.passwordRecovery) return this.renderRecoverPassword()
    const form = this.props.form
    console.log("form", form)
    return <div className='bnSignIn'>
      <BnForm>
      <div className='bnSignInLogo'><ReactSVG src={BxLogo}/></div>
      <BnBackButton action={this.back}/>
      <div className='bnSignInChoice' onClick={this.props.selectPhoneSignIn}>Sign in with phone number</div>
      <Sep/>
      <BnLabel1 text={'Sign in'}/>
      <BnLabel2 text='Please enter your email address and password'/>
      <Sep/>
      <div className='bnSignInFields'>
      <BnInputField name='email' label={'Email Address'} formErr={this.props.formErr} form={form} type='email' onChange={this.onChange}/>
      <BnInputFieldSeparator/>
      <BnInputField name='password' label={'Password'} formErr={this.props.formErr} form={form} type='password' onChange={this.onChange}/>
      </div>
      <div className='bnSignInRecoverPasswordLine'>
      <div className='bnSignInRecoverPassword' onClick={this.recoverPassword}>Recover password</div>
      </div>
      <div className='bnSignInErr'>{this.props.formErr && this.props.formErr.message}</div>
      <div className='bnSignInContinue'>
      <BnButton label={'Sign in'} action={this.action}/>
      </div>
      </BnForm>
      </div>
  }
}
