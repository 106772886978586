import { Subject, merge } from 'rxjs'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import {loadStripe} from '@stripe/stripe-js';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { BnSplash } from '../Splash'
import { BnCustomer } from '../Customer'
import { BnDoctor } from '../Doctor'
import { BnBusiness } from '../Business'
import { BnAdmin } from '../Admin'
import { Me } from '../../classes/Me'
import { isSafari, isDesktop, isMobile, isIOS } from '../../Platform.js'
import './index.css'


const firebaseConfigDev = {
  apiKey: "AIzaSyCAAoMFYxYq1mZhIEepk78EUYAvCe4QNHA",
  authDomain: "benxtclouddev.firebaseapp.com",
  projectId: "benxtclouddev",
  storageBucket: "benxtclouddev.appspot.com",
  messagingSenderId: "721498129597",
  appId: "1:721498129597:web:f565064e49989a9eac63d4",
  measurementId: "G-MFTD5WGH91"
}

const firebaseConfigProd = {
  apiKey: "AIzaSyBMjW61u67dJ6e70o2OGcMum5XgS1UbJj0",
  authDomain: "benxt-cloud.firebaseapp.com",
  projectId: "benxt-cloud",
  storageBucket: "benxt-cloud.appspot.com",
  messagingSenderId: "926532441932",
  appId: "1:926532441932:web:e102da4f249b964ad961e9",
  measurementId: "G-M152VEVJJQ"
}

const firebaseConfig = {
  dev: firebaseConfigDev,
  prod: firebaseConfigProd
}

const getEnv = () => {
  const u = new URL(window.origin);
  if (u.hostname  === 'benxt.co') {
    return 'prod'
  }
  const searchParams =  new URLSearchParams(window.location.search)
  const env = searchParams.get('env')
  return env || 'dev'
}

const stripeKeys = {
  dev: 'pk_test_51KwCKDHBazk2dW4fwRudiOHsPuZ4oyyLMMWVBC7SxepgzFpCzIaHL7c4bsGT6eQmD7vP8GFXbWOF8oehJlWOagWS00GQ9XpCIk',
  prod: 'pk_test_51KwCKDHBazk2dW4fwRudiOHsPuZ4oyyLMMWVBC7SxepgzFpCzIaHL7c4bsGT6eQmD7vP8GFXbWOF8oehJlWOagWS00GQ9XpCIk'
}

export const stripePromise = loadStripe(stripeKeys[getEnv()])

const getFirebaseConfig = () => {
  const env = getEnv()
  console.log('env', env)
  return firebaseConfig[env]
}

const config = getFirebaseConfig()

const getBnApp = () => {
  const searchParams =  new URLSearchParams(window.location.search)
  const app = searchParams.get('app')
  return app || 'customer'
}
console.log('firebaseConfig', config)
firebase.initializeApp(config)

const me = new Me(firebase, config)

export class BnClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    me.observeSelf().subscribe(self => {
      this.setState({self})
    })
  }

  getPlatform = () => {
    let bxPlatName = 'bnClient'

    if (isMobile()) {
      bxPlatName += ' benxtAppMobile'
    }

    if (me.isNative()) {
      bxPlatName += ' benxtAppNative'
    }

    if (isIOS) {
      bxPlatName += ' benxtAppIOS'
    }

    if(isDesktop()) {
      bxPlatName += ' benxtAppDesktop'
    }

    if (isSafari) {
      bxPlatName += ' benxtAppSafari'
    }


    return(bxPlatName);
  }

  render () {
    let content = <BnSplash/>
    const self = this.state.self
    switch (getBnApp()) {
      case 'customer':
        content = <BnCustomer self={self} me={me}/>
          break
      case 'business':
        content = <BnBusiness self={self} me={me}/>
          break
      case 'doctor':
        content = <BnDoctor self={self} me={me}/>
          break
      case 'admin':
        content = <BnAdmin self={self} me={me}/>
          break
    }
    return <div className={this.getPlatform()}>
        {content}
    </div>
  }
}
