import { TaxonomyGraph } from './TaxonomyGraph.js'
import { CategorizedBaseTaxonomy } from './CategorizedBaseTaxonomy'

let G

const initGraph = () => {
  if (!G) {
    G = {}
    TaxonomyGraph.forEach(n => {
      G[n.code] = n
      n.implied = [n.code]
    })
    TaxonomyGraph.forEach(n => {
      for (const x of n.implies) {
        const { code } = x
        if (code) {
          const p = G[code]
          p.implied.push(n)
        }
      }
    })
  }
}

export const resolveCategory = specialty => {
  for (const { providers, category } of CategorizedBaseTaxonomy) {
    for (const provider of providers) {
      if (implies(specialty.code, provider.code)) {
        return category
      }
    }
  }
  debugger
  throw 'category not found for ' + specialty.label + ' ' + specialty.code
}

export const resolveCode = label => {
  initGraph()
  for (const code in G) {
    if (G[code].label === label) {
      return code
    }
  }
  return null
}

export const getImplications = (code) => {
  initGraph()
  const n = G[code]
  return n.implied
}

export const implies = (code1, code2) => {
  if (code1 === code2) return true
  initGraph()
  const n = G[code1]
  if (!n) return false
  for (const x of n.implies) {
    const { code } = x
    if (code === code2) {
      return true
    }
    if (code && implies(code, code2)) {
      return true
    }
  }
  return false
}


