import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import './index.css'
import { BnApp } from '../App'
import { BnPaymentMethodsPage, BnAddPaymentMethodPage, BnPaymentMethodButton } from '../Customer'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnLabel1, BnLabel2, BnErrorLabel, BnFieldLabel } from '../Label'
import { BnRemoveButton, BnButton, BnBlueButton, BnBackButton, BnLogoSmall, BnPageButton, BnPageButton2, BnInputWithButton } from '../Button'
import { BnInputField, BnInputFieldSeparator, BnSearchField, BnBoolInput } from '../TextInput'
import { delay, capitalize, joinWith } from '../../classes/Util.js'
import BxGoldRibbon from '../../assets/Marks/bxGoldRibbon.svg'
import BxSilverRibbon from '../../assets/Marks/bxSilverRibbon.svg'
import BxCross from '../../assets/Icons/bxCross.svg'
import BxSpinner from '../../assets/Spinners/SpinnerShape.svg'

const plans = [
  {
    name: 'Silver',
    ribbon: BxSilverRibbon,
    price: 49.99,
    label: 'Best for small to medium businesses',
  },
  {
    name: 'Gold',
    ribbon: BxGoldRibbon,
    price: 89.99,
    label: 'Best for medium to large businesses',
  }
]

const BnSubscriptionCard = props => {
  const { business } = props
  const { plan, activeMembers } = business
  const price = parseFloat(plan.price) * activeMembers
  return <div className='bnPlan'>
    <div className='bnPlanTitle'>
    <div className='bnPlanName'>{name}</div>
    <ReactSVG src={ribbon}/>
    </div>
    <Sep/>
    <div className='bnPlanPrice'><span className='bnPlanDollars'>${price.toFixed(2)}</span> /month </div>
    </div>
    
}

const BnPlan = props => {
  const { plan, action } = props
  const { id,  name, price, label } = plan
  let ribbon
  switch (id) {
    case 'gold':
      ribbon = BxGoldRibbon
      break
    case 'silver':
      ribbon = BxSilverRibbon
      break
  }
  return <div className='bnPlan'>
    <div className='bnPlanTitle'>
    <div className='bnPlanName'>{name}</div>
    <ReactSVG src={ribbon}/>
    </div>
    <Sep/>
    <div className='bnPlanPrice'><span className='bnPlanDollars'>${price}</span> /month </div>
    <Sep/>
    <BnLabel2 text={label}/>
    {action && <BnButton label={'Join ' + name} action={action}/>}
    </div>
}

class AccountSetupPage1 extends BnSubpage {

  constructor (props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.business) {
      const b = this.props.business
      const form = this.state.form
      this.accountFields.forEach(x => {
        form[x.name] = b[x.name]
      })
      this.addressFields.forEach(x => {
        form[x.name] = b[x.name]
      })
      this.forceUpdate()
    }
  }

  onAddPaymentMethod = paymentMethod => {
    if (paymentMethod) {
    }
    this.setState({
      paymentMethod
    })
  }

  accountFields = [
    {
      label: 'Company Name',
      name: 'name',
      autoComplete: 'organization'
    },
    {
      label: 'Email address',
      name: 'email',
      autoComplete: 'email'
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      autoComplete: 'tel',
      type: 'tel'
    },
    {
      name: 'password',
      label: 'Password',
      autoComplete: 'new-password',
      type: 'password'
    },
    ]

  addressFields = [
    {
      name: 'address',
      label: 'Street Address',
      autoComplete: 'street-address'
    },
    {
      name: 'city',
      label: 'City',
      autoComplete: 'address-level2'
    },
    {
      name: 'state',
      label: 'State',
      autoComplete: 'address-level1'
    },
    {
      name: 'country',
      label: 'Country',
      autoComplete: 'country'
    },
    {
      name: 'zip',
      label: 'Zip',
      autoComplete: 'postal-code'
    }]
  
  action = async () => {
    await this.props.continue(this.getForm())
  }

  handleSetupComplete = (result) => {
    this.back()
  }

  addPaymentMethods = async () => {

    const createSetupIntent = async () => {
      return await this.props.me.createBusinessSetupIntent(this.props.business)
    }

    const cancelSetupIntent = async (setupIntent) => {
      const id = setupIntent.id
      await this.props.me.cancelBusinessSetupIntent(this.props.business, setupIntent)
    }
    const selectPaymentMethod = async paymentMethod => {
      await this.props.me.selectBusinessPaymentMethod(this.props.business, paymentMethod)
    }
    
    this.setState({
      subpage: () => {
        const selectedPaymentMethod = (this.props.business.paymentMethods.find(x => x.id === this.props.business.selectedPaymentMethod) || {} )
        return <BnPaymentMethodsPage selectPaymentMethod={selectPaymentMethod} selectedPaymentMethod={selectedPaymentMethod} paymentMethods={this.props.business.paymentMethods} createSetupIntent={createSetupIntent} cancelSetupIntent={cancelSetupIntent}  me={this.props.me}  back={this.back}/>
      }
    })
  }
  
  addPaymentMethod = async () => {
    if (this.props.business.paymentMethods && this.props.business.paymentMethods.length > 0) {
      return await this.addPaymentMethods()
    }
    let clientSecret
    const { setupIntent } = await this.props.me.createBusinessSetupIntent(this.props.business)
    const back = () => {
      this.props.me.cancelBusinessSetupIntent(setupIntent).catch(err => {
        console.error(err)
      })
      this.back()
    }
    this.setState({
      subpage: () => <BnAddPaymentMethodPage usePaymentElement={true} clientSecret={setupIntent.clientSecret} me={this.props.me} handleSetupComplete={this.handleSetupComplete} back={back} onComplete={this.back}/>
    })
  }

  renderPaymentMethod = () => {
    if (!this.props.business || !this.props.business.paymentMethods) {
      return [<BnBlueButton label={'Add Payment Method'} action={this.addPaymentMethod}/>]
    }
    const paymentMethod = this.props.business.paymentMethods.find(x => x.id === this.props.business.selectedPaymentMethod) ||
          this.props.business.paymentMethods[0]
    return <div className='bnBusinessAccountPaymentMethod'>
      <BnFormFields>
      <BnPaymentMethodButton selected={true} paymentMethod={paymentMethod} action={this.addPaymentMethod}/>
      </BnFormFields>
      </div>
  }
  
  
  renderContent() {
    const form = this.state.form
    const formErr = this.state.formErr
    const onChange = this.onChange
    return <div className='bnPageContent'>
      <BnLabel1 text='Account'/>
      <BnFormFields>
      {joinWith(this.accountFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      </BnFormFields>
      <Sep/>
      <BnLabel1 text= 'Business address'/>
      <BnFormFields>
      {joinWith(this.addressFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      </BnFormFields>
      {(this.props.business) && [<Sep/>, <BnLabel1 text='Payment Method'/>, <Sep/>, this.renderPaymentMethod()]}
      <Sep/>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label={this.props.business ? 'Save' : 'Create Account'} action={this.action}/>
      </div>
  }
}

class AccountSetupPage2 extends BnSubpage {

  resendCode = async () => {
  }

  action = () => {
    this.setState({
      subpage: () => <AccountSetupPage3 me={this.props.me} back={this.back}/>
    })
  }
  
  renderContent() {
    const form = this.state.form
    const formErr = this.state.formErr
    const onChange = this.onChange
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Account verification'/>
      <BnFormFields>
      <BnInputField label={'Verification Code'} name='verificationCode' type='one-time-code' form={form} formErr={formErr} onChange={this.onChange}/>
      </BnFormFields>
      <BnErrorLabel text={formErr ? formErr.message : ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}

const CardDetailsFields = [  
    {
      label: 'Card Number',
      name: 'cardNumber',
    },
    {
      label: 'Name on Card',
      name: 'cardHolder',
    },
    {
      label: 'Expiration Date',
      name: 'expirationDate',
      type: 'date'
    },
    {
      label: 'CVV',
      name: 'cvv'
    },
    {
      label: 'Zip',
      name: 'zip'
    },
]
const CardAddressFields = [
    {
      name: 'address',
      label: 'Street Address',
      autoComplete: 'street-address'
    },
    {
      name: 'city',
      label: 'City',
      autoComplete: 'address-level2'
    },
    {
      name: 'state',
      label: 'State',
      autoComplete: 'address-level1'
    },
    {
      name: 'country',
      label: 'Country',
      autoComplete: 'country'
    },
    {
      name: 'zip',
      label: 'Zip',
      autoComplete: 'postal-code'
    }
  ]

class CardInput extends BnSubpage {
  constructor (props) {
    super(props)
  }

  action = async () => {
    return this.props.continue(this.getForm())
  }

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.onChange
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Card Details'/>
      <BnFormFields>
      {joinWith(CardDetailsFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      <Sep/>
      <Sep/>
      <BnLabel1 text='Card holder address'/>
      {joinWith(CardAddressFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      </BnFormFields>
      <BnErrorLabel text={formErr ? formErr.message: ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}

const DirectDepositAccountFields = [
    {
      name: 'bankName',
      label: 'Name of Bank',
    },
    {
      name: 'routingNumber',
      label: 'Routing Number',
    },
    {
      name: 'accountNumber',
      label: 'Account Number',
    }
]

class DirectDepositInput extends BnSubpage {

  constructor (props) {
    super(props)
  }
  
  action = async () => {
    return this.props.continue(this.getForm())
  }

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.onChange
    return <div className='bnPageContent'>
      <BnLabel1 text= 'CardDetails'/>
      <BnFormFields>
      <BnBoolInput label={'Use Business Address'} form={form} name='useBusinessAddress' onChange={onChange}/>
      <BnInputFieldSeparator/>
      {joinWith(CardDetailsFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      <Sep/>
      <Sep/>
      <BnLabel1 text='Card holder address'/>
      {joinWith(CardAddressFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }
      </BnFormFields>
      <BnErrorLabel text={formErr ? formErr.message: ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}

class AccountSetupPage3 extends BnSubpage {

  card = () => {
    this.setState({
      formErr: null,
      subpage: () => <CardInput onChange={this.onChange} form={this.state.form} formErr={this.state.formErr}  me={this.props.me} back={this.back} continue={this.onCompleteCardInput}/>
    })
  }

  deposit = () => {
    this.setState({
      formErr: null,
      subpage: () => <DirectDepositInput onChange={this.onChange} form={this.state.form} formErr={this.state.formErr} me={this.props.me} back={this.back} continue={this.onCompleteDirectDepositInput}/>
    })
  }

  onCompleteCardInput = (form) => {
    this.setState({
      input: 'card'
    })
    this.back()
  }

  onCompleteDirectDepositInput = (form) => {
    this.setState({
      input: 'directDeposit'
    })
    this.back()
  }

  action = () => {
    if (!this.state.input) {
      this.setState({
        formErr: { message: 'Please select a payment method' }
      })
    }
  }

  renderContent() {
    const formErr = this.state.formErr
    return <div className='bnPageContent'>
      <BnLabel1 text= 'Payment Method'/>
      <BnFormFields>
      <BnPageButton label={'Credit or Debit Card'} action={this.card}/>
      <BnInputFieldSeparator/>
      <BnPageButton label={'Direct Deposit'} action={this.deposit}/>
      </BnFormFields>
      <BnErrorLabel text={formErr ? formErr.message: ''}/>
      <BnBlueButton label='Continue' action={this.action}/>
      </div>
  }
}

class BnBusinessPlanSetup extends BnSubpage {

  constructor(props) {
    super(props)
    this.state.page = 1
  }

  joinPlan = plan => {
    this.setState({
      joinPlan: plan,
    })
    this.gotoPage(2)
  }

  createAccount = async (form) => {
    await this.props.createBusiness(mergeFields(form, { plan: this.state.joinPlan.id }))
  }

  gotoPaymentMethods = () => {
  }

  complete = async form => {
    debugger
  }


  gotoPage = n => {
    let subpage
    switch (n) {
      case 1:
        break
      case 2:
        subpage = () => <AccountSetupPage1 me={this.props.me} back={this.back} continue={this.createAccount}/>
        break
    }
    this.setState({
      subpage
    })
  }

  renderContent() {
    return this.renderPlanSelection()
  }
  
  renderPlanSelection() {
    const plans = this.props.plans
    return <div className='bnPageContent'>
      <BnLabel1 text={'Plans'}/>
      {plans.map(plan => {
        return <BnPlan plan={plan} action={() => this.joinPlan(plan)}/>
      })
      }
      </div>
  }
}


export class BnBusinessMain extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.members = []
    this.state.transactions = []
  }

  members = {}

  componentDidMount() {
    this.sub = this.props.me.observeMembers(this.props.business.id).subscribe(change => {
      const member = change.member
      if (change.type !== 'removed') {
        this.members[member.id] = member
      } else {
        delete this.members[member.id]
      }
      this.updateMembersLater()
    })
  }

  updateMembersLater = () => setTimeout(this.updateMembers, 200)
  updateMembers = () => {
    const members = Object.values(this.members)
    members.forEach(member => {
      member.isAdmin = this.props.business.admins.find(x => x === member.uid)
    })
    members.sort((x, y) => x.name.localeCompare(y.name))
    this.setState({
      members
    })
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }

  renderExtraFields = (props) => {
    const { form, formErr, onChange} = props
    if (form.active === undefined) form.active = true
    return [
        <BnInputFieldSeparator/>,
        <BnBoolInput label={'Enable Benefits'} name={'enableBenefits'} onChange={onChange} form={form} formErr={formErr}/>
    ]
    
  }

  openMembers = () => {
    const isAdmin = member => this.props.business.admins.find(x => x === member.uid)
    this.setState({
      subpage: () => <BnBusinessMembership renderExtraFields={this.renderExtraFields} isAdmin={isAdmin} onInviteMember={this.onInviteMember} onUpdateMember={this.onUpdateMember} onRemoveMember={this.onRemoveMember} me={this.props.me} back={this.back} members={this.state.members}/>
    })
  }

  onRemoveMember = async form => {
    this.state.members = this.state.members.filter(x => x.id !== form.id)
  }

  onUpdateMember = async form => {
    await this.props.me.updateMember(this.props.business.id, form)
  }

  onInviteMember = async form => {
    this.state.members.push({
      email: form.email,
      name: form.name
    })
    this.forceUpdate()
    const result = await this.props.me.inviteMember(this.props.business.id, form)
  }

  openTransactions = () => {
  }

  openAccount = () => {
    this.setState({
      subpage: () => <AccountSetupPage1 business={this.props.business} me={this.props.me} back={this.back}/>
    })
  }

  openSupport = () => {
  }

  renderContent() {
    const { members, transactions } = this.state
    return <div className='bnPageContent'>
      <BnLabel1 text={this.props.business.name}/>
      <Sep/>
      <BnFormFields>
      <BnPageButton label='Members' count={members.length} action={this.openMembers}/>
      <BnInputFieldSeparator/>
      <BnPageButton label='Transactions' count={transactions.length} action={this.openTransactions}/>
      </BnFormFields>
      <Sep/>
      <BnFormFields>
      <BnPageButton label='Account' action={this.openAccount}/>
      <BnInputFieldSeparator/>
      <BnPageButton label='Support' action={this.openSupport}/>
      </BnFormFields>
      </div>
    
  }
}

export const MemberButton = props => {
  const { member, action, remove } = props
  let tag
  if (!member.uid) {
    tag = <span>INVITE CODE <span className='bnOrangeLabelValue'>{member.code}</span></span>
  }
  if (member.isAdmin) {
    tag = 'ADMIN'
  }
  return <div className='bnMemberButton'>
    <BnPageButton2 title={member.name} subtitle={member.email} action={action} tag={tag}/>
    <BnRemoveButton action={remove}/>
    </div>
}

export class BnEditMember extends BnSubpage {

  action = async () => {
    const form = this.state.form
    if (!form.name) {
      this.setState({
        formErr: { field: 'name', message: 'First and Last Name is required'}
      })
      return
    }
    if (!form.email) {
      this.setState({
        formErr: { field: 'email', message: 'Email Address is required'}
      })
      return
    }
    return await this.props.continue(this.state.form)
  }

  componentDidMount() {
    if (this.props.member) {
      const { code, name, email, admin, status, enableBenefits, uid, id } = this.props.member
      this.state.form = { id, code, uid, enableBenefits, email, name, admin: this.props.isAdmin, active: status === 'active' }
      this.state.transactions = []
      this.forceUpdate()
      this.sub = this.props.me.observeTransactions(this.props.member).subscribe(change => {
      })
    }
  }

  openTransactions = async () => {
  }


  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
  }
  
  renderContent() {
    const title = this.props.title
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.onChange
    const buttonClass = this.props.buttonClass
    const buttonLabel = this.props.buttonLabel
    const transactions = this.state.transactions || []
    return <div className='bnPageContent'>
      <BnFormFields>
      <BnInputField name={'name'} label={'First and Last Name'} onChange={onChange} form={form} formErr={formErr}/>
      <BnInputFieldSeparator/>
      <BnInputField name={'email'} label={'Email Address'} onChange={onChange} form={form} formErr={formErr}/>
      {this.props.member && 
       [
           <BnInputFieldSeparator/>,
           <BnPageButton label={'Transactions'} count={transactions.length} action={this.openTransactions}/>
       ]
      }
      <BnInputFieldSeparator/>
      <BnBoolInput label={'Adminstrator'} name={'admin'} onChange={onChange} form={form} formErr={formErr}/>
      {this.props.renderExtraFields && this.props.renderExtraFields({ form, formErr, onChange })}
      {this.props.member && [<Sep/>, <BnInputFieldSeparator/>, <BnFieldLabel text='Invite Code'value={form.code}/>]}
      </BnFormFields>
      <BnErrorLabel text={formErr && formErr.message}/>
      <BnButton buttonClass={buttonClass} label={buttonLabel} action={this.action}/>
      </div>
  }
}

export class BnBusinessMembership extends BnSubpage {

  constructor (props) {
    super(props)
  }

  inviteNewMember = () => {
    this.setState({
      subpage: () => <BnEditMember renderExtraFields={this.props.renderExtraFields} title={'Invite New Member'} me={this.props.me} back={this.back} continue={this.onInviteMember} buttonClass={'bnButtonBlue'}/>
    })
  }

  onInviteMember = async form => {
    await this.props.onInviteMember(form)
    this.back()
  }

  removeMember = async member => {
    await this.props.onRemoveMember( member)
  }

  updateMember = async member => {
    await this.props.onUpdateMember(member)
    this.back()
  }

  openMember = (member) => {
    const isAdmin = this.props.isAdmin(member)
    this.setState({
      editMember: member,
      subpage: () => <BnEditMember renderExtraFields={this.props.renderExtraFields} isAdmin={isAdmin} member={member} title={'Member'} me={this.props.me} back={this.back} continue={this.updateMember} buttonLabel={'Update'} buttonClass='bnButtonBlue'/>
    })
  }

  search = searchTerm => {
    this.setState({
      searchTerm
    })
  }
  
  renderContent() {
    return <div className='bnPageContent'>
      <BnLabel1 text={'Members'}/>
      <Sep/>
      {this.props.members.length > 7 && [<BnSearchField search={this.search} searchTerm={this.state.searchTerm}/>,
                                         <Sep/>]}
      <BnButton label='Invite New Member' action={this.inviteNewMember}/>
      <Sep/>
      {this.props.members.length > 0 && <BnFormFields>
      {joinWith(this.props.members.map(member => {
        return <MemberButton member={member} action={() => this.openMember(member)} remove={() => this.removeMember(member)}/>
      }), () => <BnInputFieldSeparator/>)
      }
       </BnFormFields>}
      </div>
  }
}

export class BnBusinessContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillMount() {
  }


  render() {
    let content
    if (!this.props.business) {
      content = <BnBusinessPlanSetup createBusiness={this.props.createBusiness} plans={this.props.plans} me={this.props.me} back={this.props.back} />
    } else {
      content = <BnBusinessMain business={this.props.business} me={this.props.me} back={this.props.back}/>
    }
    return <div className='bnPageContent bnBusiness'>
      {
        content
      }
      </div>
  }
}

export class BnBusinessMyBusinesses extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.businesses = []
  }

  plans = {}

  businesses = {}

  componentDidMount() {
    this.planSub = this.props.me.observePlans().subscribe(change => {
      const plan = change.plan
      if (change.type !== 'removed') {
        this.plans[plan.id] = plan
      } else {
        delete this.plans[plan.id]
      }
      this.updatePlansLater()
    })
    this.sub = this.props.me.observeMyBusinesses().subscribe(change => {
      const business = change.business
      if (change.type !== 'removed') {
        this.businesses[business.id] = business
      } else {
        delete this.businesses[business.id]
      }
      this.updateBusinessesLater()
    })
  }

  componentWillUnmount() {
    if (this.planSub) this.planSub.unsubscribe()
    if (this.sub) this.sub.unsubscribe()
  }

  updatePlansLater = () => setTimeout(this.updatePlans, 200)

  updatePlans = () => {
    const plans = Object.values(this.plans)
    plans.sort((x, y) => {
      return parseFloat(x.price) - parseFloat(y.price)
    })
    this.setState({ plans })
  }

  updateBusinessesLater = () => setTimeout(this.updateBusinesses, 200)

  updateBusinesses = () => {
    this.setState({ businesses: Object.values(this.businesses) })
  }

  openBusiness = businessId => {
    this.setState({
      subpage: () => {
        const business = this.state.businesses.find(x => x.id == businessId)
        return <BnBusinessContent plans={this.state.plans} business={business} me={this.props.me} back={this.back}/>
      }
    })
  }

  addBusiness = () => {
    let sub
    let business
    const back = () => {
      if (sub) sub.unsubcribe()
      this.back()
    }
    const createBusiness = async (form) => {
      business = await this.props.me.createBusiness(form)
      sub = this.props.me.observeBusiness(business.id).subscribe(change => {
        business = change.business
        this.forceUpdate()
      })
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnBusinessPlanSetup createBusiness={createBusiness} business={business} plans={this.state.plans} me={this.props.me} back={back} />
    })
  }

  renderContent() {
    return <div className='bnPageContent'>
      <BnLabel1 text='My Businesses'/>
      <BnButton label='Add a Business' action={this.addBusiness}/>
      <Sep/>
      {this.state.businesses.map(business => {
        const action = () => this.openBusiness(business.id)
        return <BnPageButton label={business.name} action={action}/>
      })
      }
      </div>
  }
}



export class BnBusiness extends BnApp {

  renderSignUp() {
    return this.renderContent()
  }

  renderContent() {
    //return <BnBusinessContent me={this.props.me} back={this.back}/>
    return <BnBusinessMyBusinesses me={this.props.me} back={this.back}/>
  }
}
