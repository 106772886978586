import { Subject } from 'rxjs'
import { map, flatMap } from 'rxjs/operators'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { BnApp } from '../App'
import { BnPage, BnSubpage } from '../Page'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnForm, BnFormFields, BnFormButtons, BnFormFieldSeparator as Sep } from '../Form'
import { BnRemoveButton, BnButton, BnRedButton, BnBackButton, BnLogoSmall, BnPageButton, BnPageButton2, BnInputWithButton, BnCheckbox, BnCheckboxes, BnSelectionList, BnSelectionListChooser, BnTabview } from '../Button'
import { BnInputField, BnInputFieldSeparator, BnSearchField, BnBoolInput, BnNegBoolInput } from '../TextInput'
import { MainTabs, BnCustomerContent } from '../Customer'
import { BnBusinessMyBusinesses } from '../Business'
import { BnDoctorContent, BnAppointmentsPage } from '../Doctor'
import { clone, capitalize, joinWith, mergeFields } from '../../classes/Util.js'
import { CategorizedBaseTaxonomy } from '../../classes/CategorizedBaseTaxonomy.js'
import BxTelehealth from '../../assets/Services/bxConferance.svg' 
import './index.css'

export const ReasonTabs = [
  {
    selector: 'all',
    label: 'All Reasons'
  },
  {
    selector: 'symptom',
    label: 'Symptoms'
  },
  {
    selector: 'issue',
    label: 'Issues'
  }
]

const title = x => x.label || capitalize(x.id)

const categoryLabel = x => MainTabs.find(y => y.selector === x).label

class BnSpecialtiesPage extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.searchTerm = ''
    this.state.searchResults = []
    this.state.enabledSpecialties = {}
  }

  componentDidMount() {
    this.props.specialties.forEach(x => {
      this.state.enabledSpecialties[x.id] = !x.disabled
    })
    this.search('')
    this.sub = this.props.onChangeSpecialty.subscribe(() => {
      this.search(this.state.searchTerm)
    })
  }

  componentWillUnmount() {
    this.sub.unsubscribe()
  }

  renderSearchFilters = () => {
  }

  search = async searchTerm => {
    if (!searchTerm) {
      this.setState({
        searchTerm: '',
        searchResults: this.props.specialties
      })
      return
    }
    this.setState({
      searchTerm
    })
    const searchResults = await this.props.search(searchTerm)
    if (searchResults) {
      console.log('Search results', searchResults)
      this.setState({
        searchResults: searchResults.filter(x => x.specialties)
      })
    }
  }

  
  renderContent() {
    const searchResults = this.state.searchResults
    const end = searchResults.length
    const start = Math.min(1, end)
    return <div className='bnAdminAddSpecialty'>
	  <BnSearchField label='Search' searchTerm={this.state.searchTerm} search={this.search}/>
      <Sep/>
      {this.renderSearchFilters()}
      <Sep/>
      <BnButton label={'Add ' + this.props.addLabel} action={this.addReason}/>
      <Sep/>
      <div className='bnSearchResultSummary'>{start}-{end} of {this.props.specialties.length}</div>
      {joinWith(searchResults.map((x, i) => {
        const form = this.state.enabledSpecialties
        const onChange = (field, value) => {
          form[field] = value
          this.forceUpdate()
        }
        const remove = async () => {
          await this.props.remove(x)
        }
        const result = <div key={x.id} className='bnSpecialtyCard'>
          <BnBoolInput label={x.label} form={form} name={x.code} onChange={onChange}/>
          { false && <BnRemoveButton action={remove}/> }
              </div>;
        return result
      }), () => <BnInputFieldSeparator/>)
      }
      </div>
  }
}


const filterOptions = [
  {
    name: 'Telehealth',
    value: 'telehealth',
  },
  {
    name: 'Not Telehealth',
    value: 'not-telehealth'
  },
  {
    name: 'In-Person',
    value: 'in-person'
  },
  {
    name: 'Not In-Person',
    value: 'not-in-person'
  },
  {
    name: 'Default Price',
    value: 'default-price'
  },
  {
    name: 'Custom Price',
    value: 'not-default-price'
  },
  {
    name: 'Symptoms',
    value: 'symptom'
  },
  {
    name: 'Diagnoses',
    value: 'diagnosis'
  }
  ]

class BnAddReason extends BnSubpage {

  initReason = async () => {
    const form = this.getForm()
    const { reason } = form
    if (!reason) {
      this.setState({
        formErr: {
          field: 'reason',
          message: 'Visit reason is required'
        }
      })
      return
    }
    const response = await this.props.me.initVisitReason(reason)
    const { result, error } = response
    if (error) {
      switch (error) {
        case 'already-exists':
          this.setState({
            formErr: {
              field: 'reason',
              message: 'Visit reason already exists.'
            }
          })
          return
      }
    }
    const {
      categories,
      specialties,
      symptom,
      issue,
    } = result
    form.reason = result.reason
    form.categories = categories
    form.specialties = specialties
    form.issue = issue
    form.symptom = symptom
    this.editReason()
  }

  editReason = () => {
    const reason = this.getForm()
    const saveReason = async form => {
      await this.props.saveVisitReason(form)
    }
    const deleteReason = async () => {
      await this.props.deleteVisitReason(form)
    }
    this.setState({
      subpage: () => <BnReasonCard getSpecialties={this.getSpecialties} title='Visit Reason' delete={deleteReason} reason={reason} me={this.props.me} back={this.back} action={saveReason}/>
    })
  }

  getSpecialties = reason => {
    let specialties = []
    for (const cat of CategorizedBaseTaxonomy) {
      if (reason.categories.indexOf(cat.category) >= 0) {
        console.log('getSpecialties', reason.categories, cat.category)
        specialties = specialties.concat(cat.providers)
      }
    }
    specialties.sort((x, y) => x.label.localeCompare(y.label))
    return specialties
  }

  action = async () => {
    if (!this.state.reasonInit) {
      return await this.initReason()
    }
    return this.props.save(this.getForm())
  }
  
  renderContent() {
    const form = this.getForm()
    const formErr = this.state.formErr
    const onChange = this.onChange
    return <div className='bnSubpageContent'>
      <BnLabel1 text='Add Visit Reason'/>
      <BnLabel2 text='Please enter a visit reason'/>
      <Sep/>
      <BnFormFields>
      <BnInputField formErr={formErr} name='reason' label='Visit Reason' onChange={onChange} form={form}/>
      </BnFormFields>
      <Sep/>
      <div className='bnSignInErr'>{formErr && formErr.message}</div>
      <BnButton label={this.state.reasonInit ? 'Save' : 'Continue'} action={this.action}/>
      </div>
  }
}

export class BnReasonsPage extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.form.searchTerm = ''
    this.state.form.defaultPrice = 150
    this.state.form.defaultTelehealthPrice = 45
    this.state.form.reasons = {}
    this.state.searchForm = {}
    this.state.searchForm.specialties = {}
    this.state.searchResults = []
    this.state.queryFilters = {}
    this.state.page = 1
    this.state.pageSize = 15
    this.state.out_of = 0
    this.state.found = 0
    this.state.providerType = 'primaryCare'
    this.state.reasonType = 'all'
  }

  specialties = {}
  
  componentDidMount() {
    if (this.props.selectedSpecialties) {
      this.props.selectedSpecialties.forEach(x => {
        this.state.searchForm.specialties[x.code] = true
      })
    }
    //this.observePrices()
    this.search('')
  }

  componentWillUnmount() {
    this.unobservePrices()
  }
  
  onChange = (field, value) => {
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  observePrices() {
  }

  unobservePrices() {
  }

  openPage = x => {
  }

  search = async searchTerm => {
    this.setState({
      searchTerm
    })
    const reasonType = this.state.reasonType
    const result = await this.props.me.searchVisitReasons({
      q: searchTerm,
      category: this.state.providerType,
      reasonType
    })
    const {
      found,
      page,
      out_of,
      results 
    } = result
    const updates = {
      searchResults: results,
      page,
      out_of,
      found
    }
    this.setState(updates)
  }

  forms = {}

  getReasonForm = x => {
    if (!this.forms[x.id]) {
      this.forms[x.id] = mergeFields(x, { enabled: !x.disabled })
    }
    return this.forms[x.id]
  }

  chooseQueryFilters = () => {
    const options = filterOptions
    const form = this.state.queryFilters
    const action = async (option) => {
      this.back()
    }
    const onChange = (name, value) => {
      form[name] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser title={'Filters'} subtitle={'Apply search filters'} me={this.props.me} form={form} onChange={onChange}  back={this.back} options={options} action={action}/>
    })
  }

  chooseSpecialty = () => {
    const options = this.getOptions()
    const form = this.state.searchForm.specialties
    const action = async (option) => {
      this.back()
    }
    const onChange = (name, value) => {
      form[name] = value
      this.forceUpdate()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser title={'Specialties' } subtitle={'Filter results by specialty'} me={this.props.me} form={form} onChange={onChange}  back={this.back} options={options} action={action}/>
    })
  }

  renderQueryFilters = () => {
    return [<BnPageButton label='Filters' count={filterOptions.length} action={this.chooseQueryFilters}/>,
            queryFilters.length > 0 && <BnInputFieldSeparator/>,
            <BnCheckboxes children={queryFilters.map(opt => {
              return <BnCheckbox key={opt.value} form={this.state.queryFilters} name={opt.value} label={opt.name} onChange={this.onChangeQueryFilter}/>
            })}/>]
  }

  showProviderFilter = () => {
    switch (this.state.providerType) {
      case 'dental':
      case 'vision':
      case 'behavioralHealth':
      case 'specialtyCare':
        return true
    }
    return false
  }
  
  renderSearchFilters = () => {
    if (!this.showProviderFilter()) return null
    const form = this.state.searchForm.specialties
    const filters = []
    if (this.props.specialties) for (const spec of this.props.specialties) {
      if (form[spec.code]) {
        filters.push(spec)
      }
    }
    filters.sort((x, y) => x.label.localeCompare(y.label))
    const onChangeSearchFilter = (name, value) => {
      if (value) {
        form[name] = value
      } else {
        delete form[name]
      }
      this.forceUpdate();
    }
    const selection = filters
    return <div className='bnSearchFilters'>
      <BnSelectionList label={'Specialties'} options={this.getOptions()} count={this.getOptions().length} action={this.chooseSpecialty}/>
      <BnInputFieldSeparator/>
      <div className='bnSearchFiltersActive'>
      {
        joinWith(filters.map(x => {
          return <BnCheckbox key={x.id} name={x.id} label={x.label} form={this.state.searchForm.specialties} onChange={onChangeSearchFilter}/>
        }), () => <BnInputFieldSeparator/>)
      }
    </div>
      <Sep/>
    </div>
  }

  onChangeQueryFilter = (field, value) => {
    this.state.queryFilters[field] = value
    this.forceUpdate()
  }

  getOptions() {
    if (!this.props.specialties) {
      return []
    }
    return this.props.specialties.map(x => {
      return {
        value: x.code,
        name: x.label
      }
    })
  }

  selectProviderType = tab => {
    this.setState({providerType: tab.selector}, () => {
      this.search(this.state.searchTerm)
    })
  }

  selectReasonType = tab => {
    this.setState({reasonType: tab.selector}, () => {
      this.search(this.state.searchTerm)
    })
  }

  addReason = () => {
    const save = async form => {
      await this.props.me.saveVisitReason(form)
      this.search(this.state.searchTerm)
      this.back()
    }
    this.setState({
      subpage: () => <BnAddReason getSpecialties={this.getSpecialties} me={this.props.me} back={this.back} saveVisitReason={save}/>
    })
  }

  editReason = (reason) => {
    const saveReason = async form => {
      await this.props.me.saveVisitReason(form)
      for (const field in form) { // hack
        reason[field] = form[field]
      }
      this.back()
    }
    const deleteReason = async form => {
      await this.props.me.deleteVisitReason(form)
      this.state.searchResults = this.state.searchResults.filter(x => x.id !== form.id)
      this.back()
    }
    this.setState({
      subpage: () => {
        return <BnReasonCard title='Visit Reason' getSpecialties={this.getSpecialties} deleteVisitReason={deleteReason} reason={reason} me={this.props.me} back={this.back} action={saveReason}/>
      }
    })
  }

  getSpecialties = (reason) => {
    let specialties = []
    for (const cat of CategorizedBaseTaxonomy) {
      if (reason.categories.indexOf(cat.category) >= 0) {
        console.log('getSpecialties', reason.categories, cat.category)
        specialties = specialties.concat(cat.providers)
      }
    }
    specialties.sort((x, y) => x.label.localeCompare(y.label))
    return specialties
  }

  renderContent() {
    const options = this.getOptions()
    options.sort((x, y) => x.name.localeCompare(y.name))
    const title = this.props.title
    const data = this.state.data || []
    const form = this.state.form
    const formErr = this.state.formErr
    const queryFilters = []
    for (const opt of filterOptions) {
      if (this.state.queryFilters[opt.value]) {
        queryFilters.push(opt)
      }
    }
    const onChange = (field, value) => {
      console.log(field, value)
      this.onChange(field, value)
    }
    let start = 1
    let end = 10
    const seen = {}
    const queryFilter = x => {
      if (seen[x.id]) { // hack
        return false
      }
      seen[x.id] = true
      const form = this.getReasonForm(x)
      for (const id in this.state.queryFilters) {
        if (this.state.queryFilters[id]) {
          switch (id) {
            case 'symptom':
              break
            case 'diagnosis':
              break
            case 'procedure':
              break
          }
        }
      }
      return true
    }
    let searchResults = this.state.searchResults.filter(queryFilter)
    if (false) searchResults.sort((x, y) => {
      return x.reason.localeCompare(y.reason)
    })
    const pageSize = this.state.pageSize
    start = (this.state.page-1) * pageSize + Math.min(searchResults.length, 1)
    end = Math.max(start + searchResults.length - 1, start)
    const total = this.state.found
    console.log('searchResults', searchResults)
    return <div className='bnAdminAddSpecialty'>
      <div className='bnProviderCategories'>
      <BnTabview tabs={MainTabs} selection={this.state.providerType} select={this.selectProviderType}/>
      </div>
       <BnSearchField label='Search' searchTerm={this.state.searchTerm} search={this.search}/>
      <Sep/>
      {this.renderSearchFilters()}
      <Sep/>
      {this.props.isAdmin &&
        [<BnButton label='Add Visit Reason' action={this.addReason}/>,
         <Sep/>]}
      <div className='bnSearchResultSummary'>{start}-{end} of {total}</div>
      <BnTabview tabs={ReasonTabs} selection={this.state.reasonType} select={this.selectReasonType}/>
      {joinWith(searchResults.map((x, i) => {
        const editReason = async () => {
          this.editReason(x)
        }
        let tag = ''
        let sep = ''
        if (x.symptom) {
          tag += 'SYMPTOM'
          sep = ', '
        }
        if (x.issue) {
          tag += sep
          tag += 'ISSUE'
        }
        return <div className='bnReasonButton'>
          <BnPageButton2 title={x.reason} subtitle={''} action={editReason} tag={tag}/>
          {false && <BnRemoveButton action={remove}/>}
          </div>
      }), () => <BnInputFieldSeparator/>)}
      </div>
  }
}


class BnAdminMain extends Component {
  constructor (props) {
    super(props)
    this.state = {
      page: '',
      app: {
      },
      dataValues: {
      },
      data: {
        reasons: 0,
        specialties: 0,
      },
      bookings: {
        declined: 0,
        requested: 0,
        scheduled: 0,
        booked: 0,
        completed: 0,
        canceled: 0
      },
      practices: {
        allPractices: 0,
        active: 0,
        offline: 0
      },
      support: {
        open: 0,
        resolved: 0,
      }      
    }
  }

  reasons = {}
  specialties = {}

  updateSpecialtiesLater = () => {
    clearTimeout(this.specialtiesUpdate)
    this.specialtiesUpdate = setTimeout(this.updateSpecialties, 200)
  }

  updateSpecialties = () => {
    const specialties = Object.values(this.specialties)
    specialties.sort((x, y) => x.label.localeCompare(y.label))
    this.state.data.specialties = specialties.length
    this.state.dataValues.specialties = specialties
    this.forceUpdate(() => {
      this.onChangeSpecialty.next()
    })
  }

  updateData = (specialtiesChanged) => {
    console.log('update data')
    this.state.dataValues.providerTypes = Object.values(this.providerTypes)
    this.state.dataValues.providerTypes.sort((x, y) => x.name.localeCompare(y.name))
    this.forceUpdate()
  }

  updateDataLater = (specialtiesChanged) => {
    clearTimeout(this.updateDataTimeout)
    this.updateDataTimeout = setTimeout(() => this.updateData(specialtiesChanged), 200)
  }

  searchId = 0

  mainSearch = (searchTerm, type) => {
    if (!type) type = 'autoComplete'
    let searchId = ++this.searchId
    const k = this.searchCont
    if (k) {
      k()
    }
    return new Promise(resolve => {
      this.searchCont = resolve
      this.worker.postMessage({
        type,
        searchId,
        searchTerm
      })
    })
  }


  onSearchResults = e => {
    const {type, searchId, searchResults } = e.data
    if (type === 'autoComplete' || type === 'specialties') {
      if (searchId !== this.searchId) {
        this.state.searchCont()
        return
      }
      const k = this.searchCont
      this.searchCont = null
      k(searchResults)
    }
  }

  providerTypes = {}

  componentDidMount() {
    const reasonType = this.state.reasonType
    if (false) this.props.me.searchVisitReasons({q: '', reasonType, pageSize: 1}).then(result => {
      this.state.data.reasons = result.out_of
      this.state.dataValues.reasons = true
      this.forceUpdate()
    })
    this.sections.forEach(s => {
      s.pages.forEach(pages => {
        pages.forEach(page => {
          page.section = s
        })
      })
    })
    this.adminSub = this.props.me.observeIsAdmin().subscribe(isAdmin => {
      this.setState({
        isAdmin
      })
    })
    this.worker = new Worker('./Search.js')
    this.worker.addEventListener('message', this.onSearchResults)
    this.mainSearch('')
    if (false) this.reasonSub = this.props.me.observeVisitReasons().subscribe(change => {
      const { reason } = change
      if (change.type == 'removed') {
        delete this.reasons[reason.id]
      } else {
        this.reasons[reason.id] = reason
      }
      this.worker.postMessage({
        type: 'upload',
        upload: {
          reasons: [change]
        }
      })
      this.updateDataLater()
    })
    if (false) this.specialtiesSub = this.props.me.observeSpecialties().subscribe(change => {
      if (change.type === 'removed') {
        delete this.specialties[change.specialty.id]
      } else {
        this.specialties[change.specialty.id] = change.specialty
      }
      this.updateSpecialtiesLater()
    })
    this.providerTypesSub = this.props.me.observeProviderTypes().subscribe(change => {
      if (change.type === 'removed') {
        delete this.providerTypes[change.providerType.id]
      } else {
        this.providerTypes[change.providerType.id] = change.providerType
      }
      this.updateDataLater()
    })
    this.visitReasonsSummarySub = this.props.me.observeVisitReasonsSummary().subscribe(data => {
      this.state.data.reasons = data.all || 0
      this.state.dataValues.reasons = true
      this.forceUpdate()
    })
    this.practicesSummarySub = this.props.me.observePracticesSummary().subscribe(data => {
      this.state.practices.allPractices = data.all || 0
      this.forceUpdate()
    })
    this.providersSummarySub = this.props.me.observeProvidersSummary().subscribe(data => {
      this.state.practices.allProviders = data.all || 0
      this.forceUpdate()
    })
    this.bookingSummarySub = this.props.me.observeBookingSummary().subscribe(data => {
      for (const field in data) {
        this.state.bookings[field] = data[field]
      }
      this.forceUpdate()
    })
  }

  componentWillUnmount() {
    if (this.adminSub) this.adminSub.unsubscribe()
    if (this.reasonSub) this.reasonSub.unsubscribe()
    if (this.specialtiesSub) this.specialtiesSub.unsubscribe()
    if (this.providerTypesSub) this.providerTypesSub.unsubscribe()
    if (this.practicesSummarySub) this.practicesSummarySub.unsubscribe()
    this.worker.terminate()
  }

  sections = [
    {
      id: 'app',
      label: 'Apps',
      pages: [[
        {
          id: 'customer'
        },
        {
          id: 'business'
        },
        {
	  label: 'Provider',
          title: undefined,
          id: 'doctor'
        }]]
    },
    {
      admin: true,
      id: 'data',
      label: 'Data',
      pages:[[
        {
          id: 'reasons',
          label: "Visit Reasons",
        },
        /*{
          id: 'providerTypes',
          label: 'Provider Types'
          },*/
        /*
        {
          id: 'specialties',
        },*/
        ]]
    },
    {
      admin: true,
      id: 'bookings',
      label: 'Bookings',
      pages: [
        [
        {
          id: 'requested',
        },
        {
          id: 'scheduled',
        },
        {
          id: 'booked',
        },
        ],
        [
        {
          id: 'declined',
          label: 'Declined by Provider'
        },
        ],[
        {
          id: 'completed',
        },
        {
          id: 'canceled',
        }
        ]]
    },
    {
      admin: true,
      id: 'practices',
      label: 'Practices',
      pages: [[
        {
          label: 'All Practices',
          id: 'allPractices',
          title: 'All Practices'
        },
        {
          label: 'All Providers',
          id: 'allProviders',
          title: 'All Providers'
        },
        {
          id: 'active',
        },
        {
          id: 'offline',
        }
        ]]
    },
    {
      admin: true,
      id: 'support',
      pages: [[
        {
          id: 'open',
        },
        {
          id: 'resolved',
        },
      ]]
    }
    ]

  openPage = page => {
    this.setState({
      page: page
    })
  }

  back = () => {
    this.setState({
      page: null
    })
  }

  addSpecialty = async name => {
    await this.props.me.addSpecialty(name)
    this.onChangeSpecialty.next()
  }

  removeSpecialty = async specialty => {
    await this.props.me.removeSpecialty(specialty)
    this.onChangeSpecialty.next()
  }

  onChangeSpecialty = new Subject()

  addProviderType = async name => {
    await this.props.me.addProviderType(name)
    this.onChangeProviderType.next()
  }

  removeProviderType = async providerType => {
    await this.props.me.removeProviderType(providerType)
    this.onChangeProviderType.next()
  }

  onChangeProviderType = new Subject()
  
  observeAppointments = status => {
    return this.props.me.observeAppointments(status)
  }

  render() {
    let subpage
    const page = this.state.page
    let isAdmin = this.state.isAdmin
    if (page) {
      console.log('page', page)
      if (page.section && page.section.id === 'bookings') {
        const status = page.id
        subpage = <BnAppointmentsPage isAdmin={true} status={status} observeAppointments={() => this.observeAppointments(status)} me={this.props.me} back={this.back}/>
      } else {
        switch (page.id) {
          case 'customer':
            subpage = <BnCustomerContent me={this.props.me} back={this.back}/>
              break
          case 'business':
            subpage = <BnBusinessMyBusinesses me={this.props.me} back={this.back}/>
              break
          case 'doctor':
            isAdmin = false
          case 'allPractices':
            subpage = <BnDoctorContent isAdmin={isAdmin} type={'practices'} practicesTitle={page.title} me={this.props.me} back={this.back}/>
              break
          case 'allProviders':
            subpage = <BnDoctorContent isAdmin={isAdmin} type={'providers'} practicesTitle={page.title} me={this.props.me} back={this.back}/>
              break
          default:
            if (this.state.dataValues[page.id]) {
              const data = this.state.dataValues[page.id]
              const title = page.label || capitalize(page.id)
              switch (page.id) {
                case 'reasons': {
                  subpage = <BnReasonsPage isAdmin={true} back={this.back} me={this.props.me} title={title}/>
                    break
                }
                case 'specialties': {
                  subpage = <BnSpecialtiesPage addLabel='Specialty' onChangeSpecialty={this.onChangeSpecialty} remove={this.removeSpecialty} search={(searchTerm)=> this.mainSearch(searchTerm, 'specialties')}back={this.back} me={this.props.me} title={title} specialties={data}/>
                    break
                }
                case 'providerTypes': {
                  subpage = <BnSpecialtiesPage addLabel='Provider Type' onChangeSpecialty={this.onChangeProviderType} remove={this.removeProviderType} search={(searchTerm)=> this.mainSearch(searchTerm, 'providerTypes')}back={this.back} me={this.props.me} title={title} specialties={data}/>
                    break
                }
              }
            } else {
              subpage = <BnSubpage me={this.props.me} title={title(this.state.page)} back={this.back}/>
            }
            break
        }
      }
    }
    let sections = this.sections
    console.log('isAdmin', isAdmin)
    if (isAdmin !== true) {
      sections = sections.filter(x => !x.admin)
    }
    const selectPage = page => () => this.openPage(page)
    return <div className='bnPageContent'>
      <BnPage me={this.props.me} subpage={subpage}>
      <BnForm>
      <BnLogoSmall/>
      <Sep/>
      <BnBackButton action={this.props.me.signOut}/>
      <Sep/>
      {joinWith(sections.map(section => {
        return <div key={section.id} className='bnFormSection'>
          <BnLabel1 text={section.label || capitalize(section.id)}/>
          {joinWith(section.pages.map(pages => {
            return <BnFormFields>{joinWith(pages.map(page => {
              const label = page.label || capitalize(page.id)
              let count = this.state[section.id][page.id]
              if (section.id === 'data' && Array.isArray(this.state.dataValues[page.id])) {
                count = this.state.dataValues[page.id].length;
              }
              console.log(section.id, page.id, count)
              return <BnPageButton key={section.id + '-'+ page.id} label={label} action={selectPage(page)} count={count}/>
            }), () => <BnInputFieldSeparator/>)}</BnFormFields>
          }), () => <Sep/>)
          }
        </div>
      }), () => [<Sep/>, <Sep/>])
      }
    </BnForm>
      </BnPage>
      </div>
  }
}

class BnAdminContent extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return <BnAdminMain me={this.props.me}/>
  }
}

export class BnAdmin extends BnApp {
  renderContent() {
    return <BnAdminContent me={this.props.me} back={this.props.back}/>
  }
}

  
export class BnReasonCard extends BnSubpage {

  componentDidMount() {
    const form = this.getForm()
    if (this.props.reason) {
      for (const field in this.props.reason) {
        form[field] = this.props.reason[field]
      }
      form.enabled = !this.props.reason.disabled
    } else {
      form.categories = []
      form.specialties = []
      form.disabled = false
      form.reason = ''
    }
    this.forceUpdate()
  }

  delete = async () => {
    const response = await this.props.deleteVisitReason(this.props.reason)
  }

  reset = async () => {
    const form = this.getForm()
    const response = await this.props.me.initVisitReason(form.reason)
    const { result } = response
    for (const field in result) {
      form[field] = result[field]
    }
    this.forceUpdate()
  }

  editSpecialties = async () => {
    const form = this.getForm()
    const specialties = this.props.getSpecialties(form)    
    const options = specialties.map(x => {
      return {
        name: x.label,
        value: x.code
      }
    })
    const specialtyForm = {}
    form.specialties.forEach(x => specialtyForm[x.code] = true)
    const onChangeSpecialty = (field, value) => {
      if (value) {
        specialtyForm[field] = true
      } else {
        delete specialtyForm[field]
      }
    }
    const action = () => {
      this.onChange('specialties', specialties.filter(x => specialtyForm[x.code]))
      this.back()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser boolInput={true} back={this.back} options={options} title={'Specialties'} subtitle={'relevant to ' + form.reason} me={this.props.me} form={specialtyForm} onChange={onChangeSpecialty} action={action}/>
    })
  }

  editProviders = async () => {
    const cardForm = this.getForm()
    const options = MainTabs.map(tab => {
      return {
        name: tab.label,
        value: tab.selector
      }
    })
    const form = {}
    options.forEach(x => {
      cardForm.categories.forEach(y => {
        if (y === x.value) {
          form[y] = true
        }
      })
    })
    const onChange = (field, value) => {
      if (value) {
        form[field] = value
      } else {
        delete form[field]
      }
      this.forceUpdate()
    }
    const action = async () => {
      this.onChange('categories', Object.keys(form))
      this.back()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser boolInput={true} back={this.back} options={options} title={'Providers'} subtitle={'relevant to ' + cardForm.reason} me={this.props.me} form={form} onChange={onChange} action={action}/>
    })
  }
  renderContent() {
    const props = this.props
    const form = this.getForm()
    const onChange = this.onChange
    const { reason, symptom, issue } = form
    const categories = form.categories || []
    const specialties = form.specialties || []
    return <div className='bnReasonCard'>
      <div className='bnEditableFields'>
      <BnFormFields>
      <div className='bnFieldTypeLabel'>{'REASON'}</div>
      <div className='bnReasonCardLabel'>{reason}</div>
      <BnInputFieldSeparator/>
      <BnPageButton label='Providers' count={categories.length} action={this.editProviders}/>
      <BnInputFieldSeparator/>
      <BnPageButton label='Specialties' count={specialties.length} action={this.editSpecialties}/>
      <BnInputFieldSeparator/>
      <BnBoolInput label='Symptom' form={form} onChange={onChange} name='symptom'/>
      <BnInputFieldSeparator/>
      <BnBoolInput label='Issue' form={form} onChange={onChange} name='issue'/>
      <BnInputFieldSeparator/>
      <BnNegBoolInput label='Disabled' form={form} onChange={onChange} name='disabled'/>
      </BnFormFields>
      {form.id && <BnFormButtons>
       <BnButton label='Reset' action={this.reset}/>
       {this.props.deleteVisitReason && <BnRedButton label='Delete' action={this.delete}/>}
       </BnFormButtons>
      }
      </div>
      </div>
  }
}
