export const accordionData = [
    {
      title: 'How do I activate my membership?',
      content: `To activate your BeNXT membership, you can do so for free by sign-up HERE.
      If you don't already have an account, you will be required to create one before inputting 
      your payment information. It's that easy!
      
      It only takes two minutes to activate your membership and start saving! Simply 
      click HERE, create an account for your employees, and input your payment 
      information. If you already have an account, you'll immediately be directed toward 
      the payment information page.`
    },
    {
      title: 'Can I use BeNXT with my insurance?',
      content: `You cannot combine BeNXT Membership with insurance or 
      Medicare. However, prices for appointments, labs, prescriptions, specialists, and 
      services on BeNXT are incredibly affordable that they may beat your 
      co-pay — especially if you have a high deductible plan and take advantage of 
      the additional savings opportunities exclusive to BeNXT GOLD Membership. 

      Best of all, you can book an appointment in less than two minutes - without 
      paperwork, surprise bills, or hidden fees. Feel better knowing you'll always 
      pay the best price for your doctor, prescription, specialist, or service when 
      you book care as a BeNXT GOLD Member.
      `
    },
    {
      title: 'Is membership a replacement for insurance?',
      content: `No, membership is not a replacement for insurance.

      BeNXT GOLD membership gives you access to exclusive savings and 
      discounts on great doctors, specialists, and more. Activating your 
      membership today allows you to lock in special member-only discounts 
      like 25% off primary care, 5% off dental, and one FREE lab or blood test per year.
      `
    },
    {
      title: 'Does this cover primary care visits?',
      content: `Absolutely! Get 25% off virtual or in-person primary care when you become a 
      member. Primary care is a group of specialties that includes family medicine, internal 
      medicine, urgent care, and alternative/or holistic medicine.
      `
    },
    {
      title: 'What dental discounts do members get?',
      content: `BeNXT GOLD members automatically apply 10% off any dental 
      visit. With exclusive discounts on dental cleanings, emergency 
      dental visits, and teeth whitening, BeNXT GOLD members can save hundreds 
      of dollars when they book a dentist appointment on BeNXT. 
      `
    },
    {
      title: 'What discounts do members get on specialists?',
      content: `Members get at least 10% off 100+ specialists, including 
      dermatology, gynecology, therapy, psychiatry, radiology, and 
      more. Member discounts on specialists extend to both in-person and virtual appointments.
      `
    },
    {
      title: 'Do I really get a FREE lab or blood test?',
      content: `Yes! Each year, BeNXT GOLD annual members get one FREE lab or 
      blood test - a $65+ value. Choose from a list of labs, including HbA1c, 
      HIV lab test, comprehensive metabolic panel (CMP), lipid panel 
      (cholesterol), complete blood count (CBC), testosterone lab test, basic 
      health check lab, thyroid-stimulating hormone (TSH), and many more. If there are 
      none listed for your region, please contact our care finder team, who will 
      get you a free lab in your area.

      `
    },
    {
      title: 'Is BeNXT application HIPAA compliant?',
      content: `All data you share with BeNXT is 
      protected by a HIPAA-compliant cloud infrastructure. Your 
      data is always encrypted at rest and in transit.
      `
    },
    {
      title: 'Will the BeNXT app be secure?',
      content: `The BeNXT apps make use of the latest developments in 
      security architectures for both the Apple and Android platforms. The 
      BeNXT application is made available through the official Apple and 
      Google Play stores. These steps ensure that the application is safely 
      and securely installed and never tampered with.
      `
    },
    {
      title: '**What is the BeNXT concierge?',
      content: `BeNXT concierge is a service that makes it easier than ever to find 
      the right doctor, specialist, or appointment at the best price. Simply tell 
      our care team what you need, and they'll match you with one of the 10,000+ doctors 
      listed on BeNXT. If we aren't able to find you the right service or appointment in 
      your area, we'll leverage our extensive offline network to find a service or 
      provider near you - even if they aren't listed on BeNXT.

      BeNXT concierge is available exclusively to BeNXT GOLD members.
      `
    }
  ];