import { docData, doc, collectionChanges } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge } from 'rxjs/operators'
import axios from 'axios'
import * as geofirestore from 'geofirestore'
import GeoPoint from 'geo-point'
import { capitalize, mergeFields, formatAddress } from './Util.js'
import { resolvePrice } from './ResolvePrice.js'
import { resolveCode, resolveCategory } from './Taxonomy.js'
import Typesense from 'typesense'


const TYPESENSE_KEY='vK8XwCQvMRPiCC4YI0JDgcajmnjJJc90'

const typesense = new Typesense.Client({
  'nodes': [{
    'host': 'hzle1sm5ptr7vxifp-1.a1.typesense.net',
    'port': '443',
    'protocol': 'https'
  }],
  'apiKey': TYPESENSE_KEY,
  'connectionTimeoutSeconds': 5
})

const filterSpecialty = (specialty, providerType) => {
  if (!providerType) return true
  const nonSpecialty = {
    primaryCare: specialty => {
      switch (specialty.code) {
        case '207Q00000X':
        case '208D00000X':
          return true
      }
      return false
    },
    vision: specialty => specialty.code.startsWith('152') || specialty.code.startsWith('207W'),
    acupuncture: specialty => specialty.code.startsWith('1711'),
    dental: specialty => specialty.code.startsWith('1223'),
    behavioralHealth: specialty => specialty.code === '103T00000X' || specialty.code.startsWith('2084P'),
    chiropractic: specialty => specialty.code === '111N00000X',
  }
  let f = nonSpecialty[providerType]
  if (f) return f(specialty)
  for (const k in nonSpecialty) {
    f = nonSpecialty[k]
    if (f(specialty)) return false
  }
  return true
}

const doSearchNearbyProviders = async opts => {
  const { lat, lng } = opts
  let { radius, reason, providerType } = opts
  if (!reason.specialties) {
    const admin = getAdmin()
    const db = admin.firestore()
    const c = db.collection('VisitReasonToSpecialty')
    const { docs } = await c.where('reason', '==', reason).get()
    const [snap] = docs
    if (!snap) {
      throw new Error('not found: ' + reason)
    }
    reason = snap.data()
  }
  console.log('reason', reason)
  if (!radius) {
    radius = 25
  }
  radius *= 1.60934
  let specialtyFilter = reason.specialties.filter(x => filterSpecialty(x, providerType)).map(x => x.code).join(',')
  const geoFilter = `location:(${lat}, ${lng}, ${Math.round(radius)}km)`
  specialtyFilter = `specialtyCodes:=[${specialtyFilter}]`
  const params = {
    q: '*',
    filter_by: `${specialtyFilter} && ${geoFilter}`.trim(),
    per_page: 100
  }
  console.log('searchParams', params)
  const result = await typesense.collections('Providers').documents().search(params)
  console.log(result)
  const hits = result.hits
  return {
    page: hits.page,
    out_of: hits.out_of,
    results: result.hits.map(hit => {
      console.log(hit)
      return hit.document.provider
    })
  }
}

const doSearchProviders = async opts => {
  const { q, per_page } = opts
  const params = {
    q,
    per_page: per_page || 100,
    query_by: 'name'
  }
  console.log(params)
  const result = await typesense.collections('Providers').documents().search(params)
  console.log(result)
  const hits = result.hits
  return {
    page: hits.page,
    out_of: hits.out_of,
    results: result.hits.map(hit => {
      console.log(hit)
      return hit.document.provider
    })
  }
}

const doSearchPractices = async opts => {
  const { q, per_page } = opts
  const params = {
    q,
    per_page: per_page || 100,
    query_by: 'name'
  }
  console.log(params)
  let result
  try {
    result = await typesense.collections('Practices').documents().search(params)
  } catch (err) {
    console.error(err)
    return {
      page: 1,
      out_of: 0,
      results: []
    }
  }
  console.log(result)
  const hits = result.hits
  return {
    page: hits.page,
    out_of: hits.out_of,
    results: result.hits.map(hit => {
      console.log(hit)
      return hit.document.provider
    })
  }
}

const doSearchReasons = async opts => {
  const { q, category, pageSize, reasonType } = opts
  let filter_by = ''
  let sep = ''
  if (category) {
    filter_by = 'categories:=[' + category+']'
    sep = ' && '
  }
  if (reasonType) {
    filter_by += sep
    switch (reasonType) {
      case 'symptom':
        filter_by += 'symptom:=true'
        break
      case 'issue':
        filter_by += 'issue:=true'
        break
    }
  }
  const params = {
    q,
    query_by: 'reason',
    per_page: pageSize || 100,
    filter_by
  }
  console.log('searchParams', params)
  let result
  try {
    result = await typesense.collections('VisitReasons').documents().search(params)
  } catch (err) {
    console.error(err);
    throw err
  }
  console.log('VisitReasons', result)
  return {
    found: result.found,
    page: result.page,
    out_of: result.out_of,
    results: result.hits.map(hit => {
      console.log(hit.document)
      const { id, reason, specialties, symptom, issue, categories } = hit.document
      return {
        id, reason, specialties, symptom, issue, categories
      }
    })
  }
}

const unuppercase = name => name ? name.split(' ').map(x => x.toLowerCase()).map(x => {
  switch (x) {
    case 'of':
    case 'for':
    case 'the':
    case 'from':
    case 'and':
      break
    default:
      return capitalize(x)
  }
  return x
}).join(' ') : name

const debugLog = (...args) => {
  //console.log(args)
}

const ICD_10_SEARCH_URL = q => `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?maxList=30&sf=code,name&terms=${encodeURIComponent(q)}`
const HCPCS_SEARCH_URL = q => `https://clinicaltables.nlm.nih.gov/api/hcpcs/v3/search?maxList=30&terms=${encodeURIComponent(q)}`
const CONDITIONS_SEARCH_URL = q => `https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?&df=term_icd9_code,term_icd9_text,primary_name&terms=${encodeURIComponent(q)}`

const HCPCS_PRICE_URL = code => 'https://pfs.data.cms.gov/api/1/datastore/query?search==hcpcsCheck'+code
const HCPCS_PRICE_BODY = code => {
  return {
    "resources":[
    {
      "id":"a3642db0-2920-5b58-9de5-a8e68b650dd7",
      "alias":"t"
    }],
  "properties":[
    {"resource":
     "t",
     "property":
     "hcpc"
    },
    {"resource":
     "t",
     "property":"proc_stat"
    }],
  "conditions":[
    {"resource":"t",
     "property":"hcpc",
     "value":''+code,
     "operator":"="
    }],
  "sorts":[],
  "offset":0,
  "limit":500,
  "keys":true
  }
}

const doPriceSearch = async code => {
  const response = axios.post(HCPCS_PRICE_URL(code), HCPCS_PRICE_BODY(code))
  const { results } = response.data
  const [result] = results
  const { nfac_price } = result
  return nfac_price
}

const doDiagnosisSearch = async q => {
  const result = await axios.get(ICD_10_SEARCH_URL(q))
  const [resultCount, codes, hash, results] = result.data
  console.log("resultCount", q, resultCount)
  return {
    resultCount,
    results: results.map(result => {
      const [code, description] = result
      return {
        type: 'diag', code, description
      }
    })
  }
}

const doProcedureSearch = async q => {
  const url = HCPCS_SEARCH_URL(q)
  const result = await axios.get(url)
  const [resultCount, codes, hash, results] = result.data
  console.log("resultCount", q, resultCount)
  return {
    resultCount,
    results: results.map(result => {
      const [code, description] = result
      return {
        type: 'proc', code, description 
      }
    })
  }
}

const doConditionsSearch = async q => {
  const result = await axios.get(CONDITIONS_SEARCH_URL(q))
  const [resultCount, codes, hash, results] = result.data
  return results.map(result => {
    const [code, description, name] = result
    return {
      type: 'condition', code, name, description 
    }
  })
}

const dist = (lat1, lng1, lat2, lng2) => {
  const start = new GeoPoint(Number(lat1), Number(lng1))
  const end = new GeoPoint(Number(lat2), Number(lng2))
  return start.calculateDistance(end)
}

const isInsecure = ((u) => !(u.protocol === 'https:' || u.hostname.startsWith('localhost')))(new URL(window.origin))

let lastKnownPosition

const getCurrentPosition = async () => {
  if (typeof navigator !== 'undefined') {
    if ('geolocation' in navigator) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          result => resolve(result.coords),
          reject,
          {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 15000
          })
      }).catch(err => {
        if (lastKnownPosition) return lastKnownPosition
      }).then(coords => {
        lastKnownPosition = coords
        return coords
      })
    }
  }
  return { latitude: 0, longitude: 0 }
}

let GeoFirestore

class DoctorCache {
  constructor (me, position) {
    this.me = me
    this.db = me.firebase.firestore()
    this.position = position
    this.c = this.db.collection('Providers')
    const q = this.c.orderBy('lastModified', 'desc').limit(1)
    const ob = collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const snap = change.doc
        const data = snap.data()
        data.id = snap.id
        if (data.specialties) {
          data.specialty = doctor.specialties[0].name
        }
        return {
          type: change.type,
          doctor: data
        }
      }))
    }))
    this.sub = ob.subscribe(change => {
      const doctor = change.doctor
      console.log('DoctorCache', change)
      if (change.type !== 'removed') {
        this.cache[doctor.id] = doctor
        this.subject.next(change)
      }
    })
  }

  release = () => {
    this.sub.unsubscribe()
  }

  subject = new Subject()
  cache = {}

  observeDoctor = doctorId => {
    const cache = this.cache
    let ob
    if (!cache[doctorId]) {
      const get = async () => this.c.doc(doctorId).get()
      ob = from(get()).pipe(map(snap => {
        const doctor = snap.data()
        doctor.id = snap.id
        this.cache[doctorId] = doctor
        return {
          type: 'updated',
          doctor: convertDoctor(this.me, doctor, this.position)
        }
      }))
    } else {
      ob = of({
        type: 'added',
        doctor: cache[doctorId]
      })
    }
    return concat(ob, this.subject.pipe(filter(change => change.doctor.id === doctorId)))
  }
}

const convertDoctor = (me, doctor, position) => {
  const { place, coordinates } = doctor
  let { officeVisit } = doctor
  try { 
    doctor.distance = dist(position.latitude, position.longitude, coordinates.latitude, coordinates.longitude)
  } catch (err) {
    //debugger
  }
  if (place) {
    doctor.rating = place.rating || 0
  }
  if (officeVisit && !officeVisit.price) {
    if (doctor.services) {
      doctor.services.forEach(proc => {
        const { high, low } = proc
        proc.price = Math.round(low + Math.random() * (high - low))
      })
      const proc = doctor.services.find(x => x.code === 'D0150')
      officeVisit.price = proc.price
    }
  }
  if (false && !officeVisit) {
    const chiropractor = '111N00000X'
    const acupuncture = '171100000X'
    const c = doctor.specialties.find(x => x.code === chiropractor)
    const a = doctor.specialties.find(x => x.code === acupuncture)
    if (c) {
      const low = 125
      const high = 200
      officeVisit = {
        code: '99202',
        price: Math.round(low + Math.random() * (high - low))
      }
    }
    if (a) {
    }
  }
  if (!officeVisit) { // fix me
    const low = 125
    const high = 250
    officeVisit = {
      code: '99202',
      price: Math.round(low + Math.random() * (high - low))
    }
    doctor.officeVisit = officeVisit
  }
  if (!doctor.edu) doctor.edu = []
  doctor.edu.map(x => {
    switch (x.code) {
      case 'DMD':
      case 'DDS':
        x.institution = unuppercase(x.institution)
        break
    }
  })
  switch (doctor.gender) {
    case 'M':
      doctor.gender = 'Male'
      break
    case 'F':
      doctor.gender = 'Female'
      break
  }
  doctor.specialtyDescription = me.getSpecialtyDescription(doctor.specialties)
  return doctor
}

export class Me {
  isNative = () => {
    return typeof window !== 'undefined' && window.ReactNativeWebView
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      debugLog(msg)
    }
  }
  
  nativeInit () {
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
      }
    }
  }
  
  constructor (firebase, config) {
    this.firebase = firebase
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    window.onerror = (event, source, line, col, error) => {
      //this.nativeLog("error", source + " Line  " + line + ": "+ error)
      //alert(source + " Line  " + line + ": "+ error)
    }
    this.nativeInit()
    GeoFirestore = geofirestore.initializeApp(firebase.firestore())
  }

  getProviderTypesFilter = specialty => {
    if (true) {
      const category = resolveCategory(specialty)
      return type => type === category
    }
    const fs = {
      primaryCare: specialty => {
        switch (specialty.code) {
          case '207Q00000X':
          case '208D00000X':
            return true
        }
        return false
      },
      vision: specialty => specialty.code.startsWith('152') || specialty.code.startsWith('207W'),
      acupuncture: specialty => specialty.code.startsWith('1711'),
      dental: specialty => specialty.code.startsWith('1223'),
      behavioralHealth: specialty => specialty.code === '103T00000X' || specialty.code.startsWith('2084P'),
      chiropractic: specialty => specialty.code === '111N00000X',
    }
    const types = {}
    for (const type in fs) {
      const f = fs[type]
      if (f(specialty)) {
        types[type] = true
      }
    }
    return type => types[type]
  }

  providerTypeFilter = providerType => specialty => filterSpecialty(specialty, providerType)

  observeCustomer = () => {
    return doc(this.firebase.firestore().collection('Customers').doc(this.self.uid)).pipe(map(snap => {
      return snap.exists ? snap.data() : null
    }))
  }

  observePaymentMethods = () => {
    return this.observeCustomer().pipe(map(customer => {
      debugger
      if (customer) {
        let { paymentMethods, selectedPaymentMethod } = customer
        if (paymentMethods && selectedPaymentMethod) {
          selectedPaymentMethod = paymentMethods.find(x => x.id === selectedPaymentMethod)
        }
        return { paymentMethods, selectedPaymentMethod }
      }
      return {}
    }))
  }

  priceSearch = async code => {
    return doPriceSearch(code)
  }

  procedureSearch = async q => {
    return await doProcedureSearch(q)
  }

  diagnosisSearch = async q => {
    return await doDiagnosisSearch(q)
  }

  resolvePrice = async code => await resolvePrice(code)

  deleteVisitReason = async reason => {
    debugger
    await this.firebase.firestore().collection('VisitReasonToSpecialty').doc(reason.id).delete()
  }

  initVisitReason = async reason => {
    const func = this.firebase.functions().httpsCallable('initVisitReason')
    reason = unuppercase(reason)
    const response = await func({
      reason
    })
    return response.data
  }
  
  searchVisitReasons = async opts => {
    const { q, category, reasonType } = opts
    if (!q) {
      let found = 0
      let out_of = 0
      try {
        const result = await doSearchReasons({ q, category, reasonType, pageSize: 1 })
        found = result.found
        out_of = result.out_of
      }  catch  (err) {
        
      }
      let fq = this.firebase.firestore().collection('VisitReasonToSpecialty')
      if (category) {
        fq = fq.where('categories', 'array-contains', category)
      }
      if (reasonType === 'symptom') {
        fq = fq.where('symptom', '==', true)
      } else if (reasonType === 'issue') {
        fq = fq.where('issue', '==', true)
      }
      const { docs } = await fq.orderBy('reason', 'asc').limit(100).get()
      const returnValue = {
        found: found,
        page: 1,
        out_of: out_of,
        results: docs.map(snap => {
          const id = snap.id
          const data = snap.data()
          data.id = id
          return data
        })
      }
      //debugger
      return returnValue
    }
    if (true) return doSearchReasons(opts)
    const func = this.firebase.functions().httpsCallable('searchVisitReasons')
    const response = await func(opts)
    const { results, error } = response.data
    console.log(opts, results)
    if (error) {
      //debugger
      console.error(error)
      return {}
    }
    return results
  }

  findSimilarProviders = async opts => {
    const { reasonId, price, providerInfo, customerInfo } = opts
    const { providerType } = customerInfo
    const { distance, specialties } = providerInfo
    const { lat, lng } = customerInfo
    const c = this.firebase.firestore().collection('VisitReasonToSpecialty')
    const snap = await c.doc(reasonId).get()
    const reason = snap.data()
    const radius = customerInfo.distanceFilter === 'any' ? 25 : customerInfo.distanceFilter
    let specialtyFilter = specialties.filter(x => filterSpecialty(x, providerType)).map(x => x.code).join(',')
    const geoFilter = `location:(${lat}, ${lng}, ${Math.round(radius)}km)`
    specialtyFilter = `specialtyCodes:=[${specialtyFilter}]`
    const params = {
      q: '*',
      filter_by: `${specialtyFilter} && ${geoFilter}`.trim(),
      per_page: 100
    }
    console.log(params)
    const result = await typesense.collections('Providers').documents().search(params)
    console.log(result)
    const hits = result.hits
    const filterProvider = provider => {
      if (providerInfo.rating) {
        return provider.rating >= rating
      }
      if (!provider.officeVisit) {
        return false
      }
      return true
    }
    return {
      page: hits.page,
      out_of: hits.out_of,
      results: result.hits.map(hit => {
        console.log(hit)
        const provider = hit.document.provider
        provider.distance = dist(provider.place.lat, provider.place.lng, lat, lng)
        return provider
      }).filter(x => x.npi != providerInfo.npi && filterProvider(x))
    }
  }

  searchProviders = async opts => {
    let { q } = opts
    if (!q) {
      let out_of = 0
      try {
        const result = await doSearchProviders({ q, per_page: 1})
        out_of = result.out_of
      } catch (err) {
      }
      q = this.firebase.firestore().collection('Providers').orderBy('name', 'asc').limit(100)
      const { docs } = await q.get()
      return {
        page: 1,
        out_of,
        results: docs.map(snap => {
          const id = snap.id
          const data = snap.data()
          data.id = id
          return data
        })
      }
    }
    return await doSearchProviders(opts)
  }

  searchPractices = async opts => {
    let { q } = opts
    if (!q) {
      const result = await doSearchPractices({ q, per_page: 1})
      const { out_of } = result
      q = this.firebase.firestore().collection('ProviderLocations').orderBy('name', 'asc').limit(100)
      const { docs } = await q.get()
      return {
        page: 1,
        out_of,
        results: docs.map(snap => {
          const id = snap.id
          const data = snap.data()
          data.id = id
          return data
        })
      }
    }
    return await doSearchPractices(opts)
  }

  searchNearbyProviders = async opts => {
    const { lat, lng, radius, reason } = opts
    const position = {
      latitude: lat,
      longitude: lng
    }
    console.log(opts)
    let results
    if (true) {
      results = await doSearchNearbyProviders(opts)
    } else {
      const func = this.firebase.functions().httpsCallable('searchProviders')
      const response = await func({ lat, lng, radius, reasonId: reason.id })
      const { error } = response.data
      //debugger
      if (error) {
        console.error(error)
        return {page: 0, out_of: 0, results: []}
      }
      results = response.data.results
    }
    console.log('searchProviders', results)
    results.results = results.results.map(x => {
      x.coordinates = { // hack
        latitude: x.coordinates._latitude,
        longitude: x.coordinates._longitude
      }
      return convertDoctor(this, x, position)
    })
    return results
  }

  getTaxonomy = async code => {
    const snap = await this.firebase.firestore().collection('Taxonomy').doc(code).get()
    return snap.data()
  }

  reqs = []
  callId = 0
  nativeCall = (type, data) => {
    const id = ++this.callId
    return new Promise(resolve => {
      this.reqs[id] = resolve
      const call = {
        type,
      }
      call[type] = data
      this.sendNativeMessage({
        type: 'call',
        reqId: id,
        call
      })
    })
  }
  
  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall('location').then(response => {
        return response.coords
      }).catch(err => {
      })
    }
    return getCurrentPosition()
  }

  getLocation = async opts => {
    if (!opts) return this.getCurrentLocation()
    const { latitude, longitude, address } = opts
    console.log(opts)
    const func = this.firebase.functions().httpsCallable('getLocation')
    const result = await func({
      lat: latitude,
      lng: longitude,
      address
    })
    const { results } = result.data
    const { geometry, address_components } = results[0]
    const streetNumber = address_components.find(x => x.types.find(y => y == 'street_number')).short_name
    const street = address_components.find(x => x.types.find(y => y == 'route')).short_name
    let suite = ''
    const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
    if (subPremise) {
      suite = `, ${subPremise.short_name}`
    }
    let lat, lng
    if (geometry) {
      lat = geometry.location.lat
      lng = geometry.location.lng
    }
    return {
      latitude: lat,
      longitude: lng,
      address: `${streetNumber} ${street}${suite}`,
      address_components
    }
  }

  urlSubject = new Subject()
  credsSubject = new Subject()
  
  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message)
      return
    }
    if (msg.type === 'token') {
      this.saveToken(msg.token)
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea
      //this.nativeLog("window.safeAreaInsets=>"+window.safeAreaInsets);
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'qrCode') {
      if (this.qrCodeInputSubject) {
        this.qrCodeInputSubject.next({
          type: msg.op,
          code: msg.qrCode
        })
      }
      if (this.qrCodeResult) {
        this.qrCodeResult(msg.qrCode)
      }
    } else if (msg.type === 'response') {
      const resolve = this.reqs[msg.reqId]
      if (resolve) {
        delete this.reqs[msg.reqId]
        resolve(msg.response)
      }
    }
  }

  setStatusBarColor = color => {
    debugLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }
  
  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      return
    }
    if (!user) {
      if (this.doctorCache) {
        this.doctorCache.release()
        this.doctorCache = null
      }
    } else {
      this.getSpecialtyDescriptions()
    }
    this.self = user
    this.selfSubject.next(user)
  }

  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.firebase.functions().httpsCallable('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.firebase.functions().httpsCallable('phoneNumberExists')
    const result = await func({phoneNumber})
    return result.data
  }

  verifyInvite = async (email, verificationCode) => {
    const func = this.firebase.functions().httpsCallable('verifyInvite')
    const arg = { email, verificationCode: Number(verificationCode) }
    const result = await func(arg)
    console.log('verifyInvite', arg, result)
    return result.data
  }

  createAccount = async (email, password) => {
    const result = await this.firebase.auth().createUserWithEmailAndPassword(email, password)
    return result.user
  }

  addSpecialty = async name => {
    const c = this.firebase.firestore().collection('Specialties')
    const ref = c.doc()
    await ref.set({
      id: ref.id,
      name
    })
  }

  specialtyDescriptions = {}

  getSpecialtyDescriptions = async () => {
    const { docs } = await this.firebase.firestore().collection('SpecialtyDescriptions').get()
    for (const snap of docs) {
      this.specialtyDescriptions[snap.id] = snap.data()
    }
  }

  getSpecialtyDescription = specialties => {
    let key = specialties.map(x => x.code)
    key.sort()
    key = key.join('-')
    return this.specialtyDescriptions[key]
  }

  removeSpecialty = async specialty => {
    await this.firebase.firestore().collection('Specialties').doc(specialty.id).delete()
  }

  createCustomerAccount = async (verification, form) => {
    console.log('createCustomerAccount', verification, form)
    const { name, email, phoneNumber, address, country, state, zip, password } = form
    let user
    if (!this.self) {
      user = await this.createAccount(email, password)
    } else {
      user = this.self
    }
    const func = this.firebase.functions().httpsCallable('setupCustomerAccount')
    const { verificationCode } = verification
    const arg = {
      invite: { verificationCode: parseInt(verificationCode), email },
      form: {
        name, email, phoneNumber, address, country, state, zip
      }
    }
    console.log(arg)
    ////debugger
    const result = await func(arg)
    console.log(result)
    //debugger
    if (result.error) {
      throw new Error(result.error)
    }
  }    
  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    return await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.sendNativeMessage({
      type: 'signOut'
    })
    await this.firebase.auth().signOut()
  }

  observeDoctorPlan = () => {
    return from([])
  }

  observeMyBusinesses = () => {
    const q = this.firebase.firestore().collection('Businesses').where('admins', 'array-contains', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          business: data
        }
      }))
    }))
  }

  observeMyMemberships = () => {
    const q = this.firebase.firestore().collection('CustomerMembership').where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          membership: data
        }
      }))
    }))
  }

  observePlans = () => {
    const q = this.firebase.firestore().collection('Plans')
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          plan: data
        }
      }))
    }))
  }

  removeMember = async (businessId, form) => {
    const { id } = form
    const func = this.firebase.functions().httpsCallable('removeMember')
    const result = await func({
      businessId, id
    })
  }

  inviteMember = async (businessId, form) => {
    const { name, email, admin, enableBenefits } = form
    const func = this.firebase.functions().httpsCallable('inviteMember')
    const result = await func({
      businessId,
      member: {
        name,
        email,
        isAdmin: admin,
        enableBenefits
      }
    })
  }

  updateMember = async (businessId, form) => {
    const { id, name, email, admin, enableBenefits } = form
    console.log('updateMember', form)
    const func = this.firebase.functions().httpsCallable('updateMember')
    const result = await func({
      businessId,
      member: {
        id,
        name,
        email,
        isAdmin: admin,
        enableBenefits
      }
    })
  }

  observeMembers = businessId => {
    const q = this.firebase.firestore().collection('Membership').where('businessId', '==', businessId)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          member: data
        }
      }))
    }))
  }

  removePracticeMember = async (businessId, form) => {
    const { id } = form
    const func = this.firebase.functions().httpsCallable('removePracticeMember')
    const result = await func({
      businessId, id
    })
  }

  invitePracticeMember = async (practiceId, form) => {
    const { name, email, admin, npi } = form
    const func = this.firebase.functions().httpsCallable('invitePracticeMember')
    const result = await func({
      practiceId,
      member: {
        name,
        email,
        isAdmin: admin,
        npi
      }
    })
  }

  updatePracticeMember = async (practiceId, form) => {
    const { id, name, email, admin, npi } = form
    console.log('updateMember', form)
    const func = this.firebase.functions().httpsCallable('updatePracticeMember')
    const result = await func({
      practiceId,
      member: {
        id,
        name,
        email,
        isAdmin: admin,
        npi
      }
    })
  }

  observePracticeMembers = businessId => {
    const q = this.firebase.firestore().collection('PracticeMembership').where('practiceId', '==', businessId)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          member: data
        }
      }))
    }))
  }

  addSpecialty = async name => {
    const c = this.firebase.firestore().collection('ProviderTypes')
    const ref = c.doc()
    await ref.set({
      id: ref.id,
      name
    })
  }
  removeProviderType = async providerType => {
    await this.firebase.firestore().collection('ProviderTypes').doc(providerType.id).delete()
  }

  observeProviderTypes = () => {
    const q = this.firebase.firestore().collection('ProviderTypes')
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        const { id, name } = data
        return {
          type: change.type,
          providerType: {
            id,
            code: id,
            label: name,
            name,
          }
        }
      }))
    }))
  }

  observeBusinessPlan = () => {
    return from([])
  }

  observeTransactions = member => {
    return from([])
  }

  resolveNPI = async npi => {
    const func = this.firebase.functions().httpsCallable('resolveNPI')
    const result = await func({npi})
    console.log('resolveNPI', npi, result)
    const data = result.data
    if (data.error) {
      return data
    }
    return data.results[0]
  }

  observeServices = (doctor) => {
    const { npi } = doctor
    //debugger
    const q = this.firebase.firestore().collection('Services').where('npi', '==', String(npi))
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        data.id = id
        return {
          type: change.type,
          service: data
        }
      })
    }), catchError (err => {
      //debugger
      return {}
    }))
  }

  observeSpecialties = () => {
    const q = this.firebase.firestore().collection('SpecialtyToVisitReason')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        let data = change.doc.data()
        if (!data.specialty) {
          //debugger
        }
        const specialty = data.specialty
        specialty.visitReasons = data.visitReasons
        specialty.id = id
        return {
          type: change.type,
          specialty
        }
      })
    }), catchError (err => {
      //debugger
      return {}
    }))
  }

  observeCommonVisitReasons = () => {
    const q = this.firebase.firestore().collection('CommonVisitReasons')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        data.id = id
        return {
          type: change.type,
          reason: data
        }
      })
    }))
  }

  observeVisitReasons = () => {
    const q = this.firebase.firestore().collection('VisitReasonToSpecialty')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        data.id = id
        return {
          type: change.type,
          reason: data
        }
      })
    }))
  }

  hasNearbyDoctors = async position => {
    const geocollection = GeoFirestore.collection('Providers')
    const q = geocollection.near({ center: new this.firebase.firestore.GeoPoint(position.latitude, position.longitude), radius: 25 }).limit(1)
    const { docs } = await q.get()
    return docs.length > 0
  }

  observeNearbyDoctors = (position) => {
    this.doctorCache = new DoctorCache(this, position)
    console.log('observeNearbyDoctors', position)
    const geocollection = GeoFirestore.collection('Providers')
    const q = geocollection.near({ center: new this.firebase.firestore.GeoPoint(position.latitude, position.longitude), radius: 25 })
    const f = (q, cb) => {
      q.onSnapshot(cb)
    }
    return bindCallback(f)(q).pipe(flatMap(snap => {
      return from(snap.docChanges().map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          doctor: convertDoctor(this, data, position)
        }
      }))
    }))
  }

  saveDoctorAvailability = form => {
    const { doctor, daysOfWeek, timesOfDay } = form
    const c = this.firebase.firestore().collection('Providers')
    c.doc(doctor.id).set({
      lastModified: this.firebase.firestore.ServerTimestamp(),
      availability: { daysOfWeek, timesOfDay }
    }, { merge: true })
  }

  observeDoctors = () => {
    if (true) return this.observeNearbyDoctors()
  }

  observeDefaultPrice = () => {
    return of({
      defaultPrice: 180,
      defaultTelehealthPrice: 45
    })
  }

  observeDoctor = doctorId => {
    return this.doctorCache.observeDoctor(doctorId)
  }

  observeAllPractices = () => {
    const q = this.firebase.firestore().collection('ProviderLocations')
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        data.id = id
        return {
          type: change.type,
          practice: data
        }
      })
    }))
  }

  observeIsAdmin = () => {
    return this.observeSelf().pipe(flatMap(self => {
      if (self) {
        return doc(this.firebase.firestore().collection('Admins').doc(self.uid)).pipe(map(snap => snap.exists), catchError(err => {
          console.error('observeIsAdmin', err)
          return false
        }))
      }
      return of(false)
    }))
  }

  confirmPaymentIntent = async id => {
    const func = this.firebase.functions().httpsCallable('confirmPaymentIntent')
    const arg = {id}
    const result = await func(arg)
    return result.data
  }

  getPaymentMethod = async id => {
    const func = this.firebase.functions().httpsCallable('getPaymentMethod')
    const arg = {id}
    const result = await func(arg)
    return result.data
  }

  getPaymentMethods = async id => {
    const func = this.firebase.functions().httpsCallable('getPaymentMethods')
    const arg = {}
    const result = await func(arg)
    return result.data
  }
  
  selectCustomerPaymentMethod = async paymentMethod => {
    const func = this.firebase.functions().httpsCallable('selectCustomerPaymentMethod')
    const arg = { paymentMethodId: paymentMethod.id }
    const result = await func(arg)
    return result.data
  }

  selectBusinessPaymentMethod = async (business, paymentMethod) => {
    const func = this.firebase.functions().httpsCallable('selectBusinessPaymentMethod')
    const arg = {businessId: business.id, paymentMethodId: paymentMethod.id}
    const result = await func(arg)
    console.log('selectBusinessPaymentMethod', result)
    return result.data
  }

  updateBusiness = async (updates) => {
    const { id, plan, email, password, name, phoneNumber, address, city, state, country, zip } = updates
    const func = this.firebase.functions().httpsCallable('updateBusiness')
    const result = await func(updates)
    return result.data
  }

  cancelCustomerSetupIntent = async (setupIntent) => {
    const id = setupIntent.id
    const func = this.firebase.functions().httpsCallable('cancelSetupIntent')
    const arg = {id}
    const result = await func(arg)
    return result.data
  }
  
  createCustomerSetupIntent = async () => {
    const func = this.firebase.functions().httpsCallable('createSetupIntent')
    const arg = {}
    const result = await func(arg)
    return result.data
  }

  removeCustomerPaymentMethod = async paymentMethod => {
    const func = this.firebase.functions().httpsCallable('removeCustomerPaymentMethod')
    const arg = {id: paymentMethod.id}
    const result = await func(arg)
    return result.data
  }

  createBusiness = async (form) => {
    const func = this.firebase.functions().httpsCallable('createBusiness')
    const arg = form
    const result = await func(arg)
    return result.data
  }

  createBusinessSetupIntent = async (business) => {
    const func = this.firebase.functions().httpsCallable('createBusinessSetupIntent')
    const arg = {businessId: business.id}
    const result = await func(arg)
    return result.data
  }

  cancelBusinessSetupIntent = async (business, setupIntent) => {
    const func = this.firebase.functions().httpsCallable('cancelBusinessSetupIntent')
    const arg = {businessId: business.id, id: setupIntent.id}
    const result = await func(arg)
    return result.data
  }

  updateVisitReason = async (id, updates) => {
    const ref = this.firebase.firestore().collection('VisitReasonToSpecialty').doc(id)
    await ref.set(updates, { merge: true })
  }

  saveVisitReason = async reason => {
    const ref = this.firebase.firestore().collection('VisitReasonToSpecialty').doc(reason.id)
    await ref.set({
      reason: reason.reason,
      disabled: !reason.enabled,
      symptom: !!reason.symptom,
      issue: !!reason.issue,
      specialties: reason.specialties || [],
      categories: reason.categories || [],
    }, { merge: true })
  }

  saveDefaultPrice = async updates => {
    const { reason, price, telehealthPrice } = updates
    const ref = this.firebase.firestore().collection('Prices').doc(reason.id)
    await ref.set({ reasonId: reason.id, reason: reason.reason, price, telehealthPrice })
  }

  observeDefaultPrices = async updates => {
    const q = this.firebase.firestore().collection('Prices')
    return collectionData(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        const reason = {
          id: data.reasonId,
          reason: data.reason
        }
        data.reason = reason
        data.id = change.doc.id
        return {
          type: change.type,
          defaultPrices: data
        }
      }))
    }))
  }

  observeAdminDefaultPrices = () => {
    const ref = this.firebase.firestore().collection('DeafultPrices').doc('singleton')
    return docData(ref)
  }

  saveDoctorDefaultPrice = async updates => {
    const { doctorId, defaultPrice, defaultTelehealthPrice } = updates
    const ref = this.firebase.firestore().collection('Providers').doc(doctorId)
    await ref.set({
      lastModified: this.firebase.firestore.ServerTimestamp(),
      defaultPrice, defaultTelehealthPrice
    }, { merge: true })
  }

  saveDoctorPrice = async updates => {
    const { doctor, reason, price, telehealthPrice, enabled, telehealthEnabled } = updates
    const id = doctor.id + '-' + reason.id
    const ref = this.firebase.firestore().collection('DoctorPrices').doc(id)
    await ref.set({
      coordinates: doctor.coordinates,
      by: this.self.uid,
      doctorId: doctor.id,
      reasonId: reason.id,
      reason: reason.reason,
      price: price || 0,
      telehealthPrice: telehealthPrice || 0,
      enabled: !!enabled,
      telehealthEnabled: !!telehealthEnabled
    }, { merge: true })
  }

  observeMyPrices = doctorId => {
    const q = this.firebase.firestore().collection('DoctorPrices').where('doctorId', '==', doctorId)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        return {
          type: change.type,
          reason: {id: data.reasonId, reason: data.reason },
          doctorPrice: data
        }
      }))
    }), catchError(err => {
      //debugger
    }))
  }

  observeNearbyDoctorPrices = (reasonId, distance) => {
    if (!distance) distance = 80
    return from(this.getLocation()).pipe(flatMap(position => {
      const geocollection = GeoFirestore.collection('DoctorPrices')
      let q = geocollection.near({ center: new this.firebase.firestore.GeoPoint(position.latitude, position.longitude), radius: distance })
      q = q.where('reasonId', '==', reasonId)
      const f = (q, cb) => {
        q.onSnapshot(cb)
      }
      return bindCallback(f)(q).pipe(flatMap(snap => {
        return from(snap.docChanges().map(change => {
          const id = change.doc.id
          const data = change.doc.data()
          return {
            type: change.type,
            reason: reason,
            doctorPrice: data
          }
        }))
      }))
    }))
  }

  observeDoctorPrices = reasonId => {
    if (true) return this.observeNearbyDoctorPrices(reasonId)
    const q = this.firebase.firestore().collection('DoctorPrices').where('reasonId', '==', reasonId)
    return collectionChanges(q).pipe(flatMap(changes => {
      return changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        return {
          type: change.type,
          reason: reason,
          doctorPrice: data
        }
      })
    }))
  }

  observeVisitReasonsSummary = () => docData(this.firebase.firestore().collection('AdminSummaries').doc('visitReasons'))
  observePracticesSummary = () => docData(this.firebase.firestore().collection('AdminSummaries').doc('practices'))
  observeProvidersSummary = () => docData(this.firebase.firestore().collection('AdminSummaries').doc('providers'))
  observeBookingSummary = () => docData(this.firebase.firestore().collection('AdminSummaries').doc('booking'))

  observeMyPractices = (isAdmin) => {
    return this.observeIsAdmin().pipe(flatMap(isSysAdmin => {
      if (isAdmin && isSysAdmin) return this.observeAllPractices()
      return this.observeMyPracticesImpl()
    }))
  }

  observeMyPracticesImpl = () => {
    const q = this.firebase.firestore().collection('PracticeLocations').where('uid', '==', this.self.uid)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const id = change.doc.id
        const data = change.doc.data()
        data.id = id
        return {
          type: change.type,
          practice: data
        }
      }))
    }))
  }

  observeAppointments = status => {
    return this.observeAppointmentsImpl(q => q.where('status', '==', status))
  }

  observeAppointmentsImpl = (where) => {
    let q = this.firebase.firestore().collection('CustomerBooking')
    if (where) q = where(q)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        const { providerId, providerInfo } = data
        if (!providerInfo) {
          console.error("no provider info", data)
          return null
        }
        const { npi, name, address_components, lat, lng, isoPhone, specialties } = providerInfo
        data.doctor = {
          distance: 0,
          id: providerId,
          name,
          place: {
            address_components,
            lat, lng
          },
          isoPhone,
          npi,
          specialties
        }
        data.id = change.doc.id
        data.reason = {
          id: data.reasonId,
          reason: data.reason
        }
        return {
          type: change.type,
          appt: data
        }
      }).filter(x => x))
    }))
  }

  acceptAppointment = async updates => {
    const { doctor, appt, date, time } = updates
    //debugger
    const when = new Date(date)
    when.setHours(time.getHours())
    when.setMinutes(time.getMinutes())
    const arg = {
      providerId: doctor.id,
      appointmentId: appt.id,
      scheduled: when.getTime()
    }
    const func = this.firebase.functions().httpsCallable('acceptAppointment')
    const result = await func(arg)
    return result.data
  }

  declineAppointment = async appt => {
    const updates = { appointmentId: appt.id }
    const func = this.firebase.functions().httpsCallable('declineAppointment')
    const result = await func(updates)
    return result.data
  }

  updateAppointment = async form => {
    console.log(form)
    debugger
  }

  completeAppointment = async appt => {
    const updates = { appointmentId: appt.id }
    const func = this.firebase.functions().httpsCallable('completeAppointment')
    const result = await func(updates)
    return result.data
  }

  openDoctorLocation = async doctor => {
    const { name, place } = doctor
    const { address_components } = place
    const address = formatAddress(address_components)
    const url = 'https://maps.google.com/?q=' + encodeURIComponent(`${name}, ${address}`)
    window.open(url, '_blank')
  }

  confirmCustomerAppointment = async (appt) => {
    const updates = {
      id: appt.id
    }
    const func = this.firebase.functions().httpsCallable('confirmCustomerAppointment')
    const result = await func(updates)
    return result.data
  }

  observeCustomerBookingSummary = () => {
    return doc(this.firebase.firestore().collection('CustomerBookingSummaries').doc(this.self.uid).pipe(map(snap => snap.data())))
  }

  observeDoctorBookingSummary = doctorId => {
    return doc(this.firebase.firestore().collection('DoctorBookingSummaries').doc(doctorId)).pipe(map(snap => snap.data()))
  }

  observeMemberTransactions = (businessId, memberId) => {
    const q = this.firebase.firestore().collection('MemberTransactions').where('businessId', '==', businessId).where('memberId', '==', memberId)
    return collectionChanges(q).pipe(flatMap(changes => {
      return from(changes.map(change => {
        const data = change.doc.data()
        data.id = change.doc.id
        return {
          type: change.type,
          txn: data
        }
      }))
    }))
  }
  
  observeDoctorAppointments = (doctorId, status) => {
    const where = q => {
      q = q.where('doctorId', '==', doctorId)
      if (status) {
        q = q.where('status', '==', status)
      }
      return q
    }
    return this.observeAppointmentsImpl(where)
  }

  observeMyAppointments = () => {
    const where = q => q.where('uid', '==', this.self.uid)
    return this.observeAppointmentsImpl(where)
  }

  cancelAppointment = async id => {
    const func = this.firebase.functions().httpsCallable('cancelCustomerAppointment')
    const arg = { id }
    const result = await func(arg)
    return result.data
  }

  cancelDoctorAppointment = async id => {
    const func = this.firebase.functions().httpsCallable('cancelDoctorAppointment')
    const arg = { id }
    const result = await func(arg)
    return result.data
  }

  bookAppointment = async form => {
    const { location, providerType, sort, filters, reason, doctor, price, telehealth, paymentMethod, timesOfDay, daysOfWeek, when } = form
    const func = this.firebase.functions().httpsCallable('bookCustomerAppointment')
    const arg = {
      location,
      providerType,
      sort,
      filters,
      reasonId: reason.id,
      npi: String(doctor.npi),
      isTelehealth: !!telehealth,
      paymentMethodId: paymentMethod.id,
      when,
      timesOfDay,
      daysOfWeek,
      price
    }
    console.log('arg', arg)
    const result = await func(arg)
    console.log('result', result.data)
    return result.data
  }

}


