export const CategorizedBaseTaxonomy = [
  {
    "category": "behavioralHealth",
    "label": "Behavioral Health",
    "providers": [{"code":"103T00000X","label": "Psychologist"},
                  {"code":"2084P0800X","label": "Psychiatrist"},
                  {"code":"2084N0400X","label": "Neurologist"}]
  },
  {
    "category": "acupuncture",
    "label": "Acupuncture",
    "providers": [{"code":"171100000X","label": "Acupuncturist"}]
  },
  {
    "category": "chiropractic",
    "label": "Chiropractic",
    "providers": [{"code":"111N00000X","label": "Chiropractor"}]
  },
  {
    "category": "dental",
    "label": "Dental",
    "providers": [{"code": "122300000X",  "label": "Dentist"},
                  {"code": "1223S0112X", "label": "Oral Surgery (Dentist only)"},
                  {"code": "1223E0200X", "label": "Endodontics"},
                  {"code": "1223P0106X", "label": "Oral & Maxillofacial, Pathology"},
                  {"code": "1223D0008X", "label": "Oral & Maxillofacial, Radiology"},
                  {"code": "1223S0112X", "label": "Oral & Maxillofacial, Surgery"},
                  {"code": "1223X0400X", "label": "Orthodontics"},
                  {"code": "1223P0221X", "label": "Pediatric Dentistry"},
                  {"code": "1223P0300X", "label": "Periodontics"},
                  {"code": "1223P0700X", "label": "Prosthodontics"}]

  },
  {
    "category": "vision",
    "label": "Vision",
    "providers": [{"code":"152W00000X",  "label": "Optometrist"},
                  {"code":"207W00000X","label": "Ophthalmology specialist"},
                  {"code": "152WC0802X", "label": "Optometry, Corneal and Contact Management"},
                  {"code": "152WL0500X", "label": "Optometry, Low Vision Rehabilitation"},
                  {"code": "152WP0200X", "label": "Optometry, Pediatrics"},
                  {"code": "152WS0006X", "label": "Optometry, Sports Vision"},
                  {"code": "152WV0400X", "label": "Optometry, Vision Therapy"}]
  },
  {
    "category": "primaryCare",
    "label": "Primary Care",
    "providers": [{"code":"207Q00000X","label": "Family Medicine doctor"},
                  {"code":"208D00000X","label": "General Practice doctor"}]
  },
  {
    "category": "specialtyCare",
    "label": "Specialty Care",
    "providers": [
      {"code":"207RA0002X","label": "Internal Medicine doctor"},
      {"code":"207K00000X","label": "Allergy and Immunology specialist"},
      {"code":"207N00000X","label": "Dermatologist"},
      {"code":"207RG0100X","label": "Gastroenterologist"},
      {"code":"207RH0000X","label": "Hematologist"},
      {"code":"207RI0008X","label": "Hepatologist"},
      {"code":"207RA0401X","label": "Addiction Medicine specialist"},
      {"code":"207RB0002X","label": "Bariatric Medicine specialist"},
      {"code":"207RC0000X","label": "Cardiologist"},
      {"code":"207RE0101X","label": "Endocrinology, Diabetes, and Metabolism specialist"},
      {"code":"207RG0300X","label": "Geriatric Medicine specialist"},
      {"code":"207RN0300X","label": "Nephrology specialist"},
      {"code":"207RP1001X","label": "Pulmonologist"},
      {"code":"207RR0500X","label": "Rheumatology specialist"},
      {"code":"213E00000X","label": "Podiatrist"},
      {"code":"207RS0012X","label": "Sleep Medicine specialist"},
      {"code":"207RX0202X","label": "Oncology specialist"},
      {"code":"207V00000X","label": "OBGYN"},
      {"code":"207Y00000X","label": "ENT specialist"},
      {"code":"208200000X","label": "Plastic Surgery specialist"},
      {"code":"208100000X","label": "Physical Medicine & Rehabilitation specialist"},
      {"code":"207RS0010X","label": "Sports Medicine specialist"},
      {"code":"207X00000X","label": "Orthopedics"},
      {"code":"2084E0001X","label": "Epilepsy specialist"},
      {"code":"208800000X","label": "Urology specialist"}]
  },
  {
    "category": "lab",
    "label": "Lab",
    "providers": [
      { "code": "291U00000X", "bcbs": "8207455", "label": "Clinical Medical Laboratory"}]
  },
  {
    "category": "imaging",
    "label": "Imaging",
    "providers": [
      {"code": "261QM1200X", bcbs: "NC74744", "label": "Magnetic Resonance Imaging (MRI) Clinic/Center"},
      {"code": "261QR0200X", bcbc: "LC69442", "label": "Radiology Clinic/Center"}
    ]
  }
]
