export const TaxonomyGraph = [
 {
  "path": "Allopathic & Osteopathic Physicians/General Practice",
  "code": "1223G0001X",
  "label": "Dentist",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery",
  "code": "208600000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine",
  "code": "2086H0002X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Pediatric Surgery",
  "code": "2086S0120X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Plastic and Reconstructive Surgery",
  "code": "2086S0122X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Surgery of the Hand",
  "code": "2086S0105X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Surgical Critical Care",
  "code": "2086S0102X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Surgical Oncology",
  "code": "2086X0206X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Trauma Surgery",
  "code": "2086S0127X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Vascular Surgery",
  "code": "2086S0129X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Thoracic Surgery (Cardiothoracic Vascular Surgery)",
  "code": "208G00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Transplant Surgery",
  "code": "204F00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Colon & Rectal Surgery",
  "code": "208C00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Neurological Surgery",
  "code": "207T00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Oral & Maxillofacial Surgery",
  "code": "204E00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
  "code": "207X00000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Adult Reconstructive Orthopedic Surgery",
  "code": "207XS0114X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Foot and Ankle Surgery",
  "code": "207XX0004X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Hand Surgery",
  "code": "207XS0106X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Orthopedic Surgery of the Spine",
  "code": "207XS0117X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Orthopedic Trauma",
  "code": "207XX0801X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Pediatric Orthopedic Surgery",
  "code": "207XP3100X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery/Sports Medicine",
  "code": "207XX0005X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Orthopedic Surgery",
    "code": "207X00000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Plastic Surgery",
  "code": "208200000X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Plastic Surgery/Plastic Surgery Within the Head & Neck",
  "code": "2082S0099X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Plastic Surgery",
    "code": "208200000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Plastic Surgery/Surgery of the Hand",
  "code": "2082S0105X",
  "label": "Physician/General Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Plastic Surgery",
    "code": "208200000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Allergy and Immunology",
  "code": "207K00000X",
  "label": "Physician/Allergy/ Immunology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Allergy and Immunology/Allergy",
  "code": "207KA0200X",
  "label": "Physician/Allergy/ Immunology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Allergy and Immunology",
    "code": "207K00000X",
    "label": "Physician/Allergy/ Immunology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Allergy and Immunology/Clinical & Laboratory Immunology",
  "code": "207KI0005X",
  "label": "Physician/Allergy/ Immunology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Allergy and Immunology",
    "code": "207K00000X",
    "label": "Physician/Allergy/ Immunology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
  "code": "207Y00000X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Facial Plastic Surgery",
  "code": "207YS0123X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Otolaryngic Allergy",
  "code": "207YX0602X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Otolaryngology/Facial Plastic Surgery",
  "code": "207YX0905X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Otolaryngology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Otology & Neurotology",
  "code": "207YX0901X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Pediatric Otolaryngology",
  "code": "207YP0228X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Plastic Surgery within the Head & Neck",
  "code": "207YX0007X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Otolaryngology/Sleep Medicine",
  "code": "207YS0012X",
  "label": "Physician/Otolaryngology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Otolaryngology",
    "code": "207Y00000X",
    "label": "Physician/Otolaryngology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
  "code": "207L00000X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology/Addiction Medicine",
  "code": "207LA0401X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
    "code": "207L00000X",
    "label": "Physician/Anesthesiology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology/Critical Care Medicine",
  "code": "207LC0200X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
    "code": "207L00000X",
    "label": "Physician/Anesthesiology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology/Hospice and Palliative Medicine",
  "code": "207LH0002X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
    "code": "207L00000X",
    "label": "Physician/Anesthesiology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology/Pain Medicine",
  "code": "207LP2900X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
    "code": "207L00000X",
    "label": "Physician/Anesthesiology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Anesthesiology/Pediatric Anesthesiology",
  "code": "207LP3000X",
  "label": "Physician/Anesthesiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Anesthesiology",
    "code": "207L00000X",
    "label": "Physician/Anesthesiology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Cardiovascular Disease",
  "code": "207RC0000X",
  "label": "Physician/Cardiovascular Disease (Cardiology)",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Dermatology",
  "code": "207N00000X",
  "label": "Physician/Dermatology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Dermatology/Clinical & Laboratory Dermatological Immunology",
  "code": "207NI0002X",
  "label": "Physician/Dermatology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Dermatology",
    "code": "207N00000X",
    "label": "Physician/Dermatology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Dermatology/MOHS-Micrographic Surgery",
  "code": "207ND0101X",
  "label": "Physician/Dermatology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Dermatology",
    "code": "207N00000X",
    "label": "Physician/Dermatology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Dermatology/Dermapathology",
  "code": "207ND0900X",
  "label": "Physician/Dermatology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Dermatology",
    "code": "207N00000X",
    "label": "Physician/Dermatology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Dermatology/Pediatric Dermatology",
  "code": "207NP0225X",
  "label": "Physician/Dermatology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Dermatology",
    "code": "207N00000X",
    "label": "Physician/Dermatology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine",
  "code": "207Q00000X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Addiction Medicine",
  "code": "207QA0401X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Adolescent Medicine",
  "code": "207QA0000X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Adult Medicine",
  "code": "207QA0505X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Bariatric Medicine",
  "code": "207QB0002X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Geriatric Medicine",
  "code": "207QG0300X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Hospice and Palliative Medicine",
  "code": "207QH0002X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Sports Medicine",
  "code": "207QS0010X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Family Medicine/Sleep Medicine",
  "code": "207QS1201X",
  "label": "Physician/Family Practice",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Family Medicine",
    "code": "207Q00000X",
    "label": "Physician/Family Practice",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pain Medicine/Interventional Pain Medicine",
  "code": "208VP0014X",
  "label": "Physician/Interventional Pain Management",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pain Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Gastroenterology",
  "code": "207RG0100X",
  "label": "Physician/Gastroenterology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
  "code": "207RA0002X",
  "label": "Adult Congenital Heart Disease",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Addiction Medicine",
  "code": "207RA0401X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Adolescent Medicine",
  "code": "207RA0000X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Advanced Heart Failure and Transplant Cardiology",
  "code": "207RA0001X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Allergy & Immunology",
  "code": "207RA0201X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Bariatric Medicine",
  "code": "207RB0002X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Clinical & Laboratory Immunology",
  "code": "207RI0001X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Critical Care Medicine",
  "code": "207RC0200X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Endocrinology/Diabetes/& Metabolism",
  "code": "207RE0101X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Endocrinology/Diabetes"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Endocrinology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Geriatric Medicine",
  "code": "207RG0300X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Hematology",
  "code": "207RH0000X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Hematology & Oncology",
  "code": "207RH0003X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Hepatology",
  "code": "207RI0008X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Hospice and Palliative Medicine",
  "code": "207RH0002X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Infectious Disease",
  "code": "207RI0200X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Magnetic Resonance Imaging (MRI)",
  "code": "207RM1200X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Medical Oncology",
  "code": "207RX0202X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Nephrology",
  "code": "207RN0300X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Pulmonary Disease",
  "code": "207RP1001X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Rheumatology",
  "code": "207RR0500X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Sleep Medicine",
  "code": "207RS0012X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Sports Medicine",
  "code": "207RS0010X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Transplant Hepatology",
  "code": "207RT0003X",
  "label": "Physician/Internal Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine & OMM",
  "code": "204D00000X",
  "label": "Physician/Osteopathic Manipulative Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine/Sports Medicine",
  "code": "204C00000X",
  "label": "Physician/Osteopathic Manipulative Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Neuromusculoskeletal Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology/Neurology",
  "code": "2084N0400X",
  "label": "Physician/Neurology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology/Neurology with Special Qualifications in Child Neurology",
  "code": "2084N0402X",
  "label": "Physician/Neurology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Neuroradiology",
  "code": "2085N0700X",
  "label": "Physician/Neurology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology/Epilepsy",
  "code": "2084E0001X",
  "label": "Physician/Neurology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry and Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Neurocritical Care",
  "code": "2084A2900X",
  "label": "Physician/Neurology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
  "code": "207V00000X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Bariatric Medicine",
  "code": "207VB0002X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Critical Care Medicine",
  "code": "207VC0200X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Female Pelvic Medicine and Reconstructive Surgery",
  "code": "207VF0040X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Gynecologic Oncology",
  "code": "207VX0201X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Gynecology",
  "code": "207VG0400X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Hospice and Palliative Medicine",
  "code": "207VH0002X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Maternal & Fetal Medicine",
  "code": "207VM0101X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Obstetrics",
  "code": "207VX0000X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology/Reproductive Endocrinology",
  "code": "207VE0102X",
  "label": "Physician/Obstetrics & Gynecology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Obstetrics & Gynecology",
    "code": "207V00000X",
    "label": "Physician/Obstetrics & Gynecology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine/Neuropsychiatry",
  "code": "2084H0002X",
  "label": "Physician/Hospice and Palliative Care",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine",
    "code": "2086H0002X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians/Surgery",
      "code": "208600000X",
      "label": "Physician/General Surgery",
      "implies": [
       {
        "path": "Allopathic & Osteopathic Physicians"
       }
      ]
     },
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine/Emergency Medicine",
  "code": "207PH0002X",
  "label": "Physician/Hospice and Palliative Care",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery/Hospice and Palliative Medicine",
    "code": "2086H0002X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians/Surgery",
      "code": "208600000X",
      "label": "Physician/General Surgery",
      "implies": [
       {
        "path": "Allopathic & Osteopathic Physicians"
       }
      ]
     },
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Surgery",
    "code": "208600000X",
    "label": "Physician/General Surgery",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
  "code": "207W00000X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Glaucoma Specialist",
  "code": "207WX0009X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Retina Specialist",
  "code": "207WX0107X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Uveitis and Ocular Inflammatory Disease",
  "code": "207WX0108X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Neuro-ophthalmology",
  "code": "207WX0109X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Pediatric Ophthalmology and Strabismus Specialist",
  "code": "207WX0110X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology/Cornea and External Diseases Specialist",
  "code": "207WX0120X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology",
    "code": "207W00000X",
    "label": "Physician/Ophthalmology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmic Plastic and Reconstructive Surgery",
  "code": "207WX0200X",
  "label": "Physician/Ophthalmology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Dentist/Oral & Maxillofacial Surgery",
  "code": "1223S0112X",
  "label": "Oral Surgery (Dentist only)",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Dentist",
    "code": "122300000X",
    "label": "Dentist",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
     },
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Clinical Cardiatric Electrophysiology",
  "code": "207RC0001X",
  "label": "Clinical Cardiac Electrophysiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Anatomic Pathology",
  "code": "207ZP0101X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Anatomic Pathology & Clinical Pathology",
  "code": "207ZP0102X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Blood Banking & Transfusion Medicine",
  "code": "207ZB0001X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Chemical Pathology",
  "code": "207ZP0104X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Clinical Pathology",
  "code": "207ZC0006X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Clinical Pathology/Laboratory Medicine",
  "code": "207ZP0105X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology/Clinical Pathology",
    "code": "207ZC0006X",
    "label": "Physician/Pathology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians/Pathology"
     },
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Cytopathology",
  "code": "207ZC0500X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Dermapathology",
  "code": "207ZD0900X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Forensic Pathology",
  "code": "207ZF0201X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Hematology",
  "code": "207ZH0000X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Immunopathology",
  "code": "207ZI0100X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Medical Microbiology",
  "code": "207ZM0300X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Molecular Genetic Pathology",
  "code": "207ZP0007X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Neuropathology",
  "code": "207ZN0500X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Pediatric Pathology",
  "code": "207ZP0213X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pathology/Clinical Informatics",
  "code": "207ZC0008X",
  "label": "Physician/Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pathology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine/Sports Medicine",
  "code": "207PS0010X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
    "code": "207P00000X",
    "label": "Physician/Emergency Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Sports Medicine",
  "code": "2080S0010X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Brain Injury",
  "code": "2081P0301X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Sports Medicine",
  "code": "2081S0010X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Sports Medicine",
  "code": "2083S0010X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Sports Medicine",
  "code": "2084S0010X",
  "label": "Physician/Sports Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
  "code": "208100000X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Hospice and Palliative Medicine",
  "code": "2081H0002X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Neuromuscular Medicine",
  "code": "2081N0008X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Pain Medicine",
  "code": "2081P2900X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Pediatric Rehabilitation Medicine",
  "code": "2081P0010X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation/Spinal Cord Injury Medicine",
  "code": "2081P0004X",
  "label": "Physician/Physical Medicine and Rehabilitation",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Physical Medicine & Rehabilitation",
    "code": "208100000X",
    "label": "Physician/Physical Medicine and Rehabilitation",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry",
  "code": "2084P0800X",
  "label": "Physician/Psychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Respiratory/Developmental/Rehabilitative and Restorative Service/Brain Injury Medicine",
  "code": "2084P0301X",
  "label": "Physician/Psychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Respiratory/Developmental/Rehabilitative and Restorative Service"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Respiratory/Developmental"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Respiratory"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Diagnostic Radiology",
  "code": "2085R0202X",
  "label": "Physician/Diagnostic Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Radiological Physics",
  "code": "2085R0205X",
  "label": "Physician/Diagnostic Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Diagnostic Ultrasound",
  "code": "2085U0001X",
  "label": "Physician/Diagnostic Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Diagnostic Neuroimaging",
  "code": "2085D0003X",
  "label": "Physician/Diagnostic Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Pediatric Radiology",
  "code": "2085P0229X",
  "label": "Physician/Diagnostic Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Urology",
  "code": "208800000X",
  "label": "Physician/Urology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Urology/Pediatric Urology",
  "code": "2088P0231X",
  "label": "Physician/Urology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Urology",
    "code": "208800000X",
    "label": "Physician/Urology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor",
  "code": "111N00000X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Independent Medical Examiner",
  "code": "111NI0013X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Internist",
  "code": "111NI0900X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Neurology",
  "code": "111NN0400X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Nutrition",
  "code": "111NN1001X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Occupational Medicine",
  "code": "111NX0100X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Orthopedic",
  "code": "111NX0800X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Pediatric Chiropractor",
  "code": "111NP0017X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Radiology",
  "code": "111NR0200X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Rehabilitation",
  "code": "111NR0400X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Sports Physician",
  "code": "111NS0005X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Chiropractic Providers/Chiropractor/Thermography",
  "code": "111NT0100X",
  "label": "Chiropractic",
  "implies": [
   {
    "path": "Chiropractic Providers/Chiropractor",
    "code": "111N00000X",
    "label": "Chiropractic",
    "implies": [
     {
      "path": "Chiropractic Providers"
     }
    ]
   },
   {
    "path": "Chiropractic Providers"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine",
  "code": "207U00000X",
  "label": "Physician/Nuclear Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Neuroradiology/Nuclear Radiology",
  "code": "2085N0904X",
  "label": "Physician/Nuclear Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology/Neuroradiology",
    "code": "2085N0700X",
    "label": "Physician/Neurology",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians/Radiology"
     },
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine/In Vivo & In Vitro Nuclear Medicine",
  "code": "207UN0903X",
  "label": "Physician/Nuclear Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine",
    "code": "207U00000X",
    "label": "Physician/Nuclear Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine/Nuclear Cardiology",
  "code": "207UN0901X",
  "label": "Physician/Nuclear Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine",
    "code": "207U00000X",
    "label": "Physician/Nuclear Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine/Nuclear Imaging & Therapy",
  "code": "207UN0902X",
  "label": "Physician/Nuclear Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Nuclear Medicine",
    "code": "207U00000X",
    "label": "Physician/Nuclear Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics",
  "code": "208000000X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Adolescent Medicine",
  "code": "2080A0000X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Clinical & Laboratory Immunology",
  "code": "2080I0007X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Developmental–Behavioral Pediatrics",
  "code": "2080P0006X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Hospice and Palliative Medicine",
  "code": "2080H0002X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Medical Toxicology",
  "code": "2080T0002X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Neonatal-Perinatal Medicine",
  "code": "2080N0001X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Neurodevelopmental Disabilities",
  "code": "2080P0008X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Allergy & Immunology",
  "code": "2080P0201X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Cardiology",
  "code": "2080P0202X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Critical Care Medicine",
  "code": "2080P0203X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Emergency Medicine",
  "code": "2080P0204X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Endocrinology",
  "code": "2080P0205X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Gastroenterology",
  "code": "2080P0206X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Hematology-Oncology",
  "code": "2080P0207X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Infectious Diseases",
  "code": "2080P0208X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Nephrology",
  "code": "2080P0210X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Pulmonology",
  "code": "2080P0214X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Rheumatology",
  "code": "2080P0216X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Pediatric Transplant Hepatology",
  "code": "2080T0004X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Sleep Medicine",
  "code": "2080S0012X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Obesity Medicine",
  "code": "2080B0002X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Pediatrics/Child Abuse Pediatrics",
  "code": "2080C0008X",
  "label": "Physician/Pediatric Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Pediatrics",
    "code": "208000000X",
    "label": "Physician/Pediatric Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist",
  "code": "152W00000X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Corneal and Contact Management",
  "code": "152WC0802X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Low Vision Rehabilitation",
  "code": "152WL0500X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Occupational Vision",
  "code": "152WX0102X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Pediatrics",
  "code": "152WP0200X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Sports Vision",
  "code": "152WS0006X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Eye and Vision Service Providers/Optometrist/Vision Therapy",
  "code": "152WV0400X",
  "label": "Optometry",
  "implies": [
   {
    "path": "Eye and Vision Service Providers/Optometrist",
    "code": "152W00000X",
    "label": "Optometry",
    "implies": [
     {
      "path": "Eye and Vision Service Providers"
     }
    ]
   },
   {
    "path": "Eye and Vision Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
  "code": "213E00000X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Foot & Ankle Surgery",
  "code": "213ES0103X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Foot Surgery",
  "code": "213ES0131X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/General Practice",
  "code": "213EG0000X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Primary Podiatric Medicine",
  "code": "213EP1101X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Public Medicine",
  "code": "213EP0504X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Radiology",
  "code": "213ER0200X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist/Sports Medicine",
  "code": "213ES0000X",
  "label": "Podiatry",
  "implies": [
   {
    "path": "Podiatric Medicine & Surgery Service Providers/Podiatrist",
    "code": "213E00000X",
    "label": "Podiatry",
    "implies": [
     {
      "path": "Podiatric Medicine & Surgery Service Providers"
     }
    ]
   },
   {
    "path": "Podiatric Medicine & Surgery Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist",
  "code": "103T00000X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Addiction (Substance Abuse Disorder)",
  "code": "103TA0400X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Adult Development & Aging",
  "code": "103TA0700X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Clinical",
  "code": "103TC0700X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Clinical Child & Adolescent",
  "code": "103TC2200X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Cognitive & Behavioral",
  "code": "103TB0200X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Counseling",
  "code": "103TC1900X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Educational",
  "code": "103TE1000X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Exercise & Sports",
  "code": "103TE1100X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Family",
  "code": "103TF0000X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Forensic",
  "code": "103TF0200X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Group Psychotherapy",
  "code": "103TP2701X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Health",
  "code": "103TH0004X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Health Service",
  "code": "103TH0100X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Men & Masculinity",
  "code": "103TM1700X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Intellectual & Developmental Disabilities",
  "code": "103TM1800X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Prescribing (Medical)",
  "code": "103TP0016X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Psychoanalysis",
  "code": "103TP0814X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Psychotherapy",
  "code": "103TP2700X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Rehabilitation",
  "code": "103TR0400X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/School",
  "code": "103TS0200X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Psychologist/Women",
  "code": "103TW0100X",
  "label": "Psychologist, Clinical",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Psychologist",
    "code": "103T00000X",
    "label": "Psychologist, Clinical",
    "implies": [
     {
      "path": "Behavioral Health & Social Service Providers"
     }
    ]
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Addiction Medicine",
  "code": "2084A0401X",
  "label": "Physician/Addiction Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Behavioral Health & Social Service Providers/Social Worker/Clinical",
  "code": "1041C0700X",
  "label": "Licensed Clinical Social Worker",
  "implies": [
   {
    "path": "Behavioral Health & Social Service Providers/Social Worker"
   },
   {
    "path": "Behavioral Health & Social Service Providers"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Aerospace Medicine",
  "code": "2083A0100X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Medical Toxicology",
  "code": "2083T0002X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Occupational Medicine",
  "code": "2083X0100X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Preventive Medicine/Occupational Environmental Medicine",
  "code": "2083P0500X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Public Health & General Preventive Medicine",
  "code": "2083P0901X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Undersea and Hyperbaric Medicine",
  "code": "2083P0011X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Addiction Medicine",
  "code": "2083A0300X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Obesity Medicine",
  "code": "2083B0002X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Preventive Medicine/Clinical Informatics",
  "code": "2083C0008X",
  "label": "Physician/Preventive Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Preventive Medicine"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Addiction Psychiatry",
  "code": "2084P0802X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Bariatric Medicine",
  "code": "2084B0002X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Behavioral Neurology & Neuropsychiatry",
  "code": "2084B0040X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Child & Adolescent Psychiatry",
  "code": "2084P0804X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Clinical Neurophysiology",
  "code": "2084N0600X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Diagnostic Neuroimaging",
  "code": "2084D0003X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Forensic Psychiatry",
  "code": "2084F0202X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Geriatric Psychiatry",
  "code": "2084P0805X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Neurodevelopmental Disabilities",
  "code": "2084P0005X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Neuromuscular Medicine",
  "code": "2084N0008X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Pain Medicine",
  "code": "2084P2900X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Psychosomatic Medicine",
  "code": "2084P0015X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Sleep Medicine",
  "code": "2084S0012X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology/Vascular Neurology",
  "code": "2084V0102X",
  "label": "Physician/Neuropsychiatry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Psychiatry & Neurology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Radiation Oncology",
  "code": "2085R0001X",
  "label": "Physician/Radiation Oncology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
  "code": "207P00000X",
  "label": "Physician/Emergency Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine/Emergency Medical Services",
  "code": "207PE0004X",
  "label": "Physician/Emergency Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
    "code": "207P00000X",
    "label": "Physician/Emergency Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine/Medical Toxicology",
  "code": "207PT0002X",
  "label": "Physician/Emergency Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
    "code": "207P00000X",
    "label": "Physician/Emergency Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine/Pediatric Emergency Medicine",
  "code": "207PP0204X",
  "label": "Physician/Emergency Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
    "code": "207P00000X",
    "label": "Physician/Emergency Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Emergency Medicine/Undersea and Hyperbaric Medicine",
  "code": "207PE0005X",
  "label": "Physician/Emergency Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Emergency Medicine",
    "code": "207P00000X",
    "label": "Physician/Emergency Medicine",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Radiology/Vascular and Interventional Radiology",
  "code": "2085R0204X",
  "label": "Physician/Interventional Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Radiology"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ambulatory Health Care Facilities/Sleep Disorder Diagnostic",
  "code": "261QS1200X",
  "label": "Physician/Sleep Medicine",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ambulatory Health Care Facilities"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Internal Medicine/Interventional Cardiology",
  "code": "207RI0011X",
  "label": "Physician/Interventional Cardiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Internal Medicine",
    "code": "207RA0002X",
    "label": "Adult Congenital Heart Disease",
    "implies": [
     {
      "path": "Allopathic & Osteopathic Physicians"
     }
    ]
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Dentist",
  "code": "122300000X",
  "label": "Dentist",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Hospitalist",
  "code": "208M00000X",
  "label": "Physician/Hospitalist",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Clinical Genetics",
  "code": "207SG0201X",
  "label": "Medical Genetics and Genomics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Medical Genetics/Clinical Biochemical Genetics",
  "code": "207SG0202X",
  "label": "Medical Genetics and Genomics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Medical Genetics"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Medical Genetics/Clinical Molecular Genetics",
  "code": "207SG0203X",
  "label": "Medical Genetics and Genomics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Medical Genetics"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Medical Genetics/Ph.D. Medical Genetics",
  "code": "207SG0205X",
  "label": "Medical Genetics and Genomics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Medical Genetics"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Medical Genetics/Molecular Genetic Pathology",
  "code": "207SM0001X",
  "label": "Medical Genetics and Genomics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Medical Genetics"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Other Service Providers/Acupuncturist",
  "code": "171100000X",
  "label": "Acupuncturist",
  "implies": [
   {
    "path": "Other Service Providers"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Dental Public Health",
  "code": "1223D0001X",
  "label": "Dental Public Health",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Endodontics",
  "code": "1223E0200X",
  "label": "Endodontics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial/Pathology",
  "code": "1223P0106X",
  "label": "Oral & Maxillofacial, Pathology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial/Radiology",
  "code": "1223D0008X",
  "label": "Oral & Maxillofacial, Radiology",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial/Surgery",
  "code": "1223S0112X",
  "label": "Oral & Maxillofacial, Surgery",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Oral & Maxillofacial"
   },
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Orthodontics",
  "code": "1223X0400X",
  "label": "Orthodontics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Pediatric Dentistry",
  "code": "1223P0221X",
  "label": "Pediatric Dentistry",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Periodontics",
  "code": "1223P0300X",
  "label": "Periodontics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 },
 {
  "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers/Prosthodontics",
  "code": "1223P0700X",
  "label": "Prosthodontics",
  "implies": [
   {
    "path": "Allopathic & Osteopathic Physicians/Ophthalmology & Dental Providers"
   },
   {
    "path": "Allopathic & Osteopathic Physicians"
   }
  ]
 }
]


