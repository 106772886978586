import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'

import BxLogo from '../../assets/Marks/bxLogo.svg'
import BxSignIn from '../../assets/Icons/bxSignin.svg'

import BxHeaderPhones from '../../assets/Svgs/MHeaderPhones.svg'
import BxMemberPhones from '../../assets/Svgs/MMemberPhones.svg'
import Bx123Phones from '../../assets/Svgs/M123Phones.svg'
import BxUserPhones from '../../assets/Svgs/MUserPhones.svg'
import BxRetainPhones from '../../assets/Svgs/MRetainPhones.svg'

import BxHeaderBrowsers from '../../assets/Svgs/DTHeaderBrowers.svg'
import BxMemeberBrowsers from '../../assets/Svgs/DTMemeberBrowers.svg'
import Bx123Browsers from '../../assets/Svgs/DT123Browers.svg'
import BxUserBrowsers from '../../assets/Svgs/DTUserBrowers.svg'
import BxRetainBrowsers from '../../assets/Svgs/DTRetainBrowers.svg'

import { isMobile, isDesktop } from '../../Platform.js'
import Accordion from '../Accordion/index'
import { accordionData } from '../Accordion/content'

import './index.css'

const enableDesktop = ()=> {
  const searchParams =  new URLSearchParams(window.location.search)
  return searchParams.has('enableDesktop')
}

const noSignup = () => isDesktop() && !enableDesktop()

export class BnStart extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
 
  openEmail = () => window.open("mailto:employers@benxt.co?subject=More Infomation", "_blank")

  render() {
    return <div className='bnStart'>
      <div className='bnStartHeader'>
        <div className='bnStartLogo'><ReactSVG src={BxLogo}/></div>
        <div className='bnStartSignInButton' onClick={this.props.signIn}>
          {!noSignup() && <div className='bnStartSignInButtonIcon'><ReactSVG src={BxSignIn}/></div>}
          {!noSignup() && <div className='bnStartSignInButtonLabel'>SIGN IN</div>}
        </div>
      </div>

      <div className='bnStartBody'>
        <div className='bnStart123Phone'>{<ReactSVG src={noSignup() ? BxHeaderBrowsers : BxHeaderPhones}/>}</div>
        <div className='bnStartJoinButton' onClick={noSignup() ? this.openEmail : this.props.signUp}>
          {noSignup() ? "Contact BeNXT today" : "Join BeNXT today"}
        </div>
        <div className='bnStart123Phone'><ReactSVG src={noSignup() ? BxMemeberBrowsers : BxMemberPhones}/></div>
        <div className='bnStartJoinButton' onClick={noSignup() ? this.openEmail : this.props.signUp}>
          {noSignup() ? "Contact BeNXT today" : "Join BeNXT today"}
        </div>
        <div className='bnStart123Phone'><ReactSVG src={noSignup() ? Bx123Browsers : Bx123Phones}/></div>
        <div className='bnStart123Phone'><ReactSVG src={noSignup() ? BxUserBrowsers : BxUserPhones}/></div>
        <div className='bnStartJoinButton' onClick={noSignup() ? this.openEmail : this.props.signUp}>
          {noSignup() ? "Contact BeNXT today" : "Join BeNXT today"}
        </div>
        <div className='bnStart123Phone'><ReactSVG src={noSignup() ? BxRetainBrowsers : BxRetainPhones}/></div>

        <div className="bnFAQ">
          <span className="fBold">F</span><span className="fReg">requently </span> 
          <span className="fBold">A</span><span className="fReg">sked </span>
          <span className="fBold">Q</span><span className="fReg">uestions</span>
        </div>

        <div className="bnAccordion">
          {accordionData.map(({ title, content }) => (
            <Accordion key={accordionData.id} title={title} content={content} />
          ))}
        </div>
        
        <div className='bnFooter'>
          <div className='bnEnt'>BeNXT Enterprises <div className='bnRights'>(All rights reserved)</div></div>
          <div className='bnService'>
            <div className='bnLink'><a href="/static/privacy_policy.html" target="_blank">Privacy Policy</a></div>
            <div className='bnSep'>.</div>
            <div className='bnLink'><a href="/static/terms_of_service.html" target="_blank">Terms of Service</a></div>
          </div>
        </div>
      </div>
    </div>
  }
}
