import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import moment from 'moment'
import { BnAddressLabel, daysOfWeek, timesOfDay, getDaysOfWeek, getTimesOfDay } from '../Doctor'
import { BnApp } from '../App'
import { BnPage, BnSubpage } from '../Page'
import { BnCustomerAccountSetup, BnCustomerAccountSetupSpinner } from './AccountSetup'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnLabel, BnLabel1, BnLabel2, BnLabel3, BnLabel4, BnOrangeLabel, BnFieldLabel } from '../Label'
import { BnRemoveButton, BnButton, BnRadioButton, BnBackButton, BnLogoSmall, BnMenu, BnSearchResult, BnSearchResultBack, BnTabview, BnCheckbox, BnCheckboxes, BnCheckboxSeparator, BnRedButton, BnBlueButton, BnPageButton, BnButtonSeparator, BnSelectionList, BnSelectionListChooser } from '../Button'
import { BnInputField, BnInputFieldSeparator, BnSearchField, BnTextArea, BnDateInput } from '../TextInput'
import BxRight from '../../assets/Icons/bxRight.svg'
import BxLocation from '../../assets/Icons/bxLocation.svg'
import BxLanguage from '../../assets/Icons/bxLanguage.svg'
import BxStar from '../../assets/Icons/bxStar.svg'
import BxCal from '../../assets/Icons/bxCal.svg'
import BxClock from '../../assets/Icons/bxClock.svg'
import BxGender from '../../assets/Icons/bxGender.svg'
import BxDermatology from '../../assets/Services/bxDermatology.svg'
import BxImaging from '../../assets/Services/bxImaging.svg'
import BxPediatrics from '../../assets/Services/bxPediatrics.svg'
import BxDental from '../../assets/Services/bxDental.svg'
import BxWomen from '../../assets/Services/bxWomen.svg'
import BxMen from '../../assets/Services/bxMen.svg'
import BxHealth from '../../assets/Services/bxHealth.svg'
import BxTelehealth from '../../assets/Services/bxConferance.svg'
import BxInPerson from '../../assets/Icons/bxInPerson.svg'
import SpinLoader from '../../assets/Spinners/SpinLoader.svg'
import { stripePromise } from '../Client'
import { joinWith, mergeFields, formatDistance } from '../../classes/Util.js'
import { implies, implied } from '../../classes/Taxonomy.js'
import { isSafari } from '../../classes/Platform.js'
import {Elements} from '@stripe/react-stripe-js'
import {ElementsConsumer, CardElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js'
import { WeightedList } from '../../classes/WeightedList.js'
import Star0 from '../../assets/Icons/Stars/s0.png'
import Star1 from '../../assets/Icons/Stars/s1.png'
import Star2 from '../../assets/Icons/Stars/s2.png'
import Star3 from '../../assets/Icons/Stars/s3.png'
import Star4 from '../../assets/Icons/Stars/s4.png'
import Star5 from '../../assets/Icons/Stars/s5.png'
import Star6 from '../../assets/Icons/Stars/s6.png'
import Star7 from '../../assets/Icons/Stars/s7.png'
import Star8 from '../../assets/Icons/Stars/s8.png'
import Star9 from '../../assets/Icons/Stars/s9.png'
import Star10 from '../../assets/Icons/Stars/s10.png'
import './index.css'

const capitalize = x => x.length > 0 ? x[0].toUpperCase() + x.slice(1) : ''

class BnCustomerMenu extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  back = () => {
    this.setState({
      subpage: null
    })
  }

  render() {
    let apptCount = 0
    
    const openAppts = async () => {
      this.setState({
        subpage: () => this.props.renderAppointmentsPage(this.back)
      })
    }

    const openBilling = async () => {
      const removePaymentMethod = async paymentMethod => {
        return await this.props.me.removeCustomerPaymentMethod(paymentMethod)
      }
      
      const createSetupIntent = async () => {
        return await this.props.me.createCustomerSetupIntent()
      }
      
      const cancelSetupIntent = async (setupIntent) => {
        const id = setupIntent.id
        await this.props.me.cancelCustomerSetupIntent(setupIntent)
      }
      const selectPaymentMethod = this.props.me.selectCustomerPaymentMethod    
      this.setState({
        subpage: () => <BnPaymentMethodsPage removePaymentMethod={removePaymentMethod} selectPaymentMethod={selectPaymentMethod} selectedPaymentMethod={this.props.selectedPaymentMethod} paymentMethods={this.props.paymentMethods} me={this.props.me} back={this.back} cancelSetupIntent={cancelSetupIntent} createSetupIntent={createSetupIntent}/>

      })
    }

    const openSupport = async () => {
    }

    const signOut = async () => {
      await this.props.me.signOut()
    }

    let selectedCard
    const paymentMethod = this.props.selectedPaymentMethod
    console.log('selectedPaymentMethod', paymentMethod)
    if (paymentMethod && paymentMethod.method === 'card') {
      selectedCard = capitalize(paymentMethod.type) + '****' + paymentMethod.last4
    }

    const subpage = this.state.subpage ? this.state.subpage() : null
    
    return  <div className='bnCustomerMenuContainer'>
      <BnPage me={this.props.me} subpage={subpage}>
      <div className='bnCustomerMenu'>      
      <div className='bnCustomerContentHeader'>
      <BnMenu open={true} action={this.props.close}/>
      <div className='bnCustomerMenuSeparator'/>
      <BnLogoSmall/>
      </div>
      <Sep/>
      <Sep/>
      <BnLabel1 text='Account'/>
      <BnPageButton label='Appointments' count={apptCount} action={openAppts}/>
      <BnInputFieldSeparator/>
      <BnPageButton label='Billing' count={selectedCard} action={openBilling}/>
      <BnInputFieldSeparator/>
      <BnPageButton label='Support' count={''} action={openSupport}/>
      <BnInputFieldSeparator/>
      <Sep/>
      <Sep/>
      <BnButton label='Sign Out' action={signOut}/>
      </div>
      </BnPage>
      </div>
  }
}

export const getOfficeVisitLabel = opts => {
  const { doctor, reason } = opts
  if (!doctor) {
    debugger
  }
  let label = '20 minute Office Visit'
  if (!doctor.officeVisit) {
    return label
  }
  if (doctor.officeVisit.code.startsWith('D')) {
    if (reason.reason === 'Dental Cleaning') {
      label = reason.reason
    } else {
      // dentist
      label = 'Oral Examination'
    }
  } else if (doctor.officeVisit.code === '90791') {
    label = "Diagnostic Evaluation"
  } else {
    const dur = getOfficeVisitDuration(doctor.officeVisit.code)
    label = `${dur} minute Office Visit`
  }
  return label
}

export const getProviderSorting = (sort, providerType, reason, providerTypeFilter) => {
  const getPrice = doctor => {
    return doctor.officeVisit.price
  }
  const orderByRelevance = (x, y) => {
    let cmp = 0
    const dur1 = getOfficeVisitDuration(x.officeVisit.code)
    const dur2 = getOfficeVisitDuration(y.officeVisit.code)
    if (dur1 && dur2) {
      cmp = dur1 - dur2
      if (cmp) return cmp
    }
    return orderByRating(x, y)
  }
  const orderByRating = (x, y) => {
    let cmp = y.rating - x.rating
    if (cmp === 0) {
      const r1 = x.place.reviews || []
      const r2 = y.place.reviews || []
      r2.sort((x, y) => y.rating - x.rating)
      r1.sort((x, y) => y.rating - x.rating)
      cmp = r2.length - r1.length
      if (cmp) return cmp
      if (r1.length > 0) {
        cmp = r2[0].rating - r1[0].rating
          if (cmp) return cmp
        cmp = r2[0].text.length - r1[0].text.length
          if (cmp) return cmp
      }
      }
    return cmp
  }
  switch (sort) {
    case 'relevance':
      if (!reason) {
        return searchResults => searchResults.sort(orderByRelevance)
      }
      switch (providerType) {
        case 'acupuncture':
        case 'primaryCare':
        case 'chiropractic':
          return searchResults => searchResults.sort(orderByRelevance)
          
      }
      const specialtiesToSample = reason.specialties.filter(providerTypeFilter)
      if (specialtiesToSample.length === 1) {
        return searchResults => searchResults.sort(orderByRelevance)
      }
      return searchResults => {
        if (searchResults.length === 0) {
          return []
        }
        let total = 0
        let min = 999999
        specialties.forEach((x, i) => {
          total += (x.score || 100)
          min = Math.min(x.score, min)
        })
        const ws = specialties.map((x, i) => {
          const w = x.score || 75
          console.log(x.label, w)
          return w
        })
        const results = []
        const samples = sample_without_replacement(specialtiesToSample, ws, searchResults.length)
        console.log("SAMPLES", samples.length)
          for (const specialty of samples) {
            if (!specialty) {
              console.error('Null specialty', reason)
              continue
            }
            const { code, label, score } = specialty
            for (let i = 0; i < searchResults.length; i++) {
              const x = searchResults[i]
              const found = x.specialties.find(y => implies(y.code, code))
              if (found) {
                results.push(x)
                //console.log(results.length, label, score)
                searchResults.splice(i, 1)
                break 
              } else {
                console.log("not found:", specialty, 'in', x.specialties)
              }
            }
          }
        return results
      }
    case 'rating':
      sort = (x, y) => {
        const price1 = getPrice(x)
        const price2 = getPrice(y)
        let cmp = 0
        if (cmp === 0) {
          cmp = orderByRating(x, y)
        }
        if (cmp === 0) {
          cmp = price1 - price2
        }
        if (cmp === 0) {
          cmp = x.distance - y.distance
        }
        //console.log(this.state.sort, x, y, '=>', cmp)
        return cmp
      }
      break
    case 'distance':
      sort = (x, y) => {
        let cmp = 0
        const price1 = getPrice(x)
        const price2 = getPrice(y)
        if (cmp === 0) {
          cmp = x.distance - y.distance
        }
        if (cmp === 0) {
          cmp = price1 - price2
        }
        if (cmp === 0) {
          cmp = orderByRating(x, y)
        }
        return cmp
      }
      break
    case 'price':
      sort = (x, y) => {
        const price1 = getPrice(x)
        const price2 = getPrice(y)
        let cmp = price1 - price2
        if (cmp === 0) {
          cmp = orderByRating(x, y)
        }
        if (cmp === 0) {
          cmp = x.distance - y.distance
        }
        return cmp
      }
  }
  return searchResults => {
    searchResults.sort(sort)
    return searchResults
  }
}

const starFractions =
      [Star0,
       Star1,
       Star2,
       Star3,
       Star4,
       Star5,
       Star6,
       Star7,
       Star8,
       Star9,
       Star10]

const getStarFraction = fraction => {
  const i = Math.round(fraction * 10)
  console.log("getStarFraction", fraction, i)
  return starFractions[i]
}

const renderStar = (key, img) => <img key={key} className={'bnStarImg'} src={img}/>;
const starFun = fraction => renderStar(fraction, getStarFraction(fraction))
      
export const getStars = rating => {
  const n = Math.floor(rating)
  const result = repeat(n, (i) => renderStar(i, Star10))
  const remainder = rating - n
  if (remainder) {
    result.push(starFun(remainder))
  }
  rating = Math.ceil(rating)
  while (rating < 5) {
    result.push(emptyStar(rating))
    rating++
  }
  return result
}

const emptyStar = (key) => renderStar(key, Star0)


const DEFAULT_SPECIALTIES = {
  '103T00000X': 'Psychologist',
  '111N00000X': 'Chiropractor',
  '122300000X': 'Dentist',
  '152W00000X': 'Optometrist',
  '171100000X': 'Acupuncturist',
  '207Q00000X': 'Primary Care Physician'
}

export const MainTabs = [
  {
    selector: 'primaryCare',
    label: 'Primary Care'
  },
  {
    selector: 'specialtyCare',
    label: 'Specialist'
  },
  {
    selector: 'dental',
    label: 'Dental'
  },
  {
    selector: 'vision',
    label: 'Vision'
  },
  {
    selector: 'behavioralHealth',
    label: 'Behavioral Health'
  },
  {
    selector: 'chiropractic',
    label: 'Chiropractic'
  },
  {
    selector: 'acupuncture',
    label: 'Acupuncture'
  },
  {
    selector: 'lab',
    label: 'Lab'
  },
  {
    selector: 'imaging',
    label: 'Imaging'
  },
  ]

const getProviderTitle = providerType => MainTabs.find(x => x.selector === providerType).label

const GET_DEFAULT_SPECIALTIES = () => {
  const result = {
  }
  for (const code in DEFAULT_SPECIALTIES) {
    result[code] = {
      code,
      label: DEFAULT_SPECIALTIES[code]
    }
  }
  return result
}


function sample_without_replacement(population, weights, sampleSize) {
    let size = 1;
    while (size < weights.length) {
        size = size << 1;
    }
    // construct a sum heap for the weights
    const root = 1;
    const w = [...new Array(size), ...weights, 0];
    for (let index = size - 1; index >= 1; index--) {
        const leftChild = index << 1;
        const rightChild = leftChild + 1;
        w[index] = (w[leftChild] || 0) + (w[rightChild] || 0);
    }
    // retrieves an element with weight-index r 
    // from the part of the heap rooted at index
    const retrieve = (r, index) => {
        if (index >= size) {
            w[index] = 0;
            return population[index - size];
        }
        const leftChild = index << 1;
        const rightChild = leftChild + 1;
        try {
            if (r <= w[leftChild]) {
                return retrieve(r, leftChild);
            }
            else {
                return retrieve(r - w[leftChild], rightChild);
            }
        }
        finally {
            w[index] = w[leftChild] + w[rightChild];
        }
    };
    // and now retrieve sampleSize random elements without replacement
    const result = [];
    for (let k = 0; k < sampleSize; k++) {
        result.push(retrieve(Math.random() * w[root], root));
    }
    return result;
}

const CommonReasons = ['Acne', 'Joint Pain', 'Back Pain', 'High Cholesterol', 'Cold', 'Anxiety',
                       'High Blood Pressure', 'Headache/Migraine', 'Diabetes', 'Woman\'s Issues']


class BnProviderSorting extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }
  render() {
    const { specialties, providers } = this.props
    const tabs = specialties.map(x => {
      return {
        selector: x.code,
        label: x.label,
        render: () => null
      }
    })
    const searchResults = providers.filter(x => x.specialties.find(y => specialties.find(z => z.code === y.code)))
    const fn = this.props.getSorting(this.state.sort)
    searchResults = fn(searchResults)
    return <div className='bnProviderSorting'>
      <BnTabview tabs={tabs} selection={this.state.sort} select={tab=>this.setState({sort: tab.selector})}/>
     <div key={'searchResults'} className='bnCustomerSearchResults'>
     {searchResults.map(this.props.renderSearchResult)}
    </div>
      </div>
  }
}

const BnProviderSort = props => {
}



const getOfficeVisitDuration = code => {
  let dur = 0
  switch (code) {
    case '99202':
      dur = 20
      break
    case '99203':
      dur = 30
      break
    case '99204':
      dur = 45
      break
    case '99205':
      dur = 60
      break
  }
  return dur
}

const genderOptions = [
  {
    name: "Female",
    value: "F"
  },
  {
    name: "Male",
    value: "M"
  }
]

const an = x => {
  const y = x[0].toLowerCase()
  if ('aeiou'.indexOf(y) >= 0) {
    return 'an ' +x
  }
  return 'a ' + x
}

const repeat = (n, f) => {
  const result = []
  for (var i = 0; i < n; i++) {
    result.push(f(i))
  }
  return result
}

const getGenderIcon = gender => {
  return BxGender
}

export const getSpecialtyIcon = specialty => {
  if (!specialty) return BxHealth
  switch (specialty.label) {
    case 'Dentist':
      return BxDental
    case 'Dermatology':
      return BxDermatology
  }
  return BxHealth
}

const renderStatus = status => {
  let completed = 'Booked'
  let status1 = 'Scheduled'
  let className = 'bnCustomerAppointmentCardStatus'
  className += ' bnCustomerAppointmentCardStatus'+capitalize(status)
  return <div className={className}>
    <div className='bnCustomerAppointmentCardProgress'>
    <div className='bnCustomerAppointmentCardProgressLabel1'/>
    </div>
    <div className='bnCustomerAppointmentCardProgressLabels'>
    <div className='bnCustomerAppointmentCardProgressLabelA'>Processing</div>
    <div className='bnCustomerAppointmentCardProgressLabelB'>{status1}</div>
    <div className='bnCustomerAppointmentCardProgressLabelC'>{completed}</div>
    </div>
    </div>
}

const priceOptions = [
  {
    name: '125',
    label: '$125',
  },
  {
    name: '200',
    label: '$200',
  },
  {
    name: '275',
    label: '$275',
  },
  {
    name: '350',
    label: '$350'
  },
  {
    name: 'any',
    label: 'Any'
  }
  ]

const BnCustomerPriceFilter = props => {
  const { name, form, onChange } = props
  const checked = {}
  priceOptions.forEach(x => {
    checked[x.name] = form[name] === x.name
  })
  const onChecked = (field, value) => {
    const opt = priceOptions.find(x => x.name == field)
    onChange(name, opt.name)
  }
  return <div className='bnSubpageContent'>
    <BnLabel4 text='Price'/>
    <BnCheckboxes>
    {priceOptions.map(x => {
        return <BnCheckbox form={checked} name={x.name} label={x.label} onChange={onChecked}/>
    })
    }
    </BnCheckboxes>
  </div>
}

const durationOptions = [
  {
    name: '20',
    label: '20 min',
  },
  {
    name: '30',
    label: '30 min'
  },
  {
    name: '45',
    label: '45 min'
  },
  {
    name: '60',
    label: '60 min'
  },
  {
    name: 'any',
    label: 'Any'
  }
  ]

const BnCustomerDurationFilter = props => {
  const { name, form, onChange } = props
  const checked = {}
  durationOptions.forEach(x => {
    checked[x.name] = form[name] === x.name
  })
  const onChecked = (field, value) => {
    const opt = durationOptions.find(x => x.name == field)
    onChange(name, opt.name)
  }
  return <div className='bnSubpageContent'>
    <BnLabel4 text='Office Visit Duration'/>
    <BnCheckboxes>
    {durationOptions.map(x => {
        return <BnCheckbox form={checked} name={x.name} label={x.label} onChange={onChecked}/>
    })
    }
    </BnCheckboxes>
  </div>
}

const customerGenderOptions = [
  {
    name: 'F',
    label: 'Female'
  },
  {
    name: 'M',
    label: 'Male'
  },
  {
    name: 'any',
    label: 'Any'
  },
]

const BnFilterOptions = props => {
  const { name, form, onChange, options, text } = props
  const checked = {}
  options.forEach(x => {
    checked[x.name] = form[name] === x.name
  })
  const onChecked = (field, value) => {
    const opt = options.find(x => x.name == field)
    onChange(name, opt.name)
  }
  return <div className='bnSubpageContent'>
    {text ? <BnLabel4 text={text}/> : <Sep/>}
    <BnCheckboxes>
    {options.map(x => {
        return <BnCheckbox form={checked} name={x.name} label={x.label} onChange={onChecked}/>
    })
    }
    </BnCheckboxes>
  </div>
}

const BnCustomerGenderFilter = props => BnFilterOptions(mergeFields(props, { options: customerGenderOptions, text: 'Patient Gender' }))

const ageOptions = [
  {
    name: 'pediatric',
    label: 'Child',
  },
  {
    name: 'adult',
    label: 'Adult',
  },
  {
    label: 'Over 65',
    name: 'geriatric',
  },
  {
    label: 'Any',
    name: 'any'
  }
]

                                                        
const BnCustomerAgeFilter = props => BnFilterOptions(mergeFields(props, { options: ageOptions, text: 'Patient Age' }))


const reasonOptions = [
  {
    label: 'Issues',
    name: 'issue'
  },
  {
    label: 'Symptoms',
    name: 'symptom'
  },
  {
    label: 'All',
    name: 'any'
  }
]

const BnCustomerReasonFilter = props => BnFilterOptions(mergeFields(props, { options: reasonOptions }))

const ratingOptions = [2, 3, 4, 5, 'any'].map(i => {
  return {
    name: '' + i,
    //icon: i == 'any' ? null : repeat(i, () => <ReactSVG src={BxStar}/>),
    label: i == 'any' ? 'Any' : i
  }
})

const BnCustomerRatingFilter = props => {
  const { name, form, onChange } = props
  const checked = {}
  ratingOptions.forEach(x => {
    checked[x.name] = form[name] === x.name
  })
  const onChecked = (field, value) => {
    const opt = ratingOptions.find(x => x.name == field)
    onChange(name, opt.name)
  }
  return <div className='bnSubpageContent bnCustomerRatingFilter'>
    <BnLabel4 text='Rating'/>
    <BnCheckboxes>
    {ratingOptions.map(x => {
      return <BnCheckbox form={checked} name={x.name} icon={x.icon} label={x.icon ? '' : x.label} onChange={onChecked}/>
    })
    }
    </BnCheckboxes>
  </div>
}

const distanceOptions = [2, 5, 10, 25, 'any'].map(i => {
  return {
    name: '' + i,
    label: i == 'any' ? 'Any' : `${i} mi`
  }
})

const BnCustomerDistanceFilter = props => {
  const { name, form, onChange } = props
  const checked = {}
  distanceOptions.forEach(x => {
    checked[x.name] = form[name] === x.name
  })
  const onChecked = (field, value) => {
    const opt = distanceOptions.find(x => x.name == field)
    onChange(name, opt.name)
  }
  return <div className='bnSubpageContent bnCustomerRatingFilter'>
    <BnLabel4 text='Distance'/>
    <BnCheckboxes>
    {distanceOptions.map(x => {
      return <BnCheckbox form={checked} name={x.name} icon={x.icon} label={x.icon ? '' : x.label} onChange={onChecked}/>
    })
    }
    </BnCheckboxes>
  </div>
}

const BnCustomerWhen = props => {
  const { specialty, form, onChange } = props
  return <div className='bnSubpageContent bnCustomerWhen'>
    {false && <div>
    <BnLabel4 text='Appointment Scheduling'/>
    <div className='bnCustomerBookingBlurb1'>
    <span className='bnBlueAnchor'>{specialty}</span>&nbsp;patients are seen <span className='bnBlueAnchor'>within 7 days</span>.
    </div>
    <div className='bnCustomerBookingWhen'>
    <BnRadioButton form={form} name={'when'} selector={'asap'} label='As soon as possible' onChange={onChange}/>
    <BnRadioButton form={form} name={'when'} selector={'whenAvailable'} label="When I'm available" onChange={onChange}/>
    </div>
     <Sep/>
     </div>}
    <div className='bnDates'>
    <BnLabel4 text={'Days of Week'}/>
    <BnCheckboxes>
    {daysOfWeek.map(day => {
      const onChecked = (field, value) => {
        const daysOfWeek = form.daysOfWeek || {}
        daysOfWeek[field] = value
        onChange('daysOfWeek', daysOfWeek)
      }
      return <BnCheckbox form={form.daysOfWeek || {}} name={day.name} label={day.label} onChange={onChecked}/>
    })}
  </BnCheckboxes>
    <Sep/>
    <BnLabel4 text={'Times of Day'}/>
    <BnCheckboxes>
    {timesOfDay.map(x => {
      const onChecked = (field, value) => {
        const timesOfDay = form.timesOfDay || {}
        timesOfDay[field] = value
        onChange('timesOfDay', timesOfDay)
      }
      return <BnCheckbox form={form.timesOfDay || {}} name={x.name} label={x.label} onChange={onChecked}/>
    })}
  </BnCheckboxes>
    </div>
    </div>
}

const BnCustomerAppointmentCard = props => {
  const { appt, cancel, modify, confirm, action } = props
  const { id, doctor, reason, created, when, status, isTelehealth, scheduled } = appt
  let date, time
  if (scheduled) {
    date = moment(scheduled).format('ddd DD  MMM')
    time = moment(scheduled).format('hh:mm A')
  }
  let statusMsg
  switch (status) {
    case 'requested':
      statusMsg = 'Processing appointment'
      break
  }
  const buttons = []
  if (confirm) {
    const b = <BnButton label='Confirm Appointment' action={confirm}/>;
    buttons.push(b)
  }
  if (false && modify) {
    const b = <BnBlueButton label='Modify' action={modify}/>;
    buttons.push(b)
  }
  if (false && cancel) {
    const b = <BnRedButton label='Cancel' action={cancel}/>;
    buttons.push(b)
  }
  return <div className='bnCustomerAppointmentCard'>
    <div className='bnCustomerAppointmentCardHeader' onClick={action}>
    <div className='bnCustomerAppointmentCardHeaderTitle'>{reason.reason} Visit</div>
    <div className='bnCustomerAppointmentCardHeaderButton'><ReactSVG src={BxRight}/></div>
    </div>
    {statusMsg && <div className='bnCustomerAppointmentCardStatusTitle'>{statusMsg}</div>}
    <BnInputFieldSeparator/>
    {false && <div className='bnFieldTypeLabel'>DOCTOR</div>}
    {scheduled && <div className='bnCustomerAppointmentCardDate'>{date} at {time}</div>}
    {renderDoctor({doctor, isTelehealth: false, status, date, time, name})}
    <div className='bnCustomerAppointmentCardButtons'>
    {joinWith(buttons, () => <BnButtonSeparator/>)}
   </div>
    {renderStatus(status)}
    </div>
}


export const BnCustomerAppointment = props => {
  const { confirm, modify, cancel, appt, openMap } = props
  const { when, doctor, reason, scheduled, price, status, timesOfDay, daysOfWeek } = appt
  let date, time
  if (scheduled) {
    date = moment(scheduled).format('ddd DD  MMM')
    time = moment(scheduled).format('hh:mm A')
  } else {
    if (when === 'asap') {
      date = 'As soon as possible'
    } else {
      date = getDaysOfWeek(daysOfWeek)
      time = getTimesOfDay(timesOfDay)
    }
  }
  const specialties = doctor.specialties.map(x => x.label).join(', ')
  let bookingStatusClass = 'bnBookingStatus' + capitalize(status)
  return <div className={'bnCustomerAppointment'}>
    <BnOrangeLabel text={reason.reason + ' Visit'}/>
    <BnInputFieldSeparator/>
    <div className='bnFieldTypeLabel'>DOCTOR</div>
    <BnOrangeLabel text={doctor.name}/>
    <div className='bnCustomerAppointmentDoctorInfo'>
    <BnLabel3 icon={getSpecialtyIcon(doctor.specialty)} label={specialties}/>
    {date && <BnLabel3 icon={BxCal} label={date}/>}
    {time && <BnLabel3 icon={BxClock} label={time}/>}
    <BnAddressLabel address_components={doctor.place.address_components}/>
    </div>
    <BnInputFieldSeparator/>
    <BnFieldLabel text={'Status'} value={<span className={bookingStatusClass}>{capitalize(status)}</span>}/>
    <BnInputFieldSeparator/>
    <BnPageButton label='Directions' count={'Open Map'} action={openMap}/>
    <BnInputFieldSeparator/>
    <BnFieldLabel text={'Payment'} value={'$'+price}/>
    <BnInputFieldSeparator/>
  <div className='bnCustomerAppointmentButtons'>
  {confirm && <div className='bnCustomerAppointmentButtonsRow1'>
   <BnButton label='Confirm Appointment' action={confirm}/>
  <BnButtonSeparator direction='column'/>
  </div>}
  <div className='bnCustomerAppointmentButtonsRow2'>
  {cancel && <BnRedButton label='Cancel' action={cancel}/>}
  {modify && <BnBlueButton label='Modify' action={modify}/>}
  </div>
  </div>
    </div>
}

export const BnLineItem = props => {
  const { color, label, amount } = props
  let className='bnLineItem'
  if (color) {
    className += ' bnLineItem'+capitalize(color)
  }
  return <div className={className}>
    <div className='bnLineItemLabel'>{label}</div>
    <div className='bnLineItemAmount'>${amount.toFixed(2)}</div>
    </div>
}

const BnCustomerPaymentCard = props => {
  let { card, price, benefit, tax, serviceFee, action } = props
  benefit = benefit || 0
  tax = tax || 0
  serviceFee = serviceFee || 0
  return <div className='bnCustomerPaymentCardContainer'>
    <BnPaymentMethodButton selected={true} paymentMethod={card} action={action}/>
    <div className='bnCustomerPaymentCard'>    
    <BnInputFieldSeparator/>
    <BnLineItem label={'Subtotal'} amount={price}/>
    <BnLineItem color='green' label={'Membership benefit'} amount={benefit}/>
    <BnLineItem color='green' label={'Membership subtotal'} amount={price + benefit}/>
    <BnLineItem label={'Estimated tax'} amount={tax}/>
    <BnLineItem label={'Processing fee'} amount={serviceFee}/>
    <BnInputFieldSeparator/>
    <BnLineItem color='orange' label={'Total'} amount={price + benefit + tax + serviceFee}/>
    </div>
    </div>
}

const BnPaymentMethodCard = props => {
  const { remove, action, paymentMethod, selected } = props
  return <div className='bnPaymentMethodCard'>
    <BnPaymentMethodButton selected={selected} paymentMethod={paymentMethod} action={action}/>
    <BnRemoveButton action={remove}/>
    </div>
}

export const BnPaymentMethodButton = props => {
  const { paymentMethod, action, selected } = props
  let label = 'Choose Payment Method'
  let cardType
  if (paymentMethod) {
    const { type, last4 } = paymentMethod
    label = <span><span className='bnPaymentMethodType'>{capitalize(type)}</span>&nbsp;ending in {last4}</span>
    cardType = type
  }
  let className = 'bnPaymentMethodButton'
  if (selected) {
    className += ' bnPaymentMethodButtonSelected'
  }
  return <div className={className} onClick={action}>

    <div className='bnPaymentMethodLabelType'>{cardType}</div>

    <div className='bnPaymentMethodLabelButton'>
    <div classname='bnPaymentMethodLabelCard'>{label}</div>
    {action && <div className='bnPaymentMethodLabelIcon'><ReactSVG src={BxRight}/></div>}
    </div>

  </div>;
}

const addressFields = [
    {
      name: 'address',
      label: 'Street Address',
      autoComplete: 'street-address'
    },
    {
      name: 'city',
      label: 'City',
      autoComplete: 'address-level2'
    },
    {
      name: 'state',
      label: 'State',
      autoComplete: 'address-level1'
    },
    {
      name: 'zip',
      label: 'Zip',
      autoComplete: 'postal-code'
    }]

class BnChooseLocationPage extends BnSubpage {

  componentDidMount() {
    const set = (x, y) => this.onChange(x, y || '')
    for (const x in this.props.location) {
      set(x, this.props.location[x])
    }
  }

  renderContent() {
    const form = this.getForm()
    const formErr = this.getFormErr()
    const onChange = this.onChange
    return <div className='bnPageContent'>
      <BnLabel1 text={'Location'}/>
      <Sep/>
      <BnFormFields>
      {joinWith(addressFields.map(field => {
        const {name, type, label, autoComplete} = field;
        return <BnInputField name={name} label={label} autoComplete={autoComplete} type={type} onChange={onChange} form={form} formErr={formErr}/>;
      }), () => <BnInputFieldSeparator/>)
      }     
    </BnFormFields>
      </div>
  }
}

class BnAppointmentsPage extends BnSubpage {

  constructor (props) {
    super(props)
  }

  renderContent() {
    const arr = this.props.appointments
    return <div className='bnPageContent'>
      <BnLabel1 text={'Appointments'}/>
      <Sep/>
      {joinWith(arr.map(x => {
        let cancel
        let confirm
        switch (x.status) {
          case 'scheduled':
            confirm = async () => {
              await this.props.confirmAppointment(x)
            }
          case 'requested':
          case 'booked':
            {
              cancel = async () => {
                const result = await this.props.me.cancelAppointment(x.id)
                //console.log(result)
              }
              break
            }
        }

        const openMap = async () => {
          await this.props.me.openDoctorLocation(x.doctor)
        }
        
        return <BnCustomerAppointment appt={x} openMap={openMap} cancel={cancel} confirm={confirm}/>
      }), () => <Sep/>)
      }
      </div>
  }
}

export class BnAddPaymentMethodPage extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.paymentMethod = null
  }

  onChangePaymentMethod = e => {
    this.setState({
      paymentMethod: e
    })
  }

  setPaymentMethod = paymentMethod => {
    this.props.addPaymentMethod(paymentMethod)
    //console.log(paymentMethod)
  }

  onPaymentMethodError = error => {
    console.error(error)
    this.setState({
      paymentMethodError: error
    })
  }

  handleSetupComplete = result => {
    const { setupIntent } = result
    console.log(setupIntent)
    //debugger
    this.props.onComplete()
  }


  renderContent() {
    return <div className='bnSubpageContent'>
      <BnLabel1 text='Add Payment Method'/>
      <BnPaymentMethodInput me={this.props.me} clientSecret={this.props.clientSecret} handleSetupComplete={this.handleSetupComplete} setPaymentMethodError={this.onPaymentMethodError} setPaymentMethod={this.setPaymentMethod}/>
    </div>
  }
}

export class BnPaymentMethodsPage extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.paymentMethods = []
  }

  componentDidMount() {
    this.setState({
      paymentMethods: this.props.paymentMethods || []
    })
  }

  componentDidUpdate() {
    if (this.props.paymentMethods) {
      if (this.state.paymentMethods !== this.props.paymentMethods) {
        this.setState({
          paymentMethods: this.props.paymentMethods
        })
      }
    }
  }
  

  onAddPaymentMethod = paymentMethod => {
    //console.log(paymentMethod)
    this.state.paymentMethods.push(paymentMethod)
    this.back()
  }

  addPaymentMethod = async () => {
    let clientSecret
    const { setupIntent, error } = await this.props.createSetupIntent()
    if (error) {
      ////debugger
    }
    clientSecret = setupIntent.clientSecret
    // ////debugger
    const onComplete = () => {
      this.back()
    }
    const back = () => {
      this.props.cancelSetupIntent(setupIntent).catch(err => {
        console.error(err)
      })
      this.back()
    }
    this.setState({
      subpage: () => <BnAddPaymentMethodPage selectedPaymentMethod={this.props.selectedPaymentMethod} clientSecret={clientSecret} me={this.props.me}  back={back} onComplete={onComplete}/>
    })
  }
  
  renderContent() {
    return <div className='bnSubpageContent'>
      <BnLabel1 text='Payment methods'/>
      <Sep/>
      <BnButton label='Add Payment Method' action={this.addPaymentMethod}/>
      <Sep/>
      <Sep/>
      {joinWith(this.state.paymentMethods.map(paymentMethod => {
        const remove = async () => {
          await this.props.removePaymentMethod(paymentMethod)
        }
        const action = async () => {
          await this.props.selectPaymentMethod(paymentMethod)
          this.props.back()
        }
        return <BnPaymentMethodCard key={paymentMethod.id} selected={this.props.selectedPaymentMethod && paymentMethod.id === this.props.selectedPaymentMethod.id} me={this.props.me} paymentMethod={paymentMethod} remove={remove} action={action}/>
      }), () => <Sep/>)}
    {this.props.selectedPaymentMethod && this.props.continue && <div className='bnSubpageContent'>
     <Sep/>
     <BnBlueButton label='Continue' action={this.props.continue}/>
     </div>}
      </div>
  }
}

export class BnPaymentMethodInput extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  renderCardElement() {
    let color = 'gray'
    let background = 'white'
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: 'color',
          color: 'color',
          fontWeight: 500,
          fontSize: '14px',
          caretColor: 'color',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'color',
          },
          '::placeholder': {
            color: 'color'
          },
        },
        webkitAutoFill: {
          color: 'color',
          caretColor: 'color',
          backgroundColor: background
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    }
    return <div className='bnCardElement'><CardElement onChange={this.onChangePaymentMethod} options={CARD_OPTIONS}/></div>
  }

  onChangePaymentMethod = e => {
    this.setState({
      paymentMethodError: null
    })
    //console.log(e)
  }


  onChangePaymentElement = e => {
    //console.log('payment element', e)
    this.setState({
      paymentComplete: e.complete,
      paymentType: e.value ? e.value.type : '',
      paymentMethodError: ''
    })
  }

  createPaymentMethod = async () => {
    if (!this.state.paymentComplete) {
      this.setState({
        paymentMethodError: 'Payment method incomplete'
      })
    }
    if (this.props.clientSecret) {
      const elements = this.elements
      const result = await this.stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "https://benxt.com",
        },
        redirect: 'if_required'
      })
      if (result.error) {
        console.error(result.error)
        return
      }
      const { setupIntent } = result
      //console.log(result)
      if (setupIntent) {
        this.props.handleSetupComplete(result)
      }
      return
    }
    const cardElement = this.elements.getElement(CardElement);
    let result = await this.props.me.createCustomerSetupIntent()
    if (result.error) {
    }
    const { setupIntent } = result
    clientSecret = setupIntent.clientSecret
    result = await this.stripe.confirmCardSetup(clientSecret, {
      paymentMethod: {
        card: cardElement,
        billing_details: {
        }
      }
    })
    if (result.error) {
      this.setState({
        paymentMethodError: error.message
      })
    } else {
      const { setupIntent } = result
      const paymentMethod = await this.props.me.getPaymentMethod(setupIntent.paymentMethod)
      this.props.setPaymentMethod(paymentMethod)
      //console.log(setupIntent)
    }
  }

  renderPaymentMethodInput() {
    let options
    const { clientSecret } = this.props
    if (clientSecret) {
      options = { clientSecret }    
    }
    return <Elements stripe={stripePromise} options={options}>
      <ElementsConsumer>
      {({elements, stripe}) => {
        // stripe wtfs
        this.elements = elements;
        this.stripe = stripe;
        if (this.props.clientSecret) {
          return <PaymentElement onChange={this.onChangePaymentElement}/>
        }
        return this.renderCardElement()
      }}
    </ElementsConsumer>
      </Elements>
  }
  render() {
    return <div className='bnPaymentMethodInput'>
      {this.renderPaymentMethodInput()}
      <Sep/>
      <div className='bnPaymentMethodError'>
      {this.state.paymentMethodError}
      </div>
      <BnButton label='Add' action={this.createPaymentMethod}/>
      </div>
  }
}

const renderDoctor = ({doctor, isTelehealth, status, date, time, isAdmin}) => {
  const { gender, edu, degrees, langs, specialties, name, practiceName, specialtyDescription } = doctor
  const { address_components, rating } = doctor.place
  const getShortName = (type) => {
    const c = address_components.find(x => x.types.find(y => y === type))
    return c ? c.short_name : ''
  }
  let streetNumber = getShortName('street_number')
  const subpremise = getShortName('subpremise')
  if (subpremise) streetNumber += ' ' + subpremise
  const street = getShortName('route')
  const city = getShortName('locality')
  const state = getShortName('administrative_area_level_1')
  const zip = getShortName('postal_code')
  const distance = doctor.distance
  const address = `${streetNumber} ${street}, ${city}, ${state} ${zip}`
  return <div className='bnDoctorCardHeader'>
    <div className='bnDoctorCardProfile'>
    <div className='bnDoctorCardProfileIcon'></div>
    </div>
    {status === 'requested' && renderDoctorInfo({name, gender, rating, status, specialties, specialtyDescription, edu, degrees, langs, city, state, distance, isTelehealth, date, time, name, isAdmin})}
  {(status !== 'requested' || isAdmin) && <div className='bnRevealDoctorInfo'>
   <BnOrangeLabel text={name}/>
   <div className='bnRevealDoctorInfoAddress'>
   {address}
   </div>
   </div>}
   
  </div>
}

const renderDoctorInfo = info => {
  const { rating, gender, langs, city, state, distance, telehealth, date, time, name, specialtyDescription, isAdmin } = info
  //console.log("specialties:", info.specialties)
  const edu = []
  let eduLen = 2
  if (info.edu && info.edu.length > 0) {
    const seen = {}
    info.edu.forEach(x => {
      //console.log("EDU", x)
      const { resolved, institution, yearGraduated, code} = x
      if (code !== 'Residency' && code !== 'Internship') {
        if (!seen[code]) {
          seen[code] = true
          eduLen = Math.max(code.length, eduLen)
          edu.push({
            code, resolved, institution, yearGraduated
          })
        }
      }
    })
  } else if (info.degrees) {
    info.degrees.forEach(x => eduLen = Math.max(x.length, eduLen))
    edu.push({
      code: info.degrees.join(','),
    })

  }
  const dup = {'Student': true}
  const fixLabel = label => {
    const paren = label.indexOf(' (')
    if (paren > 0) {
      const base = label.substring(0, paren)
      if (base === 'Dentist') {
        const closeParen = label.lastIndexOf(')')
        label = label.substring(paren + 2, closeParen)
        if (label.indexOf('Dentist') < 0 && info.specialties.length === 1) {
          label = 'Dentist, ' + label
        }
        return label
      }
      return base
    }
    return label
  }
  const specialties = info.specialties.map(x => {
    const label = x.label
    return fixLabel(label)
  }).filter(x => {
    if (dup[x]) {
      return false
    }
    dup[x] = true
    return true
  }).join(', ')
  const specialtyIcon = getSpecialtyIcon()
  return <div className={'bnDoctorCardInfo bnEDULen' + eduLen}>
  {<div key='sepecialties' className='bnDoctorCardInfoLine'>
    <div className='bnDoctorCardInfoIcon'>
      <ReactSVG src={specialtyIcon}/>
    </div>
      <div className='bnDoctorCardInfoText'>
   {specialties}
      </div>
   </div>}
    <div key='location' className='bnDoctorCardInfoLine'>
    <div className='bnDoctorCardInfoIcon'>
    <ReactSVG src={BxLocation}/>
    </div>
    <div className='bnDoctorCardInfoText'>
    {city}, {state} {distance  ? `(${formatDistance(distance)})` : ''}
  </div>
    </div>
    {edu && edu.map(edu => {
    let institution = edu.institution
    let country
   let name = institution || 'Medical School N/A'
    if (edu.resolved) {
      const { institution, medicalSchool } = edu.resolved
      if (institution && medicalSchool) {
        if (medicalSchool.indexOf(' ') > 0 && medicalSchool.toLowerCase() !== 'school of medicine') {
          name = medicalSchool
        } else {
          name = institution
        }
        switch (edu.resolved.country.toLowerCase()) {
          case 'usa':
          case 'united states':
            break
          default:
            country = ', ' + edu.resolved.country
        }
      }
    }
      
      const codes = edu.code.split(',').map(code => code.trim())
      return <div key={'edu'} className={'bnDoctorCardInfoLine bnDoctorCardInfoLineEDU'}>
        <div className='bnDoctorCardInfoEdu'>
        {codes.map(code => <div key={code} className='bnDoctorCardInfoText'>
                        {code}
                   </div>)}
      </div>
      <div className='bnDoctorCardInfoText'>
      {name}{country}
      </div>
      </div>
  })}
    
  {gender && <div key='gender' className='bnDoctorCardInfoLine'>
   <div className='bnDoctorCardInfoIcon'>
   <ReactSVG src={getGenderIcon(gender)}/>
   </div>
   <div className='bnDoctorCardInfoText'>
   {gender}
   </div>
   </div>
  }
    
  {langs && <div key='langs' className='bnDoctorCardInfoLine'>
    <div className='bnDoctorCardInfoIcon'>
      <ReactSVG src={BxLanguage}/>
    </div>
      <div className='bnDoctorCardInfoText'>
   {langs.map(x => x.name ? x.name : x).join(', ')}
      </div>
   </div>}
  {rating && <div key='rating' className='bnDoctorCardInfoLine bnDoctorCardInfoRatingLine'>
   <div className='bnDoctorCardInfoText bnRatingText'>{rating.toFixed(1)}</div>
   <div className='bnDoctorCardInfoRatingContainer'>
   <div className='bnDoctorCardInfoRating'>
   {getStars(rating)}
   </div>
   </div>
   </div>}
  {telehealth && <div key='telehealth' className='bnDoctorCardInfoLine'>
    <div className='bnDoctorCardInfoIcon'>
      <ReactSVG src={BxTelehealth}/>
    </div>
      <div className='bnDoctorCardInfoText'>
   Telehealth
      </div>
   </div>}
  </div>
}

const BnStar = props => {
  const { n } = props
  return <div key={n} className='bxStar' style={{left: (n-1) * 20}}>
    <ReactSVG src={BxStar}/>
    </div>
}

const getRatingClipStyle = (rating) => {
  const stars = Math.floor(rating)
  const remainder = rating - stars
  const w = stars * 20 + 4 + Math.round(remainder * 12)
  return {clipPath: `polygon(0 0, 0 20px, ${w}px 20px, ${w}px 0)`}
}

const ratingToPixels = rating => {
  const stars = Math.floor(rating)
  const remainder = rating - stars
  return 
}

const renderPrice = (full, discounted) => {
  return <div className='bnDoctorCardPrice'>
    {discounted && <div className='bnDoctorCardFullPrice'>${full}</div>}
    <div className='bnDoctorCardDollars'>${discounted || full}</div>
    </div>
}

const BnDoctorCard = props => {
  const { doctor, reason, action, isAdmin } = props
  const { specialtyDescription } = doctor
  const { reviews, rating } = doctor.place
  const { officeVisit } = doctor
  let  { price } = officeVisit
  let review
  if (reviews) {
    reviews.sort((x, y) => y.rating - x.rating)
    review = reviews[0]
    if (!review.text) {
      review = null
    }
  }
  const label = getOfficeVisitLabel({ doctor, reason })
  return <div key={String(doctor.npi)}className='bnDoctorCard'>
    <div className='bnDoctorCardVisitReason'>
    <div className='bnOrangeLabel'>{reason.reason}</div>
    <div className='bnFieldTypeLabel'>{label}</div>
    </div>
  {specialtyDescription && <div className='bnSpecialtyDescription'>Meet with a specialist in {specialtyDescription.description}</div>}
  {renderDoctor({status: 'requested', doctor, isTelehealth: true, isAdmin})}
    <Sep/>
  {review && <div className='bnDoctorCardReview'>
   "{review.text }"
   </div>}
  {review && review.rating && <div key='rating' className='bnDoctorCardInfoLine bnDoctorCardInfoRatingLine'>
   <div className='bnDoctorCardInfoRatingContainer'>
   <div className='bnDoctorCardInfoReviewRating'>
   {getStars(review.rating)}
   </div>
   </div>
   </div>}
  <Sep/>
    <BnButton label={"Book"} iconContent={renderPrice(price)} action={action}/>
    </div>;
}

const BnCustomerAppointmentDetails = props => {
  const { reason } = props
  return <BnFormFields>
    <BnOrangeLabel text={reason.reason}/>
    <BnInputFieldSeparator/>
    <div className='bnFieldTypeLabel'>DOCTOR</div>
    {status !== 'processing' && <BnOrangeLabel text={doctor.name}/>}
    </BnFormFields>
}


class BnCustomerBookingCheckout extends BnSubpage {

  constructor (props) {
    super(props)
  }

  
  renderContent() {
    const { reason, doctor, price, telehealth, when, daysOfWeek, timesOfDay } = this.props.bookingForm
    //console.log('bookingForm', this.props.bookingForm)
    let dates
    let times
    if (when === 'asap') {
      dates = 'As soon as possible'
    } else {
      dates = Object.keys(daysOfWeek).map(capitalize).join(', ')
      times = Object.keys(timesOfDay).map(capitalize).join(', ')
    }
    const { address_components } = doctor.place
    const getShortName = (type) => {
      const c = address_components.find(x => x.types.find(y => y === type))
      return c ? c.short_name : ''
    }
    let streetNumber = getShortName('street_number')
    const subpremise = getShortName('subpremise')
    if (subpremise) streetNumber += ' ' + subpremise
    const street = getShortName('route')
    const city = getShortName('locality')
    const state = getShortName('administrative_area_level_1')
    const zip = getShortName('postal_code')
    const distance = doctor.distance
    return <div className='bnSubpageContent'>
      <BnLabel1 text={reason.reason + ' Visit Checkout'}/>
      <div className='bnBookingCheckoutDetails'>
      <BnLabel3 icon={BxLocation} label={`${city}, ${state} (${formatDistance(distance)})`}/>
      {doctor.gender && <BnLabel3 icon={getGenderIcon(doctor.gender)} label={doctor.gender}/>}
      <BnLabel3 icon={telehealth ? BxTelehealth : BxInPerson} label={telehealth ? 'Telehealth' : 'In-person Visit'}/>
      <BnLabel3 icon={getSpecialtyIcon(doctor.specialty)} label={doctor.specialty}/>
      <BnLabel3 icon={BxCal} label={dates}/>
      {when !== 'asap' && <BnLabel3 icon={BxClock} label={times}/>}
      </div>
      <Sep/>
    </div>
  }
}

class BnCustomerBooking extends BnSubpage {

  constructor (props) {
    super(props)
    this.state.tabSelection = 'availability'
    const form = this.getForm()
  }

  bookAppointment = async (paymentMethod) => {
    const form = this.getForm()
    const bookingForm = mergeFields(form, this.props, { paymentMethod: paymentMethod })
    await this.props.bookAppointment(bookingForm)
  }

  continueBooking = async () => {
    const form = this.getForm()
    const bookingForm = mergeFields(form, this.props)
    this.setState({
      subpage: () => <BnCustomerBooking isCheckout={true}
      paymentMethods={this.props.paymentMethods || []}
      selectedPaymentMethod={this.props.selectedPaymentMethod}
      me={this.props.me} back={this.back} bookingForm={bookingForm} bookAppointment={this.bookAppointment}/>
    })
  }
  
  bookAppointment = async (bookingForm) => {
    if (!this.props.selectedPaymentMethod) {
      return this.openPaymentMethods()
    }
    await this.props.bookAppointment(mergeFields(bookingForm, { paymentMethod: this.props.selectedPaymentMethod })) 
  }

  openPaymentMethods = () => {

    const removePaymentMethod = async paymentMethod => {
      return await this.props.me.removeCustomerPaymentMethod(paymentMethod)
    }
    
    const createSetupIntent = async () => {
      return await this.props.me.createCustomerSetupIntent()
    }

    const cancelSetupIntent = async (setupIntent) => {
      const id = setupIntent.id
      await this.props.me.cancelCustomerSetupIntent(setupIntent)
    }
    
    const selectPaymentMethod = this.props.me.selectCustomerPaymentMethod    
    this.setState({
      subpage: () => <BnPaymentMethodsPage removePaymentMethod={removePaymentMethod} selectPaymentMethod={selectPaymentMethod} selectedPaymentMethod={this.props.selectedPaymentMethod} paymentMethods={this.props.paymentMethods} me={this.props.me} back={this.back} cancelSetupIntent={cancelSetupIntent} createSetupIntent={createSetupIntent}/>
    })
  }

  renderContent() {
    const form = this.getForm()
    const { isCheckout } = this.props
    const bookingForm = isCheckout ? this.props.bookingForm: mergeFields(form, this.props)
    const { reason, doctor, price, telehealth, when, daysOfWeek, timesOfDay } = bookingForm
    let dates
    let times
    if (when === 'asap') {
      dates = 'As soon as possible'
    } else {
      dates = Object.keys(daysOfWeek).map(capitalize).join(', ')
      times = Object.keys(timesOfDay).map(capitalize).join(', ')
    }
    const { specialtyDescription, langs } = doctor
    const { address_components } = doctor.place
    const getShortName = (type) => {
      const c = address_components.find(x => x.types.find(y => y === type))
      return c ? c.short_name : ''
    }
    let streetNumber = getShortName('street_number')
    const subpremise = getShortName('subpremise')
    if (subpremise) streetNumber += ' ' + subpremise
    const street = getShortName('route')
    const city = getShortName('locality')
    const state = getShortName('administrative_area_level_1')
    const zip = getShortName('postal_code')
    const distance = doctor.distance
    const onChange = this.onChange
    const label = getOfficeVisitLabel({ doctor, reason })
    return <div className='bnSubpageContent bnCustomerBookingContent'>
      <BnLabel1 text={isCheckout ? 'Checkout' : 'Your Appointment'}/>
    <div className='bnDoctorCardVisitReason'>
    <div className='bnOrangeLabel'>{reason.reason}</div>
    <div className='bnFieldTypeLabel'>{label}</div>
    </div>

  {specialtyDescription && <div className='bnSpecialtyDescription'>Meeting with a specialist in {specialtyDescription.description}</div>}

      <div className='bnCustomerBookingDoctorDetails'>
      </div>
      <Sep/>
      <div className='bnSubpageContent'>
      <div className='bnBookingCheckoutDetails'>
      <BnLabel3 icon={getSpecialtyIcon()} label={doctor.specialties.map(x => x.label).join(', ')}/>
      <BnLabel3 icon={BxLocation} label={`${city}, ${state} (${formatDistance(distance)})`}/>
      {doctor.gender && <BnLabel3 icon={getGenderIcon(doctor.gender)} label={doctor.gender}/>}
      <BnLabel3 icon={BxLanguage} label={langs.map(x => x.name ? x.name : x).join(', ')}/>
      <BnLabel3 icon={telehealth ? BxTelehealth : BxInPerson} label={telehealth ? 'Telehealth' : 'In-person Visit'}/>
      <BnLabel3 icon={BxCal} label={dates}/>
      {when !== 'asap' && <BnLabel3 icon={BxClock} label={times}/>}
    </div>
      <Sep/>
      {!isCheckout && <div className='bnSubpageContent'>
      <BnLabel1 text='Additional notes for this appointment'/>
      <BnTextArea placeholder={'Additional notes (optional)'} form={form} name='notes' onChange={onChange}/>
       <Sep/>
       </div>}
     {isCheckout && bookingForm.notes && <div className='bnSubpageContent'>
     <BnLabel1 text='Notes'/>
     <BnLabel2 text={bookingForm.notes}/>
     <Sep/>
     </div>}
      {isCheckout && <div className='bnSubpageContent'>
      <BnLabel1 text='Payment method'/>
      <BnLabel2 text='Payments are not completed until your appointment has been confirmed'/>
       <Sep/>
      <BnFormFields>
      <BnCustomerPaymentCard card={this.props.selectedPaymentMethod} price={price} action={this.openPaymentMethods}/>
      </BnFormFields>
      <Sep/>
       <BnButton label='Book Appointment' action={() => this.bookAppointment(bookingForm)}/>
       </div>}
    {!isCheckout && <BnButton label='Checkout' action={this.continueBooking} iconContent={'$'+price}/>}
      </div>
      </div>
  }
}
  

export class BnCustomerContent extends Component {

  constructor (props) {
    super(props)
    this.state = {
      searchTerm: '',
      autocompleteResults: [],
      searchResults: [],
      unfilteredSearchResults: [],
      doctorVisitSelection: 'today',
      appointments: [],
      form: { when: 'asap', priceFilter: '350', durationFilter: 'any', ratingFilter: 'any', distanceFilter: 'any', ageFilter: 'any', genderFilter: 'any', specialtiesFilter: {} , reasonFilter: 'any' },
      sort: 'relevance',
      providerType: '',
      hasProviders: true,
      profileForm: {},
      profileFormErr: {},
      minPrice: 0,
      maxPrice: 350,
      minDur: 999999,
      minRating: 0,
      maxDistance: 999999,
      busy: false,
      commonVisitReasons: []
    }
  }

  searchId = 0

  mainSearch = async searchTerm => {
    const searchId = ++this.searchId;
    if (this.state.searchCont) {
      this.state.searchCont()
    }
    if (true) {
      this.setState({
        searchComplete: false,
        searchTerm,
        searchResults: [],
        unfilteredSearchResults: [],
        autocompleteResults: [],
        busy: true
      })
      let response
      if (searchTerm.trim()) {
        console.log('searchTerm', searchTerm)
        response = await this.props.me.searchVisitReasons({
          q: searchTerm,
          //category: this.state.providerType
        })
      } else {
        response = { page: 1, out_of: 1, results: this.state.commonVisitReasons }
      }
      const { page, out_of, results } = response
      //debugger
      if (searchId === this.searchId) {
        this.setState({
          busy: false,
          searchResult: null,
          searchCont: null,
          autocompleteResults: results.map(x => {
            return { reason: x }
          })
        })
      }
      return
    }
    return new Promise(resolve => {
      this.setState({
        searchComplete: false,
        searchCont: resolve,
        searchTerm,
        searchResults: [],
        unfilteredSearchResults: []
      })
      this.worker.postMessage({
        type: 'autoComplete',
        searchId,
        searchTerm
      })
    })

  }

  onSearchResults = async e => {
    const {type, searchId, searchResults } = e.data
    //console.log(searchResults)
    if (type === 'uploadComplete') {
      //this.mainSearch(this.state.searchTerm)
      return
    }
    if (type === 'autoComplete') {
      if (searchId !== this.searchId) {
        return
      }
      this.state.searchCont()
      this.setState({
        searchResult: null,
        searchCont: null,
        autocompleteResults: searchResults
      })
    } else if (type === 'search') {
      if (searchId !== this.searchResultId) {
        return
      }
      this.state.searchResultCont()
      const filteredSearchResults = this.filterSearchResults(searchResults)
      if (searchId !== this.searchResultId) {
        return
      }
      searchResults.forEach(searchResult => {
        if (!searchResult.specialtyDescription) {
          searchResult.specialtyDescription = this.props.me.getSpecialtyDescription(searchResult.specialties)
        }
      })
      this.setState({
        searchResultCont: null,
        unfilteredSearchResults: searchResults.filter(x => x.officeVisit),
        searchResults: filteredSearchResults,
        searchComplete: true
      })
    }
  }

  filterSearchResults = searchResults => {
    const { when, timesOfDay, daysOfWeek } = this.state.form
    if (when !== 'asap') {
      let matched = false
      for (const t in timesOfDay) {
        if (timesOfDay[t]) {
          matched = true
          break
        }
      }
      for (const t in daysOfWeek) {
        if (daysOfWeek[t]) {
          matched = true
          break
        }
      }
      if (!matched) return []
      searchResults = searchResults.filter(doctor => {
        if (!doctor.officeVisit) return false
        if (doctor.availability) {
          matched = false
          for (const t in daysOfWeek) {
            if (doctor.availbility.daysOfWeek[t]) {
              matched = true
              break
            }
          }
          if (!matched) return false
          matched = false
          for (const t in timesOfDay) {
            if (doctor.availability.timesOfDay[t]) {
              matched = true
              break
            }
          }
          if (!matched) return false
        }
        return true
      })
    }
    return searchResults
  }

  doctors = {}

  componentDidMount() {
    this.props.me.observeIsAdmin().toPromise().then(isAdmin => {
      this.setState({
        isAdmin
      })
    })
    const start = Date.now()
    this.custSub = this.props.me.observeCustomer().subscribe(async customer => {
      const elapsed = Date.now() - start
      const delay = Math.max(3000 - elapsed, 0)
      console.log("DELAY", delay)
      setTimeout( () => {
        this.setState({
          customer
        })
      }, delay)
    })
    this.props.me.getCurrentLocation().then(async coords => {
      const { latitude, longitude } = coords
      this.setState({
        location: {
          latitude, longitude
        }
      })
      const location = await this.props.me.getLocation(coords)
      const { address, address_components } = location
      const city = address_components.find(x => x.types.find(y => y == 'locality')).short_name
      const state = address_components.find(x => x.types.find(y => y == 'administrative_area_level_1')).short_name
      const zip = address_components.find(x => x.types.find(y => y == 'postal_code')).short_name
      this.setState({
        location: {
          address,
          city,
          state,
          zip,
          latitude,
          longitude
        }
      })
    })
    this.mainSearch(this.state.searchTerm || '')
    this.props.me.observeMyAppointments().subscribe(change => {
      const appt = change.appt
      if (change.type !== 'removed' && appt.status !== 'completed') {
        this.appts[appt.id] = appt
      } else {
        delete this.appts[appt.id]
      }
      this.updateAppointmentsLater()
    })
    this.paymentMethodSub = this.props.me.observePaymentMethods().subscribe(({selectedPaymentMethod, paymentMethods}) => {
      debugger
      this.setState({
        selectedPaymentMethod,
        paymentMethods
      })
    })
    this.commonVisitReasonSub = this.props.me.observeCommonVisitReasons().subscribe(change => {
      const visitReason = change.reason
      if (change.type !== 'removed') {
        this.commonVisitReasons[visitReason.id] = visitReason
      } else {
        delete this.commonVisitReasons[visitReason.id]
      }
      this.updateCommonVisitReasonsLater()
    })
  }

  commonVisitReasons = {}
  updateCommonVisitReasonsLater = () => {
    clearTimeout(this.commonVisitReasonTimer)
    this.commonVisitReasonTimer = setTimeout(this.updateCommonVisitReasons, 200)
  }

  updateCommonVisitReasons = () => {
    this.setState({
      commonVisitReasons: Object.values(this.commonVisitReasons)
    }, () => {
      console.log('common visit reasons', this.state.commonVisitReasons)
      //debugger
      if (!this.state.searchTerm) {
        this.mainSearch('')
      }
    })
  }

  doctorCount = 0
  officeVisitCount = 0

  updateProviderCountLater = () => {
    clearTimeout(this.providerTimeout)
    this.providerTimeout = setTimeout(this.updateProviderCount, 200)
  }

  updateProviderCount = () => {
    //console.log("doctorCount", this.doctorCount)
    this.setState({
      providerCount: this.doctorCount,
      officeVisitCount: this.officeVisitCount
    })
  }

  updateAppointmentsLater = () => {
    clearTimeout(this.appointmentTimeout)
    this.appointmentTimeout = setTimeout(this.updateAppointments, 200)
  }

  updateAppointments = () => {
    const appointments = Object.values(this.appts)
    appointments.sort((x, y) => y.created - x.created)
    console.log('appts', appointments)
    this.setState({
      appointments
    })
  }

  appts = {}

  componentWillUnmount() {
    if (this.custSub) this.custSub.unsubscribe()
    if (this.worker) this.worker.terminate()
    if (this.doctorSub) this.doctorSub.unsubscribe()
    if (this.reasonSub) this.reasonSub.unsubscribe()
    if (this.paymentMethodSub) this.paymentMethodSub.unsubscribe()
  }

  bookAppointment = async (form) => {
    const { latitude, longitude } = this.state.location
    const { priceFilter, durationFilter, ratingFilter, distanceFilter, ageFilter, genderFilter, specialtiesFilter } = this.state.form
    const bookingForm = mergeFields(form, {
      location: { lat: latitude, lng: longitude },
      providerType: this.state.providerType,
      sort: this.state.sort,
      filters: { priceFilter, durationFilter, ratingFilter, distanceFilter, ageFilter, genderFilter, specialtiesFilter }
    })
    const { error } = await this.props.me.bookAppointment(bookingForm)
    if (error) {
      ////debugger
    }
    this.back()
  }

  doctorPrices = {}

  renderSearchResult = (doctor) => {
    const reason = this.state.searchResult
    const { officeVisit } = doctor
    const { price } = officeVisit
    const action = async () => {
      this.setState({
        subpage: () => <BnCustomerBooking
        paymentMethods={this.state.paymentMethods || []}
        selectedPaymentMethod={this.state.selectedPaymentMethod}
        bookAppointment={this.bookAppointment} back={this.back} me={this.props.me} price={price}
        providerType={this.state.providerType}
        doctor={doctor} form={this.state.form} onChange={this.onChange} reason={reason}/>
      })
    }
    console.log("NPI", doctor.npi)
    const card = <BnDoctorCard key={String(doctor.npi)} reason={reason} me={this.props.me} doctor={doctor} action={action} isAdmin={this.state.isAdmin}/>;
    return card
  }

  renderAutocompleteResult = result => {
    const label = result.reason.reason
    const action = () => {
      this.onClickSearchResult(result)
    }
    return <BnSearchResult key={result.reason.id} label={label} result={result} action={action}/>
  }

  searchResultId = 0
  onClickSearchResult = async (result) => {
    const searchId = ++this.searchId
    if (true) {
      const providerCategoryTabs = MainTabs.filter(tab => {
        for (const s of result.reason.specialties) {
          const f = this.props.me.getProviderTypesFilter(s)
          if (f(tab.selector)) {
            return true
          }
        }
        return false
      })
      let providerType = this.state.providerType
      if (!providerType) {
        if (providerCategoryTabs.length > 0) {
          providerType = providerCategoryTabs[0].selector
        }
      }
      let searchResultSpecialties = result.reason.specialties
      if (providerType) searchResultSpecialties = searchResultSpecialties.filter(this.props.me.providerTypeFilter(providerType))
      console.log('searchResultSpecialties', searchResultSpecialties)
      this.setState({
        searchResult: result.reason,
        searchResultSpecialties,
        providerType,
        providerCategoryTabs,
        searchResultTerm,
        searchComplete: false,
        busy: true
      })
      const reason = result.reason
      const { latitude, longitude } = await this.props.me.getCurrentLocation()
      const radius = 25
      const response = await this.props.me.searchNearbyProviders({ lat: latitude, lng: longitude, radius, reason, providerType })
      if (searchId !== this.searchId) {
        return
      }
      const { page, out_of, results } = response
      ////debugger
      this.setState({
        unfilteredSearchResults: results,
        searchResults: results,
        searchComplete: true,
        busy: false
      })
      return
    }
    const searchResultTerm = result.reason.reason
    if (this.state.searchResultCont) {
      this.state.searchResultCont()
    }
    return new Promise(resolve => {
      this.setState({
        searchResult: result.reason,
        searchResultCont: resolve,
        searchResultTerm,
        searchComplete: false
      })
      this.worker.postMessage({
        type: 'search',
        searchId,
        searchTerm: searchResultTerm,
      })
    })
    if (this.priceSub) {
      this.priceSub.unsubscribe()
      this.doctorPrices = {}
      this.forceUpdate()
    }
    this.priceSub = this.props.me.observeDoctorPrices(result.reason.id).subscribe(change => {
      const doctorPrice = change.doctorPrice
      if (change.type != 'removed') {
        this.doctorPrices[doctorPrice.doctorId] = doctorPrice
      } else {
        delete this.doctorPrices[doctorPrice.doctorId]
      }
    })
  }

  searchResultBack = () => {
    this.setState({
      providerType: '',
      providerCategoryTabs: [],
      searchResult: null,
      searchResults: [],
      unfilteredSearchResults: []
    })
  }

  back = () => {
    this.setState({
      subpage: null
    })
  }

  onChange = (field, value) => {
    this.state.form[field] = value
    switch (field) {
      case 'when':
      case 'daysOfWeek':
      case 'timesOfDay':
        {
          this.setState({
            searchResults: this.filterSearchResults(this.state.unfilteredSearchResults)
          })
          return
        }
        
    }
    this.forceUpdate()
  }

  setGender = () => {
    const form = {
      M: this.state.profileForm.gender === 'M',
      F: this.state.profileForm.gender === 'F'
    }
    const onChange = (field, value) => {
      //console.log(field, value)
      switch (field)  {
        case 'M':
          form.M = value
          form.F = !value
          break
        case 'F':
          form.F = value
          form.M = !value
          break
      }
      this.forceUpdate()
    }
    const action = () => {
      this.onChangeProfile('gender', form.M ? 'M' : 'F')
      this.back()
    }
    this.setState({
      subpage: () => <BnSelectionListChooser title='Gender' me={this.props.me} back={this.back} action={action} form={form} onChange={onChange} options={genderOptions}/>
    })
  }

  getSorting() {
    const getPrice = doctor => {
      return doctor.officeVisit.price
    }
    const orderByRelevance = (x, y) => {
      let cmp = 0
      const dur1 = getOfficeVisitDuration(x.officeVisit.code)
      const dur2 = getOfficeVisitDuration(y.officeVisit.code)
      if (dur1 && dur2) {
        cmp = dur1 - dur2
        if (cmp) return cmp
      }
      return orderByRating(x, y)
    }
    const orderByRating = (x, y) => {
      let cmp = y.rating - x.rating
      if (cmp === 0) {
        const r1 = x.place.reviews || []
        const r2 = y.place.reviews || []
        r2.sort((x, y) => y.rating - x.rating)
        r1.sort((x, y) => y.rating - x.rating)
        cmp = r2.length - r1.length
        if (cmp) return cmp
        if (r1.length > 0) {
          cmp = r2[0].rating - r1[0].rating
          if (cmp) return cmp
          cmp = r2[0].text.length - r1[0].text.length
          if (cmp) return cmp
        }
      }
      return cmp
    }
    let sort
    switch (this.state.sort) {
      case 'relevance':
        switch (this.state.providerType) {
          case 'acupuncture':
          case 'primaryCare':
          case 'chiropractic':
            return searchResults => searchResults.sort(orderByRelevance)
            
        }
        const reason = this.state.searchResult
        const specialtiesToSample = reason ? reason.specialties.filter(this.props.me.providerTypeFilter(this.state.providerType)) : []
        if (specialtiesToSample.length === 1) {
          return searchResults => searchResults.sort(orderByRelevance)
        }
        return searchResults => {
          if (!reason) return []
          if (searchResults.length === 0) {
            return []
          }
          let total = 0
          let min = 999999
          reason.specialties.forEach((x, i) => {
            total += (x.score || 100)
            min = Math.min(x.score, min)
          })
          const ws = reason.specialties.map((x, i) => {
            const w = x.score || 75
            console.log(x.label, w)
            return w
          })
          const results = []
          const samples = sample_without_replacement(specialtiesToSample, ws, searchResults.length)
          console.log("SAMPLES", samples.length)
          for (const specialty of samples) {
            if (!specialty) {
              console.error('Null specialty', reason)
              continue
            }
            const { code, label, score } = specialty
            for (let i = 0; i < searchResults.length; i++) {
              const x = searchResults[i]
              const found = x.specialties.find(y => implies(y.code, code))
              if (found) {
                results.push(x)
                //console.log(results.length, label, score)
                searchResults.splice(i, 1)
                break 
              } else {
                console.log("not found:", specialty, 'in', x.specialties)
              }
            }
          }
          return results
        }
      case 'rating':
        sort = (x, y) => {
          const price1 = getPrice(x)
          const price2 = getPrice(y)
          let cmp = 0
          if (cmp === 0) {
            cmp = orderByRating(x, y)
          }
          if (cmp === 0) {
            cmp = price1 - price2
          }
          if (cmp === 0) {
            cmp = x.distance - y.distance
          }
          //console.log(this.state.sort, x, y, '=>', cmp)
          return cmp
        }
        break
      case 'distance':
        sort = (x, y) => {
          let cmp = 0
          const price1 = getPrice(x)
          const price2 = getPrice(y)
          if (cmp === 0) {
            cmp = x.distance - y.distance
          }
          if (cmp === 0) {
            cmp = price1 - price2
          }
          if (cmp === 0) {
            cmp = orderByRating(x, y)
          }
          return cmp
        }
        break
      case 'price':
        sort = (x, y) => {
          const price1 = getPrice(x)
          const price2 = getPrice(y)
          let cmp = price1 - price2
          if (cmp === 0) {
            cmp = orderByRating(x, y)
          }
          if (cmp === 0) {
            cmp = x.distance - y.distance
          }
          return cmp
        }
    }
    return searchResults => {
      searchResults.sort(sort)
      return searchResults
    }
  }

  confirmAppointment = async nextAppt => {
    const stripe = await stripePromise
    let result = await this.props.me.confirmPaymentIntent(nextAppt.paymentIntentId)
    if (result.error) {
      return
    }
    // Stripe, bro what???
    const { paymentIntent } = result
    if (paymentIntent) {
      try {
        result = await stripe.handleCardAction(paymentIntent)
        if (result.error) {
          ////debugger
        }
      } catch (err) {
        console.error(err)
      }
    }
    result = await this.props.me.confirmCustomerAppointment(nextAppt)
    //console.log(result)
    ////debugger
  }

  renderAppointmentsPage = (back) => <BnAppointmentsPage confirmAppointment={this.confirmAppointment} me={this.props.me} appointments={this.state.appointments} back={back}/>  

  openAppointments = () => {
    this.setState({
      subpage: () => this.renderAppointmentsPage(this.back)
    })
  }

  cancelAppointment = async nextAppt => {
    const result = await this.props.me.cancelAppointment(nextAppt.id)
    //console.log(result)
    ////debugger
    delete this.appts[nextAppt.id]
    this.updateAppointments()
  }
  
  renderCustomerSignUp() {
    return <BnCustomerAccountSetup back={this.props.back} createAccount={this.createAccount} err={this.state.formErr} form={this.state.form} me={this.props.me}/> 
  }


  renderEmptySearchResults() {
    const reason = this.state.searchResult
    let specialists = ''
    let sep = ''
    //console.log('reason', reason)
    reason.specialties.forEach(x => {
      if (!x.name) {
        //console.log(reason)
        return
      }
      const specialist = this.props.me.formatSpecialist(x.name)
      specialists += sep
      specialists += an(specialist)
      sep = ' or '
    })
    const msg = `Sorry, we couldn\'t find a provider near you`
    return <div className='bnEmptySearchResults'>{msg}</div>
  }

  getForm = () => this.state.form

  chooseSpecialties = (allSpecialties, count) => {
    const form = mergeFields(this.getForm().specialtiesFilter) 
    const onChange = (field, value) => {
      form[field] = value
      this.forceUpdate()
    }
    const action = () => {
      const filter = this.getForm().specialtiesFilter = {}
      for (const code in form) {
        if (form[code]) {
          const specialty = allSpecialties.find(x => x.code === code)
          filter[code] = specialty
        }
      }
      this.back()
    }
    const opts = allSpecialties.map(x => {
      return {
        value: x.code,
        name: x.label + ' ('+count[x.code]+')'
      }
    })
    const subtitle = 'relevant to ' + this.state.searchResult.reason
    const title = this.getSpecialtiesTitle()
    this.setState({
      subpage: () => <BnSelectionListChooser title={title} subtitle={subtitle} me={this.props.me} back={this.back} action={action} form={form} onChange={onChange} options={opts}/>
    })
  }

  getProviderTitle = () => getProviderTitle(this.state.providerType)

  getSpecialtiesTitle = () => {
    if (!this.state.providerType) return ''
    if (this.state.providerType === 'specialtyCare') {
      return 'Specialty Care'
    }
    return this.getProviderTitle() + ' Specialties'
  }

  chooseLocation = () => {
    const action = async form => {
      const { address, city, state, zip } = form
      const result = await this.props.me.getLocation({ address, city, state, zip })
      {
        const { latitude, longitude, address_components, address} = result
        const city = address_components.find(x => x.types.find(y => y == 'locality')).short_name
        const state = address_components.find(x => x.types.find(y => y == 'administrative_area_level_1')).short_name
        const zip = address_components.find(x => x.types.find(y => y == 'postal_code')).short_name
        const updates = {
          location: { address, city, state, zip, latitude, longitude }, subpage: null 
        }
        //console.log('chooseLocation', updates)
        this.setState(updates, this.observeNearbyDoctors)
      }
    }
    this.setState({
      subpage: () => <BnChooseLocationPage location={this.state.location} me={this.props.me} back={this.back} action={action}/>
    })
  }

  onChangeProfile = (field, value) => {
    this.state.profileForm[field] = value
    this.state.profileFormErr = {}
    this.forceUpdate()
  }

  onInitDob = i => {
    this.dobInput = i
  }

  onClickDob = () => {
    this.dobInput.show()
  }

  renderCompleteProfile = () => {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const form = this.state.profileForm
    const formErr = this.state.profileFormErr
    const onChange = this.onChangeProfile
    const action = async () => {
      if (!form.gender) {
        formErr = { field: "gender", message: "Gender is required." }
        this.forceUpdate()
        return
      }
      const age = Math.round((Date.now() - form.dob.getTime()) / (1000 * 365 * 24 * 60 * 60))
      if (age <= 0) {
        formErr = { field: "dob", message: "Date of birth must be in the past." }
        this.forceUpdate()
        return
      }
      this.state.customerGender = form.gender
      this.state.customerAge = age
      this.back()
    }
    return <div className='bnFormFieldsContainer'>
      <BnPage subpage={subpage} me={this.props.me}>
      <BnForm>
      <BnLogoSmall/>
      <Sep/>
      <BnFormFields>
      <BnLabel1 text='Profile'/>
      <BnLabel2 text='Please complete your profile information'/>
      <Sep/>
      <BnInputFieldSeparator/>
      <BnSelectionList label='Gender' options={genderOptions} selection={this.state.profileForm.gender} action={this.setGender}/>
      <BnInputFieldSeparator/>
      <BnDateInput key={'dob'} onInit={this.onInitDob} onClick={this.onClickDob} label='Date of Birth' dateWheels={'M dd YYYY'} dateFormat={'MMM dd, yyyy'} name='dob' form={this.state.profileForm} onChange={this.onChangeProfile}/>
      <BnInputFieldSeparator/>
      <Sep/>
      <div className='bnSignInErr'>{formErr && formErr.message}</div>
      <BnButton action={action}/>
      </BnFormFields>
      </BnForm>
      </BnPage>
      </div>
  }

  toggleAdvancedAutocomplete = () => {
    this.setState({
      advancedAutocomplete: !this.state.advancedAutocomplete
    })
  }
  
  toggleAdvancedSearch = () => {
    this.setState({
      advancedSearch: !this.state.advancedSearch
    })
  }

  selectProviderType = tab => {
    const providerType = tab.selector
    const reason = this.state.searchResult 
    const searchResultSpecialties = reason.specialties.filter(this.props.me.providerTypeFilter(providerType))
    console.log('searchResultSpecialties', searchResultSpecialties)
    this.setState({
      providerType,
      searchResultSpecialties,
      unfilteredSearchResults: [],
      searchResults: []
    }, () => {
      this.onClickSearchResult({reason})
    })
  }

  showProviderFilter = () => {
    switch (this.state.providerType) {
      case 'dental':
      case 'vision':
      case 'behavioralHealth':
      case 'specialtyCare':
        return true
    }
    return false
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    {
      console.log('RENDER')
      let label1, label2
      if (this.state.customer === undefined) {
        label1 = 'Loading Account Information'
        label2 = 'Please wait while we locate your account'
      }
      if (label1 && label2) {
        return <BnCustomerAccountSetupSpinner label1={label1} label2={label2}/>
      }
    }
    if (!this.state.customer) {
      return this.renderCustomerSignUp()
    }
    const filterDuration = doctor => {      
      if (this.state.form.durationFilter === 'any') {
        return true
      }
      const dur = getOfficeVisitDuration(doctor.officeVisit.code)
      if (!dur) {
        return true
      }
      return this.state.minDur === dur
    }
    const filterSpecialties = doctor => {
      let count = 0
      for (const field in this.state.form.specialtiesFilter) {
        count++
        break
      }
      if (count > 0) {
        for (const x of doctor.specialties) {
          if (this.state.form.specialtiesFilter[x.code]) {
            return true
          }
        }
        return false
      }
      if (this.state.providerType === 'primaryCare') {
        for (const x of doctor.specialties) {
          switch (x.code) {
            case '207Q00000X': // Family Medicine
            case '208D00000X': // General Practice
              break
            default: // don't show primary care providers with irrelevant specialties
              for (const y of this.state.searchResult.specialties) {
                if (!implies(x.code, y.code)) {
                  return false
                }
              }
          }
        }
      }
      console.log("doctorSpecialties", doctor)
      for (const x of doctor.specialties) {
        for (const y of this.state.searchResultSpecialties) {
          if (implies(x.code, y.code)) {
            return true
          }
        }
      }
      return false
    }
    const filterRating = doctor => {
      const rating = doctor.rating || 0
      return rating >= this.state.minRating
    }
    const filterDistance = doctor => {
      const meters = doctor.distance
      const miles = meters * 0.000621371
      return miles <= this.state.maxDistance
    }
    const filterPatientGender = doctor => {
      switch (this.state.providerType) {
        case 'specialtyCare':
          break
        default:
          return true
      }
      switch (this.state.form.genderFilter) {
        case 'M':
          return !doctor.specialties.find(x => x.label.toLowerCase().indexOf('gyn') >= 0)
      }
      return true
    }
    const filterPatientAge = doctor => {
      switch (this.state.providerType) {
        case 'specialtyCare':
        case 'dental':
        break
        default:
        return true
      }
      const isPediatric = () => {
        return doctor.specialties.find(x => x.code === '208000000X') ||
          doctor.specialties.find(x => x.label.toLowerCase().indexOf('pediatric') >= 0)
      }
      const isGeriatric = () => {
        return doctor.specialties.find(x => x.label.toLowerCase().indexOf('geriatric') >= 0)
      }
      switch (this.state.form.ageFilter) {
        case 'adult':
          if (isPediatric() || isGeriatric()) {
            return false
          }
        case 'pediatric': return isPediatric()
        case 'geriatric': return isGeriatric()
        case 'any':
          break
      }
      return true
    }
    //console.log('filter', this.state.form)
    let seenNPI = {}
    const removeDup = x => {
      if (seenNPI[x.npi]) {
        return false
      }
      seenNPI[x.npi] = true
      return true
    }
    let searchResults = this.state.searchResults.filter(x => x.place &&
                                                        removeDup(x) &&
                                                        x.officeVisit &&
                                                        x.officeVisit.price <= this.state.maxPrice &&
                                                        filterSpecialties(x) &&
                                                        filterPatientGender(x) &&
                                                        filterPatientAge(x) &&
                                                        filterRating(x) &&
                                                        filterDistance(x) &&
                                                        filterDuration(x))
    const fn = this.getSorting()
    //console.log('sorting', fn)
    searchResults = fn(searchResults)
    let allSpecialties = this.state.searchResult ? this.state.searchResultSpecialties : []
    if (!allSpecialties) {
      //console.log('searchResult', this.state.searchResult)
      allSpecialties = []
    }
    const S = {}
    allSpecialties.forEach(x => S[x.code] = 0)
    let total = 0
    searchResults.forEach(x => {
      const dup = {}
      x.specialties.forEach(y => {
        if (dup[y.code]) {
          //console.log('dup', y)
          return
        }
        dup[y.code] = true
        if (!S[y.code]) {
          S[y.code] = 1
        } else {
          S[y.code]++
        }
        total++
      })
    })
    const chooseSpecialties = async () => {
      //allSpecialties.sort((x, y) => x.label.localeCompare(y.label))
      await this.chooseSpecialties(allSpecialties, S)
    }
    let subpage
    if (this.state.subpage) subpage = this.state.subpage()
    let location = this.state.location
    let nextAppts = []
    let cancelAppt
    let modifyAppt
    let confirmAppt
    if (this.state.appointments.length > 0) {
      nextAppts = this.state.appointments.filter(appt => {
        switch (appt.status) {
          case 'canceled':
          case 'completed':
            return false
          default:
            break
        }
        return true
      })
      console.log("nextAppts", nextAppts)
    }
    const form = this.state.form
    const onChange = (field, value) => {
      this.onChange(field, value)
      //console.log(form)
    }
    const onChangeSpecialtyFilter = (field, value) => {
      if (value) {
        form.specialtiesFilter[field] = S[field]
      }  else {
        delete form.specialtiesFilter[field]
      }
      this.forceUpdate()
    }
    let locationLabel = "Current Location"
    if (location) {
      const { city, state, zip } = location
      if (city && state && zip) {
        locationLabel = `${city}, ${state} ${zip}`
      }
    }
    const autocompleteFilter = result => {
      const { reason } = result
      switch (form.reasonFilter) {
        case 'any':
          return true
        case 'issue':
          return reason.issue
        case 'symptom':
          return reason.symptom
      }
    }
    let providerCategoryTabs = this.state.providerCategoryTabs || []
    let genderAge
    if (this.state.customerGender && this.state.customerAge) {
      genderAge = this.state.customerAge + 'yr ' + this.state.customerGender
    }
    const providerCount = this.state.providerCount || 0
    const officeVisitCount = this.state.officeVisitCount || 0
    return <BnPage subpage={subpage} me={this.props.me}>
      <div className='bnCustomerContent'>
      <div className='bnCustomerContentHeader'>
      <BnMenu open={this.state.showMenu} action={this.toggleMenu}/>
      <div className='bnCustomerMenuSeparator'/>
      <BnLogoSmall/>
      </div>
      <Sep/>
      {this.props.back && <BnBackButton action={this.props.back}/>}
      <Sep/>
      {nextAppts.length > 0 && 
       [<BnLabel1 text='Upcoming'/>,
        <Sep/>,
        joinWith(nextAppts.slice(0, 1).map(nextAppt => {
          if (nextAppt.status === 'scheduled') {
            confirmAppt = async () => {
              await this.confirmAppointment(nextAppt)
            }
          }
          cancelAppt = async () => {
            await this.cancelAppointment(nextAppt)
          }
          return <div className='bnCustomerNextAppointment'>
            <BnCustomerAppointmentCard action={this.openAppointments} appt={nextAppt} cancel={cancelAppt} modify={modifyAppt} confirm={confirmAppt}/>
            </div>
        }), () => <Sep/>),
        <Sep/>, <Sep/>]
      }
      <BnLabel1 text="Visit a health professional"/>
      {false && <BnLabel2 text={officeVisitCount + ' of ' + providerCount + ' providers offering office visits in your vicinity'}/>}
      <Sep/>
      <BnSearchField label={'Reason for visit'} search={this.mainSearch} searchTerm={this.state.searchTerm}/>
      <div className='bnCustomerContentLocationLine'>
      <div/>
      <div className='bnLocationButton'  onClick={this.chooseLocation}>
      <div className='bnCustomerContentLocation'>{locationLabel}</div>
      {locationLabel && <div className='bnCustomerContentLocationIcon'><ReactSVG src={BxLocation}/></div>}
      </div>
      </div>
      {this.state.autocompleteResults.length > 0 && this.state.searchTerm.trim() && !this.state.searchResult && <div className='bnAutocompleteFilters'>
       <div className='bnLocationButton' onClick={this.toggleAdvancedAutocomplete}>{(this.state.advancedAutocomplete ? 'hide  ' : '')+ 'filters'}</div>
       {this.state.advancedAutocomplete && <div className='bnCustomerContentSearchFilters'>
      <BnCustomerReasonFilter  form={form} name='reasonFilter' onChange={onChange}/>
        <Sep/>
       </div>}
       </div>}
      <Sep/>
      {!this.state.searchTerm.trim() && !this.state.searchResult && <div key='commonSearchTitle'>
       <BnLabel1 text='Popular Searches'/>
       </div>}
      <div className='bnCustomerSearchResults' style={!this.state.searchResult ? null : { display: 'none' }}>
      {this.state.autocompleteResults.filter(autocompleteFilter).map(this.renderAutocompleteResult)}
    </div>
    {this.state.searchResult && <div className='bnCustomerSearchDoctors'>
      {this.state.searchResult && <div className='bnCustomerSearchResults'>
       <BnSearchResultBack action={this.searchResultBack}/>
       <BnSearchResult label={this.state.searchResult.reason} result={this.state.searchResult}/>
       <Sep/>
       </div>}
      <Sep/>
     {this.state.searchResult && this.state.providerCategoryTabs.length > 1 &&
      <div key='providerTypeCats' className='bnProviderCategories'>
      <BnLabel1 text='Types of Care'/>
      <Sep/>
      <BnTabview tabs={providerCategoryTabs} selection={this.state.providerType} select={this.selectProviderType}/>
       </div>}
     {this.state.unfilteredSearchResults.length > 0 && <div key='customerContentSearchFilters' className='bnCustomerContentSearchFilters'>
      <Sep/>
      <div className='bnCustomerContentSearchResultsHeaderLine'>
      <BnLabel1 text={`${searchResults.length} Provider${searchResults.length !== 1 ? 's' : ''}`}/>
      <div className='bnCustomerSearchFiltersButton' onClick={this.toggleAdvancedSearch}>{(this.state.advancedSearch ? 'hide  ' : 'more ')+ 'filters'}</div>
      </div>
      <div/>
      {this.state.advancedSearch &&  <div key='customerAdvancedSearchFilters' className='bnCustomerAdvancedSearchFilters'>
       <Sep/>
       <BnCustomerGenderFilter  form={form} name='genderFilter' onChange={onChange}/>
      <Sep/>
       <BnCustomerAgeFilter  form={form} name='ageFilter' onChange={onChange}/>
      <Sep/>
      <BnCustomerDurationFilter  form={form} name='durationFilter' onChange={(field, value) => {
        let minDur = 99999999
        switch (value) {
          case 'any':
            break
          default:
            minDur = Number(value)
        }
        onChange(field, value)
        this.setState({
          minDur
        })
      }}/>
       <Sep/>
      <BnCustomerPriceFilter  form={form} name='priceFilter' onChange={(field, value) => {
        let maxPrice = 99999999
        switch (value) {
          case 'any':
            break
          default:
            maxPrice = Number(value)
        }
        onChange(field, value)
        this.setState({
          maxPrice
        })
      }}/>
      <Sep/>
      <BnCustomerRatingFilter  form={form} name='ratingFilter' onChange={(field, value) => {
        let minRating = 0
        switch (value) {
          case 'any':
            break
          default:
            minRating = Number(value)
        }
        onChange(field, value)
        this.setState({
          minRating
        })
      }}/>
      <Sep/>
      <BnCustomerDistanceFilter  form={form} name='distanceFilter' onChange={(field, value) => {
        let maxDistance = 999999
        switch (value) {
          case 'any':
            break
          default:
            maxDistance = Number(value)
        }
        onChange(field, value)
        this.setState({
          maxDistance
        })
      }}/>
       <Sep/>
      <BnCustomerWhen specialty={this.state.searchResult.reason} form={form} onChange={onChange}/>
       <Sep/>
       <BnLabel4 text={this.getProviderTitle()} style={this.showProviderFilter() ? null : { display: 'none' }}/>
       <BnPageButton label='Specialties' count={allSpecialties.length} action={chooseSpecialties}/>
       <div className='bnCustomerSpecialtiesFilterSelected'>
       {
         joinWith(Object.values(this.getForm().specialtiesFilter).map(x => {
           return <BnCheckbox name={x.code} label={x.label} form={this.getForm().specialtiesFilter} onChange={onChangeSpecialtyFilter}/>
         }), () => <div className='bnCustomerSpecialtiesFilterSeparator'/>)
       }
       </div>
       <Sep/>
       </div>}
     </div>}
     <Sep/>
       
     <div key='sorted' className='bnCustomerSearchResultsSort' style={searchResults.length > 0 ? null : {display: 'none'}}>
     <BnTabview tabs={[
       {
         selector: 'relevance',
         label: 'Relevance',
         render: () => null
       },
       {
         selector: 'rating',
         label: 'Rating',
         render: () => null
       },
       {
         selector: 'price',
         label: 'Price',
         render: () => null
       },
       {
         selector: 'distance',
         label: 'Distance',
         render: () => null
       }
     ]} selection={this.state.sort} select={tab=>this.setState({sort: tab.selector})}/> 
     </div>
     {this.state.busy && <div key='spinLoader' className='bnCustomerSearchBusy'>
     <div className='bnCustomerSearchBusySpinner'>
     <ReactSVG src={SpinLoader}/>
     </div>
     </div>}
     <div key={'searchResults'} className='bnCustomerSearchResults'>
     {searchResults.map(this.renderSearchResult)}
     {this.state.searchComplete &&
      searchResults.length ===  0 &&
      this.renderEmptySearchResults()}
     </div>
     </div>}
    </div>
      {this.state.showMenu && <BnCustomerMenu close={this.toggleMenu} renderAppointmentsPage={this.renderAppointmentsPage} me={this.props.me} selectedPaymentMethod={this.state.selectedPaymentMethod} paymentMethods={this.state.paymentMethods} appointments={this.state.appointments}/>}
      </BnPage>
  }
}

export class BnCustomer extends BnApp {

  renderContent() {
    return <BnCustomerContent me={this.props.me} back={this.props.back}/>
  }
}


